import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const WpcsYml = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.wpcs);

    useEffect(() => {
        let value = `name: WordPress Coding Standard check

# Triggers the workflow on push or pull request events but only for the main/release/production branch
on: pull_request

jobs:
  phpcs:
      name: WordPress Coding Standard
      runs-on: ubuntu-latest
      steps:
        - uses: actions/checkout@v2
        - name: WordPress Coding Standard check
          uses: 10up/wpcs-action@stable
          with:
            enable_warnings: false # Enable checking for warnings (-w)
            paths: '.' # Paths to check, space separated
            excludes: '' # Paths to excludes, space separated
            standard: 'WordPress' # Standard to use. Accepts WordPress|WordPress-Core|WordPress-Docs|WordPress-Extra|WordPress-VIP-Go|WordPressVIPMinimum|10up-Default.
            standard_repo: '' # Public (git) repository URL of the coding standard
            repo_branch: 'production' # Branch of Standard repository
            phpcs_bin_path: 'phpcs' # Custom PHPCS bin path
            use_local_config: 'true' # Use local config if available
            extra_args: '' # Extra arguments passing to the command
`;
        setStore({ wpcs: value });
    }, []);

    return null;
};

export default WpcsYml;
