import { Fragment, useEffect, useReducer} from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { reducer } from "../reducer";

const initialState = [];

const DashWidget = () => {
    const { dashWidget, setDashWidget } = useGlobalPluginInfo();
    const [linksValues, dispatch] = useReducer(reducer, dashWidget?.infoLinks || initialState);

    useEffect(() => {
        setDashWidget({...dashWidget, infoLinks: [...linksValues]})
    }, [linksValues])
   
    return (
        <Fragment>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Feed URL
                    </label>
                    <input
                        type="text"
                        defaultValue={dashWidget.feedUrl}
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => {
                            setDashWidget({
                                ...dashWidget,
                                feedUrl: e.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Max Item
                    </label>
                    <input
                        type="number"
                        defaultValue={dashWidget.maxItem}
                        placeholder="CRM Url"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => {
                            setDashWidget({
                                ...dashWidget,
                                maxItem: e.target.value,
                            });
                        }}
                    />
                </div>
            </div>
            <h3 className="block text-[#24263a] text-xs leading-[15px] font-bold mt-4">Info Links</h3>
            <div className="border border-[#D8D8D8] rounded-[2px] mt-2 p-2">
                {linksValues?.map((item, index) => (
                    <div key={index} className="flex items-center justify-center">
                         <div className="grid grid-cols-2 gap-4 mb-5 w-full">
                            <div>
                                <label
                                    htmlFor="Handle"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    Label
                                </label>
                                <input
                                    type="text"
                                    value={item.label}
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "label", index })}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="Handle"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    URL
                                </label>
                                <input
                                    type="text"
                                    value={item.url}
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "url", index })}
                                />
                            </div>
                        </div>
                        <div className="w-20 h-20 flex items-center justify-center">
                            <button onClick={() => dispatch({type: "FIELD_REMOVE", index})} className="w-12 h-12 bg-red-200 text-red-600 rounded-full">
                                <i className="fa-solid fa-trash" />
                            </button>
                        </div>
                    </div>
                   
                ))}
                <div className="mt-[15px] text-right">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() => dispatch({type: "FIELD_ADD", fields: {label: "", url: "#"} })}
                >
                    ADD
                </button>
            </div>
            </div>
        </Fragment>
       
    );
}

export default DashWidget;