import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const PluginNameFrontendCss = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.pluginNameFrontendCss);

    useEffect(() => {
        let value = `/* This is frontend css */`;

        setStore({ pluginNameFrontendCss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default PluginNameFrontendCss;
