import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const DependencyReviewYml = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setStore] = useFileStore((store) => store.dependencyReview);

    useEffect(() => {
        let value = `# Dependency Review Action
#
# This Action will scan dependency manifest files that change as part of a Pull Reqest, surfacing known-vulnerable versions of the packages declared or updated in the PR. Once installed, if the workflow run is marked as required, PRs introducing known-vulnerable packages will be blocked from merging.
#
# Source repository: https://github.com/actions/dependency-review-action
# Public documentation: https://docs.github.com/en/code-security/supply-chain-security/understanding-your-software-supply-chain/about-dependency-review#dependency-review-enforcement
name: 'Dependency Review'
on: [pull_request]

permissions:
  contents: read

jobs:
  dependency-review:
    runs-on: ubuntu-latest
    steps:
      - name: 'Checkout Repository'
        uses: actions/checkout@v3
      - name: Dependency Review
        uses: actions/dependency-review-action@v3
        with:
          license-check: true
          vulnerability-check: false
          config-file: 10up/.github/.github/dependency-review-config.yml@trunk
`
        setStore({dependencyReview: value})
    },[])

    return null;
}

export default DependencyReviewYml;