import { useState } from "react";
import Tabs from "../../Tabs/Tabs";
import RowLinks from "./RowLinks";
import RecommendedPlugin from "./RecommendedPlugin";

const RowLinkAndRecmd = () => {
    const [thirdFilesTabs, setThirdFilesTabs] = useState(1);

    const rowLink = [
        {
            id:1,
            name:"Row Link"
        },
        {
            id:2,
            name:"Recommended Plugin"
        }
    ]

    return (
        <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
            <Tabs
                tabs={thirdFilesTabs}
                setTabs={setThirdFilesTabs}
                tabName={rowLink}
                tabsItems={
                    <div className="px-5 pb-5 mt-10">
                        {thirdFilesTabs === 1 && (
                            <RowLinks />
                        )}
                        {thirdFilesTabs === 2 && (
                            <RecommendedPlugin />
                        )}
                    </div>
                }
            />
        </div>
    )
}

export default RowLinkAndRecmd;