import React, { Fragment } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const Others = () => {
    const { readmeData, setReadmeData } = useGlobalPluginInfo();

    return (
        <Fragment>
            <div className="grid grid-cols-2 items-center gap-[30px]">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Installation Instruction
                    </label>
                    <textarea
                        type="text"
                        defaultValue={readmeData.installInstruction}
                        placeholder="Installation Instruction"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                installInstruction: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Upgrade Notice
                    </label>
                    <textarea
                        type="text"
                        defaultValue={readmeData.upgradeNotice}
                        placeholder="Upgrade Notice"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                upgradeNotice: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default Others;
