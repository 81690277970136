import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../../strore/useFileStore";

const PopupPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.popupPhp);
    const { advancedItem, pluginInfo, pluginType } = useGlobalPluginInfo();

    const { functionPrefix, baseNamespace } = advancedItem;
    const { pluginAuthor, pluginAuthorEmail } = pluginInfo;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Model;`
        : `namespace ${baseNamespace}\\Inc\\Classes\\Notifications\\Model;`
}

// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

/**
 * Abstract class for Popup
 *
 * ${pluginAuthor} <${pluginAuthorEmail}>
 */
abstract class Popup extends Notification {

	public $type = 'popup';

	/**
	 * Get Key
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	final public function get_key() {
		return '${functionPrefix}_popup_' . $this->get_id();
	}
}`;
        setStore({ popupPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [functionPrefix, baseNamespace, pluginAuthor, pluginAuthorEmail, pluginType]);

    return null;
};

export default PopupPhp;
