import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../strore/useFileStore";

const AskForRatingPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.askForRatingPhp);
    const { pluginInfo, advancedItem, pluginType } = useGlobalPluginInfo();

    const {
        pluginTextDomain,
        pluginClassPrefix,
        pluginAuthor,
        pluginAuthorEmail,
        pluginName,
        askForRating,
    } = pluginInfo;
    const { constantPrefix, baseNamespace } = advancedItem;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications; 

use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Model\\Notice;`
        : `namespace ${baseNamespace}\\Inc\\Classes\\Notifications;
		
use ${baseNamespace}\\Inc\\Classes\\Notifications\\Model\\Notice;`
}

if ( ! class_exists( 'Ask_For_Rating' ) ) {
	/**
	 * Ask For Rating Class
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	class Ask_For_Rating extends Notice {

		/**
		 * Notice Content
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function notice_content() {
			printf(
				'<h2 style="margin:0">Enjoying %1$s?</h2><p>%2$s</p>',
				esc_html__( '${pluginName}', '${pluginTextDomain}' ),
				__( 'A positive rating will keep us motivated to continue supporting and improving this free plugin, and will help spread its popularity.<br> Your help is greatly appreciated!', '${pluginTextDomain}' )
			);
		}

		/**
		 * Rate Plugin URL
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function plugin_rate_url() {
			return ${
                pluginType === "free_pro_bundle"
                    ? `'${askForRating}'`
                    : `'https://wordpress.org/plugins/' . ${constantPrefix}_SLUG`
            } ;
		}

		/**
		 * Footer content
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function footer_content() {
			?>
			<a class="button button-primary rate-plugin-button" href="<?php echo esc_url( $this->plugin_rate_url() ); ?>" rel="nofollow" target="_blank">
				<?php echo esc_html__( 'Rate Now', '${pluginTextDomain}' ); ?>
			</a>
			<a class="button notice-review-btn review-later ${pluginClassPrefix}-notice-dismiss" href="#" rel="nofollow">
				<?php echo esc_html__( 'Later', '${pluginTextDomain}' ); ?>
			</a>
			<a class="button notice-review-btn review-done ${pluginClassPrefix}-notice-disable" href="#" rel="nofollow">
				<?php echo esc_html__( 'I already did', '${pluginTextDomain}' ); ?>
			</a>
			<?php
		}

		/**
		 * Intervals
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function intervals() {
			return array( 7, 11, 15, 15, 10, 20, 25, 30 );
		}
	}
}`;
        setStore({ askForRatingPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginTextDomain,
        pluginAuthor,
        pluginType,
        pluginAuthorEmail,
        pluginName,
        askForRating,
        pluginClassPrefix,
        constantPrefix,
        baseNamespace,
    ]);
    return null;
};

export default AskForRatingPhp;
