import { useEffect } from "react";
import { useFileStore } from "../strore/useFileStore";

const BabelRc = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.babelRc);

    useEffect(() => {
        let value = `{
    "presets": [
        [
        "@babel/preset-env",
        {
            "targets": {
            "browsers": [
                "last 2 Chrome versions",
                "last 2 Firefox versions",
                "last 2 Safari versions",
                "last 2 iOS versions",
                "last 1 Android version",
                "last 1 ChromeAndroid version",
                "ie 11"
            ]
            }
        }
        ],
        "@babel/preset-react"
    ]
}
`;

    setStore({babelRc: value});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default BabelRc;
