import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import { useGlobalModal } from "../context/modalContext";
import Modal from "../components/Modal/Modal";

const Layout = () => {
    // modal state
    const { isOpenModal, setIsOpenModal, fileInfo } = useGlobalModal();

    let dropRef = useRef();


    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            if (dropRef.current && !dropRef.current.contains(e.target)) {
                setIsOpenModal(false);
            }
        });
    }, [setIsOpenModal]);

    return (
        <div
            className={`relative ${
                isOpenModal ? "h-screen overflow-hidden" : "h-full"
            }`}
        >
            <Navbar />

            {isOpenModal ? (
                <div
                    className={`absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-[#00000080] `}
                >
                    <div
                        className={`relative m-auto bg-[#fff] w-[1348px] h-[680px] z-[9999] rounded-[.3rem] border-[1px] border-[#666666] ease-in-out duration-[10s] ${
                            isOpenModal ? "top-[36px] " : "top-0"
                        }`}
                        ref={dropRef}
                    >
                        <Modal modalInfo={fileInfo} />
                    </div>
                </div>
            ) : null}

            <Outlet />
        </div>
    );
};

export default Layout;
