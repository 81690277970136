import React, { Fragment } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const Description = () => {
    const { readmeData, setReadmeData } = useGlobalPluginInfo();

    return (
        <Fragment>
            <div className="grid grid-cols-2 items-center gap-[30px]">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Short Description
                    </label>
                    <textarea
                        type="text"
                        defaultValue={readmeData.shortDesc}
                        placeholder="Short Description"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                shortDesc: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Description
                    </label>
                    <textarea
                        type="text"
                        defaultValue={readmeData.desc}
                        placeholder="Description"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                desc: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default Description;
