import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const BuildReleaseZipYml = () => {
  // eslint-disable-next-line no-unused-vars
  const [_, setStore] = useFileStore((store) => store.buildReleaseZip);

  useEffect(() => {
      let value = `name: Build release zip

on:
  workflow_dispatch:
  workflow_call:
  push:
   branches:
    - trunk

jobs:
  build:
    name: Build release zip
    runs-on: ubuntu-latest
    steps:
      - name: Checkout
        uses: actions/checkout@v3

      - name: Generate ZIP file
        uses: 10up/action-wordpress-plugin-build-zip@stable
`
        setStore({buildReleaseZip: value})
    },[])

    return null;
}

export default BuildReleaseZipYml;