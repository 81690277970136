import { useState } from "react";
import Tabs from "../../Tabs/Tabs";
import Prefix from "./Prefix";
import MainMenu from "./MainMenu";
import Featured from "./Featured";

const PrefixAndMainMenu = () => {
      const [firstFilesTabs, setFirstFilesTabs] = useState(1)

      const pluginFileName = [
          {
              id: 1,
              name: "Prefix",
          },
          {
              id: 2,
              name: "Main Menu",
          },
          {
              id: 3,
              name: "Featured",
          }
      ];

    return (
        <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
            <Tabs
                tabs={firstFilesTabs}
                setTabs={setFirstFilesTabs}
                tabName={pluginFileName}
                tabsItems={
                    <div className="px-5 pb-5 mt-10">
                        {firstFilesTabs === 1 && (
                            <Prefix />
                        )}
                        {firstFilesTabs === 2 && (
                            <MainMenu />
                        )}
                        {firstFilesTabs === 3 && (
                            <Featured />
                        )}
                    </div>
                }
            />
        </div>
    )
}

export default PrefixAndMainMenu;