import React from "react";

const Tabs = ({ tabs, setTabs, tabName, tabsItems }) => {
  const items = tabName;
  return (
    <>
      <div className="flex justify-between  bg-[#f1f1f1] ">
        {items.map((item) => (
          <button
            className={`w-full text-base leading-[15px] font-bold text-center px-5 py-4 uppercase ${
              tabs === item.id
                ? "text-[#5966d2] bg-[#fff] border-b-[4px] border-[#5966d2]"
                : "text-[#24263a]"
            }`}
            key={item.id}
            onClick={() => setTabs(item.id)}
          >
            {item.name}
          </button>
        ))}
      </div>

      {tabsItems}
    </>
  );
};

export default Tabs;
