import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const CRMSetting = () => {
    const { crm, setCrm } = useGlobalPluginInfo();

    return (
        <div className="grid grid-cols-2 gap-4">
            <div>
                <label
                    htmlFor=""
                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                >
                    List Id
                </label>
                <input
                    type="text"
                    defaultValue={crm.listId}
                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                    onChange={(e) => {
                        setCrm({
                            ...crm,
                            listId: e.target.value,
                        });
                    }}
                />
            </div>
            <div>
                <label
                    htmlFor=""
                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                >
                    Tag Id
                </label>
                <input
                    type="text"
                    defaultValue={crm.tagId}
                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                    onChange={(e) => {
                        setCrm({
                            ...crm,
                            tagId: e.target.value,
                        });
                    }}
                />
            </div>
            <div>
                <label
                    htmlFor=""
                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                >
                    Site Source
                </label>
                <input
                    type="text"
                    defaultValue={crm.siteSource}
                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                    onChange={(e) => {
                        setCrm({
                            ...crm,
                            siteSource: e.target.value,
                        });
                    }}
                />
            </div>
            <div>
                <label
                    htmlFor=""
                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                >
                    CRM Endpoint
                </label>
                <input
                    type="text"
                    defaultValue={crm.crmEndpoint}
                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                    onChange={(e) => {
                        setCrm({
                            ...crm,
                            crmEndpoint: e.target.value,
                        });
                    }}
                />
            </div>
        </div>
    )

}

export default CRMSetting;