import React from "react";
// import { useGlobalPluginInfo } from "../../context/pluginInfoContext";
import General from "./General";
// import DownloadFile from "../../DownloadFile/DownloadFile";
import GeneratedFiles from "../../GeneratedFiles/GeneratedFiles";
import CRMAndLicenseSetting from "./CRMAndLicenseSetting";
import AssetsFiles from "./AssetsFiles";
import RowLinkAndRecmd from "./RowLinkAndRecmd";
import PrefixAndMainMenu from "./PrefixAndMainMenu";
import Readme from "./Readme";

const FormInputs = () => {
    // const { pluginInfo, pluginType } = useGlobalPluginInfo();

    return (
        <div className="grid grid-cols-12 gap-8">
            <div className="col-span-8">
                <div className="flex flex-col gap-6">
                    <General />
                    <PrefixAndMainMenu />
                    <CRMAndLicenseSetting />
                    <AssetsFiles />
                    <RowLinkAndRecmd />
                    <div className="-mb-4">
                        <h3 className="text-xl font-bold ">Readme</h3>
                    </div>
                    <Readme />
                </div>
            </div>

            <div className="col-span-4">
                <div className="py-[30px] px-5 bg-[#f1f1f1] rounded-[3px] mb-2">
                    <GeneratedFiles />
                </div>
                {/* {pluginInfo.pluginName ? <DownloadFile /> : null} */}
            </div>
        </div>
    );
};

export default FormInputs;
