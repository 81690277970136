import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../context/pluginInfoContext";
import { useFileStore } from "../../strore/useFileStore";

const UpgraderPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.upgraderPhp);
    const { advancedItem, pluginInfo, pluginType } = useGlobalPluginInfo();
    const { constantPrefix, baseNamespace, mainClassName } = advancedItem;
    const { pluginAuthor, pluginAuthorEmail } = pluginInfo;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Libs;
\nuse WPAdminify\\Modules\\${baseNamespace}\\${mainClassName};`
        : `namespace ${baseNamespace}\\Libs;
\nuse ${baseNamespace}\\${mainClassName};`
}

// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

if ( ! class_exists( 'Upgrader' ) ) {
	/**
	 * Plugin Upgrader Class
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	class Upgrader {

		/**
		 * Plugin version option key
		 *
		 * @var string
		 */
		protected $option_name = ''; // this should be bundled plugins installed time version .

		/**
		 * Lists of upgrades
		 *
		 * @var string[]
		 */
		protected $upgrades = array();

		/**
		 * Constructor
		 */
		public function __construct() {
			$this->option_name = ${mainClassName}::plugin_version_key();
		}

		/**
		 * Get plugin installed version
		 *
		 * @return string
		 */
		protected function get_installed_version() {
			return get_option( $this->option_name, ${constantPrefix}_VER );
		}

		/**
		 * Check if plugin's update is available
		 *
		 * @return bool
		 */
		public function if_updates_available() {
			if ( version_compare( $this->get_installed_version(), ${constantPrefix}_VER, '<' ) ) {
				return true;
			}

			return false;
		}

		/**
		 * Run plugin updates
		 *
		 * @return void
		 */
		public function run_updates() {
			$installed_version = $this->get_installed_version();
			$path              = trailingslashit( ${constantPrefix}_DIR );

			foreach ( $this->upgrades as $version => $file ) {
				if ( version_compare( $installed_version, $version, '<' ) ) {
					include $path . $file;
				}
			}
		}
	}
}`;
        setStore({ upgraderPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainClassName, baseNamespace, pluginAuthor, pluginAuthorEmail, constantPrefix, pluginType]);

    return null;
};

export default UpgraderPhp;
