import { useEffect } from "react";
import { useFileStore } from "../strore/useFileStore";

const EsLintRc = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.esLintRcJson);

    useEffect(() => {
        let value = `{
    "extends": [
        "plugin:@wordpress/eslint-plugin/recommended"
    ],
    "plugins": [
        "jsdoc"
    ],
    "parserOptions": {
        "ecmaVersion": 6,
        "ecmaFeatures": {
        "jsx": true,
        "arrowFunctions": true,
        "blockBindings": true,
        "classes": true,
        "defaultParams": true,
        "modules": true
        },
        "sourceType": "module"
    },
    "globals": {
        "wp": false,
        "hm": false,
        "_": false
    },
    "env": {
        "es6": true,
        "browser": true,
        "node": true,
        "commonjs": true,
        "jquery": true
    },
    "rules": {
        "camelcase": [1],
        "space-in-parens": [1, "always"],
        "no-trailing-spaces": [1],
        "spaced-comment": [0],
        "padded-blocks": [0],
        "prefer-template": [0],
        "max-len": [0],
        "no-else-return": [0],
        "func-names": [0],
        "object-shorthand": [0],
        "indent": ["error", "tab"],
        "space-before-function-paren": 0,
        "no-tabs": 0,
        "prefer-destructuring": 0,
        "no-param-reassign": 0,
        "curly": "error",
        "no-empty-function": "error",
        "no-global-assign": "error",
        "yoda": [ "error", "always" ],
        "no-undefined":"error",
        "comma-spacing": ["error", { "before": false, "after": true }],
        "lines-between-class-members": ["error", "always"],
        "arrow-spacing": "error",
        "jsdoc/check-alignment": 1,
        "jsdoc/check-param-names": 1,
        "jsdoc/check-tag-names": 1,
        "jsdoc/check-types": 0,
        "jsdoc/implements-on-classes": 1,
        "jsdoc/newline-after-description": 1,
        "jsdoc/no-undefined-types": 0,
        "jsdoc/require-jsdoc": 1,
        "jsdoc/require-param": 1,
        "jsdoc/require-param-description": 1,
        "jsdoc/require-param-name": 1,
        "jsdoc/require-param-type": 1,
        "jsdoc/require-returns": 1,
        "jsdoc/require-returns-check": 1,
        "jsdoc/require-returns-description": 1,
        "jsdoc/require-returns-type": 1,
        "jsdoc/valid-types": 1,
        "no-mixed-spaces-and-tabs": "off",
        "require-jsdoc": ["error", {
        "require": {
            "FunctionDeclaration": true,
            "MethodDefinition": true,
            "ClassDeclaration": true,
            "ArrowFunctionExpression": true,
            "FunctionExpression": true
        }
        }]
    },
    "settings": {
        "jsdoc": {
        "tagNamePreference" : {
            "param": "param",
            "returns": "return"
        }
        }
    }
}`;
    setStore({esLintRcJson: value});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default EsLintRc;