import React, { useEffect, useState } from "react";
import GeneratedFiles from "../../GeneratedFiles/GeneratedFiles";
import { useGlobalPluginInfo } from "../../context/pluginInfoContext";
import { useGlobalModal } from "../../context/modalContext";
import SyntaxHighlighter from "react-syntax-highlighter";
import { agate } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useCopyToClipboard } from "usehooks-ts";
import { saveAs } from "save-as";

const Modal = ({ modalInfo = "" }) => {
    const { pluginInfo } = useGlobalPluginInfo();
    const { setIsOpenModal, fileName } = useGlobalModal();
    const [value, copy] = useCopyToClipboard();
    const [copyFile, setCopyFile] = useState(false);

    const downloadFile = () => {
        let blob = new Blob([`${modalInfo}`], {
            type: "text/plain;charset=utf-8",
        });
        saveAs(blob, `${fileName}`);
    };

    useEffect(() => {
        let interval;
        if (!!copyFile) {
            interval = setInterval(() => {
                setCopyFile(false);
            }, 6000);
        }
        return () => clearInterval(interval);
    }, [copyFile]);

    return (
        <div className="h-[663px] overflow-hidden">
            <div className="modal-header flex justify-between items-center p-4 border-b-[1px] border-[#DEE2E6]">
                <h5 className="text-[#24263a] text-[1.25rem] font-bold">
                    {pluginInfo.pluginName}
                </h5>
                <button type="button" onClick={() => setIsOpenModal(false)}>
                    <i className="fa-sharp fa-solid fa-xmark text-[1.5rem] text-[#7F7F7F] hover:text-[#000] font-bold"></i>
                </button>
            </div>
            <div className="modal-body grid grid-cols-12 gap-7 p-[20px]">
                <div className="col-span-3 px-4 relative">
                    <div className="h-[500px] overflow-scroll ">
                        <GeneratedFiles />
                    </div>
                </div>

                <div className="col-span-9 h-[570px] px-4 overflow-hidden">
                    <div className="flex justify-between items-center">
                        <button
                            type="button"
                            className="py-1 px-3 text-[#007bff] hover:text-[#fff] text-[.875rem] font-medium border border-[#007bff] rounded hover:bg-[#007bff]"
                            onClick={(e) => downloadFile(e)}
                        >
                            Download File
                        </button>
                        <button
                            className="py-1 px-3 text-[#007bff] hover:text-[#fff] text-[.875rem] font-medium border border-[#007bff] rounded hover:bg-[#007bff]"
                            onClick={() => {
                                copy(modalInfo);
                                setCopyFile(!copyFile);
                            }}
                        >
                            {copyFile ? "Copied" : "Copy To Clipboard"}
                        </button>
                    </div>

                    <div className="bg-[#333333] rounded-[4px] p-[13px] mt-[20px] h-[518px] overflow-auto">
                        <SyntaxHighlighter
                            language="php"
                            style={agate}
                            className="text-[13px]"
                        >
                            {modalInfo}
                        </SyntaxHighlighter>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
