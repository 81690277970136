import React from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const AddJsFiles = () => {
    const { addJsItem, setAddJsItem } = useGlobalPluginInfo();

    const updateFile = (value, index, handle, type = "string") => {
        if (type === "array" && !!value) {
            let newItems = [...addJsItem];
            let newDeps = value.replace(/\s/g, "").split(",");
            newItems[index][handle] = newDeps;

            return setAddJsItem(newItems);
        }
        if (type === "string" && !!value) {
            let newItems = [...addJsItem];
            newItems[index][handle] = value;

            return setAddJsItem(newItems);
        }
    };

    let checkDuplicate = [];
    addJsItem.map((item, index) => {
        checkDuplicate.push(item.fileName);

        return checkDuplicate;
    });

    return (
        <>
            {addJsItem.map((data, index) => {
                return (
                    <div className="grid grid-cols-6 gap-8 mb-5" key={index}>
                        <div className="col-span-5 grid grid-cols-3 gap-8">
                            <div>
                                <label
                                    htmlFor="Handle"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    Handle
                                </label>
                                <input
                                    type="text"
                                    defaultValue={data.handleName}
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                                    onBlur={(e) =>
                                        updateFile(
                                            e.target.value,
                                            index,
                                            "handleName"
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="FileName"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    File Name
                                </label>
                                <input
                                    type="text"
                                    defaultValue={data.fileName}
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                                    onBlur={(e) => {
                                        let fileVal = e.target.value;

                                        if (
                                            fileVal.includes(".js") &&
                                            !checkDuplicate.includes(fileVal)
                                        ) {
                                            return updateFile(
                                                fileVal,
                                                index,
                                                "fileName"
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="Dependency"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    Dependency
                                </label>
                                <input
                                    type="text"
                                    defaultValue={data.dependency}
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onBlur={(e) =>
                                        updateFile(
                                            e.target.value,
                                            index,
                                            "dependency",
                                            "array"
                                        )
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor=""
                                className="block text-[#24263a] text-xs leading-[15px] font-bold"
                            >
                                In Footer
                            </label>
                            <button
                                type="button"
                                className={` w-[56px] h-[32px] p-[2px] rounded-full mt-[10px] flex items-center ${
                                    !data.toggle
                                        ? "bg-[#ECECEE]"
                                        : "bg-[#5966D2] justify-end"
                                }`}
                                onClick={() => {
                                    updateFile(!data.toggle, index, "toggle");
                                }}
                            >
                                <div className="w-[28px] h-[28px] rounded-full bg-[#fff]"></div>
                            </button>
                        </div>
                    </div>
                );
            })}
            <div className="mt-[15px] flex items-center justify-end">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() =>
                        setAddJsItem([
                            ...addJsItem,
                            {
                                handleName: "",
                                fileName: "",
                                dependency: [],
                                toggle: false,
                            },
                        ])
                    }
                >
                    ADD
                </button>
            </div>
        </>
    );
};

export default AddJsFiles;
