import { useEffect } from "react";
import { useFileStore } from "../../../../strore/useFileStore";

const VariableScss = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.variableScss);

    useEffect(() => {
        let value = `$admin_color: #00BA88;
$admin_plg_status_color: #F4B740;
$admin_plg_status_not_install_color: #1d2327;
$admin_content_body: rgba(78, 75, 102, 0.72);
$admin_heading_color: #FB0066;
$admin_heading_h3_color: #14142B;
$admin_heading_h3_span: #0347ff;
$admin_badge_color: #fff;
$admin_footer_hover: #0347FF;
$admin_popup_color: white;
$admin_popup_close: var(--jltmapfy-popup-color);
$admin_countdown_color: rgb(255, 255, 255 / .8);
$admin_popup_button: #222;
$admin_wwc_color: #646970;
$admin_fntfamly_one: dashicons;
$admin_fntfamly_two: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

/* Survey Variables */
$survey_modal_text: rgb(14, 1, 1);
$survey_submit: #fff;
$survey_feedback_text: var(--e-a-color-txt);
$survey_modal_bg: #fff;
$survey_modal_submit_bg: #3D5DFF;
$survey_modal_overlay_bg: rgba(0, 0, 0, .8);
$survey_feedback_bg: transparent;
`;

        setStore({ variableScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default VariableScss;
