import { createContext, useContext, useState } from "react";

export const DynamicPluginDataContext = createContext();

export const DynamicPluginDataProvider = ({ children }) => {
    //root files
    const [pixarlabsSDKphp, setPixarlabsSDKphp] = useState("");
    const [classPixarlabsPhp, setClassPixarlabsPhp] = useState("");
    const [composerJson, setComposerJson] = useState(""); 
    const [phpcsXmlDist, setPhpcsXmlDist] = useState("");
    const [disIgnore, setDisIgnore] = useState("");
    const [gitIgnore, setGitIgnore] = useState("");
    const [readmeTxt, setReadmeTxt] = useState("");
    const [phpCsFixerDist, setPhpCsFixerDist] = useState("");
    const [gitAttributes, setGitAttributes] = useState("");
    const [packageJson, setPackageJson] = useState("");
    const [babelRc, setBabelRc] = useState("");
    const [styleLintRc, setStyleLintRc] = useState("");
    const [esLintRcJson, setEsLintRcJson] = useState("");
    const [esLintIgnore, setEsLintIgnore] = useState("");
    const [webpackMixJs, setWebpackMixJs] = useState("");

    //.vscode
    const [settingsJson, setSettinsJson] = useState("");

    //.github > workflows
    const [buildReleaseZip, setBuildReleaseZip] = useState("");
    const [dependencyReview, setDependencyReview] = useState("");
    const [phpCompatibility, setPhpCompatibility] = useState("");
    const [phpcs, setPhpcs] = useState("");
    const [phpunit, setPhpunit] = useState("");
    const [pushDeploy, setPushDeploy] = useState("");
    const [wordpressLatest, setWordpressLatest] = useState("");
    const [wpcs, setWpcs] = useState("");


    //assets > css
    const [pluginNameAdminCss, setPluginNameAdminCss] = useState("")
    const [pluginSurveyCss, setPluginSurveyCss] = useState("");
    const [pluginNameFrontendCss, setPluginNameFrontendCss] = useState("");
    //assets > js
    const [pluginNameAdminJs, setPluginNameAdminJs] = useState("");
    const [pluginNameFrontendJs, setPluginNameFrontendJs] = useState("");

    // dev > scss
    const [surveyScss, setSurveyScss] = useState("");
    const [SDKScss, setSDKScss] = useState("");
    // dev > scss > admin
    const [dashboardScss, setDashboardScss] = useState("")
    // dev > scss > common
    const [variableScss, setVariableScss] = useState("");
    const [mixinScss, setMixinScss] = useState("");

    //inc
    const [functionPhp, setFunctionPhp] = useState("");
    //inc > Admin folder
    const [ adminSettingsPhp, setAdminSettingsPhp ] = useState("");
    const [ settinsAPIPhp, setSettinsAPIPhp ] = useState("");

    // inc > Classes folder
    const [recommendedPluginsPhp, setRecommendedPluginsPhp] = useState("");
    const [incRowLinkPhp, setIncRowLinkPhp] = useState("");
    const [upgradePluginPhp, setUpgradePluginPhp] = useState("");
    const [upgradeVersion, setUpgradeVersion] = useState("");
    const [proUpgradePhp, setProUpgradePhp] = useState("");
    const [feedbackPhp, setFeedbackPhp] = useState("");

    //Inc > Classes > Notifications
    const [askForRatingPhp, setAskForRatingPhp] = useState("");
    const [managerPhp, setManagerPhp] = useState("");
    const [notificationsPhp, setNotificationsPhp] = useState("");
    const [upgradeNoticePhp, setUpgradeNoticePhp] = useState("");
    const [incSubscribePhp, setIncSubscribePhp] = useState("");
    const [whatWeCollectPhp, setWhatWeCollectPhp] = useState("");

    //Inc > Classes > Notifications > Base
    const [dataPhp, setDataPhp] = useState("");
    const [datePhp, setDatePhp] = useState("");
    const [userDataPhp, setUserDataPhp] = useState("");

    //Inc > Classes > Notifications > Model
    const [noticePhp, setNoticePhp] = useState("");
    const [NotificationPhp, setNotificationPhp] = useState("");
    const [popupPhp, setPopurPhp] = useState("");

    //libs folder
    const [assetsPhp, setAssetsPhp] = useState("");
    const [helperPhp, setHelperPhp] = useState("");
    const [recommendedPhp, setRecommendedPhp] = useState("");
    const [rowLinkPhp, setRowLinkPhp] = useState("");
    const [upgraderPhp, setUpgraderPhp] = useState("");
    const [featuredPhp, setFeaturedPhp] = useState("");

    //Libs > License
    const [clientPhp, setClientPhp] = useState("");
    const [loaderPhp, setLoaderPhp] = useState("");
    const [examplesPhp, setExamplesPhp] = useState("");
    const [libManagerPhp, setLibManagerPhp] = useState("");

    //Libs > License > assets
    const [licenseCss, setLicenseCss] = useState("");
    const [licenseJs, setLicenseJs] = useState("");



    return (
        <DynamicPluginDataContext.Provider
            value={{
                //root files
                pixarlabsSDKphp, 
                setPixarlabsSDKphp,
                classPixarlabsPhp, 
                setClassPixarlabsPhp,
                composerJson, 
                setComposerJson,
                phpcsXmlDist,
                setPhpcsXmlDist,
                disIgnore, 
                setDisIgnore,
                gitIgnore, 
                setGitIgnore,
                readmeTxt,
                setReadmeTxt,
                phpCsFixerDist,
                setPhpCsFixerDist,
                gitAttributes,
                setGitAttributes,
                packageJson,
                setPackageJson,
                babelRc, 
                setBabelRc,
                styleLintRc,
                setStyleLintRc,
                esLintRcJson,
                setEsLintRcJson,
                esLintIgnore,
                setEsLintIgnore,
                webpackMixJs,
                setWebpackMixJs,
                //.vscode
                settingsJson,
                setSettinsJson,
                //.github > workflows
                buildReleaseZip,
                setBuildReleaseZip,
                dependencyReview,
                setDependencyReview,
                phpCompatibility,
                setPhpCompatibility,
                phpcs, 
                setPhpcs,
                phpunit,
                setPhpunit,
                pushDeploy,
                setPushDeploy,
                wordpressLatest,
                setWordpressLatest,
                wpcs,
                setWpcs,
                //assets > css
                pluginNameAdminCss,
                setPluginNameAdminCss,
                pluginSurveyCss,
                setPluginSurveyCss,
                pluginNameFrontendCss,
                setPluginNameFrontendCss,
                //assets > js
                pluginNameAdminJs,
                setPluginNameAdminJs,
                pluginNameFrontendJs,
                setPluginNameFrontendJs,
                //dev > scss
                surveyScss,
                setSurveyScss,
                SDKScss,
                setSDKScss,
                //dev > scss > admin
                dashboardScss,
                setDashboardScss,
                //dev > scss > common
                variableScss,
                setVariableScss,
                mixinScss,
                setMixinScss,
                // inc
                functionPhp, 
                setFunctionPhp,
                adminSettingsPhp,
                setAdminSettingsPhp,
                settinsAPIPhp,
                setSettinsAPIPhp,
                incSubscribePhp, 
                setIncSubscribePhp,
                whatWeCollectPhp, 
                setWhatWeCollectPhp,
                upgradeVersion, 
                setUpgradeVersion,
                //inc > classes 
                incRowLinkPhp, 
                setIncRowLinkPhp,
                recommendedPluginsPhp, 
                setRecommendedPluginsPhp,
                proUpgradePhp, 
                setProUpgradePhp,
                upgradePluginPhp, 
                setUpgradePluginPhp,
                feedbackPhp,
                setFeedbackPhp,
                //inc > classes > Notifications
                askForRatingPhp,
                setAskForRatingPhp,
                managerPhp,
                setManagerPhp,
                notificationsPhp, 
                setNotificationsPhp,
                upgradeNoticePhp,
                setUpgradeNoticePhp,
                //inc > classes > Notifications > Base
                dataPhp, 
                setDataPhp,
                datePhp, 
                setDatePhp,
                userDataPhp, 
                setUserDataPhp,
                //inc > classes > Notifications > Model
                noticePhp, 
                setNoticePhp,
                NotificationPhp, 
                setNotificationPhp,
                popupPhp,
                setPopurPhp,
                //libs
                assetsPhp,
                setAssetsPhp,
                helperPhp,
                setHelperPhp,
                recommendedPhp,
                setRecommendedPhp,
                rowLinkPhp,
                setRowLinkPhp,
                upgraderPhp,
                setUpgraderPhp,
                featuredPhp,
                setFeaturedPhp,             
                //libs > license
                clientPhp, 
                setClientPhp,
                loaderPhp,
                setLoaderPhp,
                examplesPhp, 
                setExamplesPhp,
                libManagerPhp, 
                setLibManagerPhp,
                //libs > license > assets
                licenseCss, 
                setLicenseCss,
                licenseJs, 
                setLicenseJs
            }}
        >
            {children}
        </DynamicPluginDataContext.Provider>
    );
};

export const useGlobalDynamicPluginData = () => {
    return useContext(DynamicPluginDataContext);
};
