import React, { Fragment } from "react";
import CodeBase from "../Codebase";
import FormInputs from "../components/FormInputs";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import "./Home.css";

const Home = () => {
    const { setPluginType, pluginType } = useGlobalPluginInfo();

    return (
        <Fragment>
            <CodeBase />
            <div className="p-20">
                <div className="mb-12">
                    <h1 className="text-black-500 text-3xl font-bold leading-[2.75rem] uppercase text-center">
                        WP Plugin Generator
                    </h1>
                    <p className="text-[#24263a] text-xl font-light text-center mt-2">
                        Build awesome plugin starter for Pixarlabs.
                    </p>
                </div>
                <div className="group-btn">
                    <div className={`btn`}>
                        <input
                            type="radio"
                            name="plugin_type"
                            value="wordpress_only"
                            id="wordpress_only"
                            onChange={(e) => setPluginType(e.target.value)}
                        />
                        <label className="leading-[48px] cursor-pointer" htmlFor="wordpress_only">
                            WordPress.org only
                        </label>
                    </div>
                    <div className={`btn`}>
                        <input
                            type="radio"
                            name="plugin_type"
                            value="free_pro_bundle"
                            id="free_pro_bundle"
                            onChange={(e) => setPluginType(e.target.value)}
                        />
                        <label className="leading-[48px] cursor-pointer" htmlFor="free_pro_bundle">
                            Free/Pro Bundle
                        </label>
                    </div>
                    <div className={`btn`}>
                        <input
                            type="radio"
                            name="plugin_type"
                            value="adminify_module"
                            id="adminify_module"
                            onChange={(e) => setPluginType(e.target.value)}
                        />
                        <label className="leading-[48px] cursor-pointer" htmlFor="adminify_module">
                            Adminify Module
                        </label>
                    </div>
                </div>
                <FormInputs />
            </div>
        </Fragment>
    );
};

export default Home;
