import React, { memo } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const AddCssFiles = () => {
    const { addCssItem, setAddCssItem } = useGlobalPluginInfo();

    const updateFile = (value, index, handle, type = "string") => {
        if (type === "array" && !!value) {
            let newItems = [...addCssItem];
            let newDeps = value.replace(/\s/g, "").split(",");
            newItems[index][handle] = newDeps;

            return setAddCssItem(newItems);
        }
        if (type === "string" && !!value) {
            let newItems = [...addCssItem];
            newItems[index][handle] = value;

            return setAddCssItem(newItems);
        }
    };

    let checkDuplicate = [];
    addCssItem.map((item, index) => {
        checkDuplicate.push(item.fileName);

        return checkDuplicate;
    });

    return (
        <>
            {addCssItem.map((data, index) => {
                return (
                    <div className="grid grid-cols-3 gap-8 mb-5" key={index}>
                        <div>
                            <label
                                htmlFor="Handle"
                                className="block text-[#24263a] text-xs leading-[15px] font-bold"
                            >
                                Handle
                            </label>
                            <input
                                type="text"
                                defaultValue={data.handleName}
                                className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                                onBlur={(e) =>
                                    updateFile(
                                        e.target.value,
                                        index,
                                        "handleName"
                                    )
                                }
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="FileName"
                                className="block text-[#24263a] text-xs leading-[15px] font-bold"
                            >
                                File Name
                            </label>
                            <input
                                type="text"
                                defaultValue={data.fileName}
                                className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                                onBlur={(e) => {
                                    let fileVal = e.target.value;

                                    if (
                                        fileVal.includes(".css") &&
                                        !checkDuplicate.includes(fileVal)
                                    ) {
                                        return updateFile(
                                            fileVal,
                                            index,
                                            "fileName"
                                        );
                                    }
                                }}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="dependency"
                                className="block text-[#24263a] text-xs leading-[15px] font-bold"
                            >
                                Dependency
                            </label>
                            <input
                                type="text"
                                defaultValue={data.dependency}
                                className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                onBlur={(e) =>
                                    updateFile(
                                        e.target.value,
                                        index,
                                        "dependency",
                                        "array"
                                    )
                                }
                            />
                        </div>
                    </div>
                );
            })}
            <div className="mt-[15px] flex items-center justify-end">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() =>
                        setAddCssItem([
                            ...addCssItem,
                            { handleName: "", fileName: "", dependency: [] },
                        ])
                    }
                >
                    ADD
                </button>
            </div>
        </>
    );
};

export default memo(AddCssFiles);
