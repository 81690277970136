import { Fragment } from "react";
import PixarlabsSDKPhp from "./pixarlabs-sdk.php";
import ClassPixarlabsPhp from "./class-pixarlabs.php";
import RecommendedPluginsPhp from "./Inc/Classes/Recommended_Plugins.php";
import IncRowLinksPhp from "./Inc/Classes/Row_Links.php";
import IncSubscribePhp from "./Inc/Classes/Notifications/Subscribe.php";
import UpgradePluginPhp from "./Inc/Classes/Upgrade_Plugin.php";
import WhatWeCollectPhp from "./Inc/Classes/Notifications/What_We_Collect.php";
import UpgradeVersion from "./Inc/Upgrades/upgrade-1.0.2.php";
import AssetsPhp from "./Libs/Assets.php";
import HelperPhp from "./Libs/Helper.php";
import RecommendedPhp from "./Libs/Recommended.php";
import RowLinksPhp from "./Libs/RowLinks.php";
import UpgraderPhp from "./Libs/Upgrader.php";
import ExamplesPhp from "./Libs/License/examples.php.js";
import LicenseCss from "./Libs/License/assets/license.css.js";
import LicenseJs from "./Libs/License/assets/license.js";
import ComposerJSON from "./composer.json";
import PhpcsXmlDist from "./phpcs.xml.dist";
import DistIgnore from "./.distignore";
import GitIgnore from "./.gitignore";
import AdminSettingsPhp from "./Inc/Admin/AdminSettings.php";
import SettingsAPIPhp from "./Inc/Admin/Settings_API.php";
import FunctionsPhp from "./Inc/Functions.php";
import DataPhp from "./Inc/Classes/Notifications/Base/Data.php";
import DatePhp from "./Inc/Classes/Notifications/Base/Date.php";
import UserDataPhp from "./Inc/Classes/Notifications/Base/User_Data.php";
import NoticePhp from "./Inc/Classes/Notifications/Model/Notice.php";
import NotificationPhp from "./Inc/Classes/Notifications/Model/Notification.php";
import PopupPhp from "./Inc/Classes/Notifications/Model/Popup.php";
import AskForRatingPhp from "./Inc/Classes/Notifications/Ask_For_Rating.php";
import ManagerPhp from "./Inc/Classes/Notifications/Manager.php";
import NotificationsPhp from "./Inc/Classes/Notifications/Notifications.php";
import UpgradeNoticePhp from "./Inc/Classes/Notifications/Upgrade_Notice.php";
import ProUpgradePhp from "./Inc/Classes/Pro_Upgrade.php";
import ClientPhp from "./Libs/License/Client.php";
import LoaderPhp from "./Libs/License/Loader.php.js";
import LibManagerPhp from "./Libs/License/manager.php.js";
import FeaturedPhp from "./Libs/Featured.php";
import ReadmeTxt from "./readme.txt";
import SettingsJson from "./.vscode/settings.json.js";
import BuildReleaseZipYml from "./.github/workflows/build-release-zip.yml";
import DependencyReviewYml from "./.github/workflows/dependency-review.yml";
import PhpCompatibilityYml from "./.github/workflows/php-compatibility.yml";
import PhpcsYml from "./.github/workflows/phpcs.yml";
import PhpUnitYml from "./.github/workflows/phpunit.yml";
import PushDeployYml from "./.github/workflows/push-deploy.yml";
import WordpressLatestYml from "./.github/workflows/wordpress-latest.yml";
import WpcsYml from "./.github/workflows/wpcs.yml";
import GitAttributes from "./.gitattributes";
import PhpCsFixerDistPhp from "./.php-cs-fixer.dist.php";
import FeedbackPhp from "./Inc/Classes/Feedback.php";
import PackageJson from "./package.json";
import BabelRc from "./babelrc";
import StyleLintRc from "./.stylelintrc.json";
import EsLintIgnore from "./.eslintignore";
import EsLintRc from "./.eslintrc.json";
import WebpackMixJs from "./webpack.mix.js";
import PluginSurveyCss from "./assets/css/pluginSurvey.css";
import PluginNameAdminCss from "./assets/css/plugin-name-admin.css";
import PluginNameAdminJs from "./assets/js/plugin-name-admin";
import PluginNameFrontendCss from "./assets/css/plugin-name-frontend.css";
import PluginNameFrontendJs from "./assets/js/plugin-name-frontend";
import SurverScss from "./dev/scss/survey.scss.js";
import DashboardScss from "./dev/scss/admin/dashboard.scss.js";
import VariableScss from "./dev/scss/common/variables.scss.js";
import MixinScss from "./dev/scss/common/mixin.scss.js";
import SDKScss from "./dev/scss/sdk.scss.js";
import RootLoaderPhp from "./loaderPhp.js";
import UpdateCheckerPhp from "./Libs/Update_Checker.php.js";

const CodeBase = () => {
    return (
        <Fragment>
            <PixarlabsSDKPhp />
            <ClassPixarlabsPhp />
            <ComposerJSON />
            <PhpcsXmlDist />
            <ReadmeTxt />
            <PhpCsFixerDistPhp />
            <GitAttributes />
            <DistIgnore />
            <GitIgnore />
            <PackageJson />
            <BabelRc />
            <StyleLintRc />
            <EsLintIgnore />
            <EsLintRc />
            <WebpackMixJs />
            <RootLoaderPhp />
            {/* .vscode */}
            <SettingsJson />
            {/* .github > workflow */}
            <BuildReleaseZipYml />
            <DependencyReviewYml />
            <PhpCompatibilityYml />
            <PhpcsYml />
            <PhpUnitYml />
            <PushDeployYml />
            <WordpressLatestYml />
            <WpcsYml />
            {/* assets > css */}
            <PluginNameAdminCss />
            <PluginSurveyCss />
            <PluginNameFrontendCss />
            {/* assets > js */}
            <PluginNameAdminJs />
            <PluginNameFrontendJs />
            {/* dev > scss */}
            <SurverScss />
            <SDKScss />
            {/* dev > scss > admin */}
            <DashboardScss />
            {/* dev > scss > common */}
            <VariableScss />
            <MixinScss />
            {/* Inc */}
            <FunctionsPhp />
            <AdminSettingsPhp />
            <SettingsAPIPhp />
            <UpgradeVersion />
            {/* Inc > Classes */}
            <RecommendedPluginsPhp />
            <IncRowLinksPhp />
            <UpgradePluginPhp />
            <ProUpgradePhp />
            <FeedbackPhp />
            {/* inc > Classes > Notification */}
            <AskForRatingPhp />
            <ManagerPhp />
            <NotificationsPhp />
            <UpgradeNoticePhp />
            <IncSubscribePhp />
            <WhatWeCollectPhp />
            {/* inc > Classes > Notification > Base */}
            <DataPhp />
            <DatePhp />
            <UserDataPhp />
            {/* inc > Classes > Notification > Model */}
            <NoticePhp />
            <NotificationPhp />
            <PopupPhp />
            {/* libs */}
            <AssetsPhp />
            <HelperPhp />
            <RecommendedPhp />
            <RowLinksPhp />
            <UpgraderPhp />
            <FeaturedPhp />
            <UpdateCheckerPhp />
            {/* Libs > License */}
            <ClientPhp />
            <LoaderPhp />
            <ExamplesPhp />
            <LibManagerPhp />
            {/* Libs > License > assets */}
            <LicenseCss />
            <LicenseJs />
        </Fragment>
    );
};

export default CodeBase;
