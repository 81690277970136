import React, { Fragment, useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const Heading = () => {
    const { readmeData, setReadmeData, pluginInfo } = useGlobalPluginInfo();
    const { pluginVersion } = pluginInfo;

    useEffect(() => {
        setReadmeData({
            ...readmeData,
            stableTag: pluginVersion,
        })
    }, [pluginVersion])

    return (
        <Fragment>
            <div className="grid grid-cols-2 items-center gap-[30px]">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Plugin Name
                    </label>
                    <input
                        type="text"
                        defaultValue={readmeData.pluginName}
                        placeholder="Plugin Name"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                pluginName: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Contributors
                    </label>
                    <input
                        type="text"
                        defaultValue={readmeData.contributors}
                        placeholder="Contributors"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                contributors: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Donate Link
                    </label>
                    <input
                        type="text"
                        defaultValue={readmeData.donateLink}
                        placeholder="Donate Link"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                donateLink: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Tags
                    </label>
                    <input
                        type="text"
                        defaultValue={readmeData.tags}
                        placeholder="Tags"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                tags: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Stable Tag
                    </label>

                    <input
                        type="text"
                        value={readmeData.stableTag}
                        placeholder="Stable Tag"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                stableTag: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Requires PHP
                    </label>
                    <input
                        type="text"
                        defaultValue={readmeData.requiresPHP}
                        placeholder="Requires PHP"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                requiresPHP: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Requires at least
                    </label>
                    <input
                        type="text"
                        defaultValue={readmeData.requiresAtLeast}
                        placeholder="Requires at least"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                requiresAtLeast: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Tested Up To
                    </label>
                    <input
                        type="text"
                        defaultValue={readmeData.testedUpTo}
                        placeholder="Tested up to"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setReadmeData({
                                ...readmeData,
                                testedUpTo: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default Heading;
