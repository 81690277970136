import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../strore/useFileStore";

const NotificationsPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.notificationsPhp);
    const { pluginInfo, advancedItem, pluginType } = useGlobalPluginInfo();

    const { pluginTextDomain, pluginClassPrefix, pluginAuthor, pluginAuthorEmail } = pluginInfo;
    const { baseNamespace, functionPrefix } = advancedItem;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications;

use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Base\\Date;
use WPAdminify\\Modules\\${baseNamespace}\\Libs\\Helper;`
        : `namespace ${baseNamespace}\\Inc\\Classes\\Notifications;
		
use ${baseNamespace}\\Inc\\Classes\\Notifications\\Base\\Date;
use ${baseNamespace}\\Libs\\Helper;`
}



// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

/**
 * Notification Class
 *
 * ${pluginAuthor} <${pluginAuthorEmail}>
 */
class Notifications {

	use Date;

	public $manager;

	public $conflict_days = 5;

	public $slug;

	/**
	 * Constructor method
	 */
	public function __construct() {
		$this->manager = new Manager();

		$this->slug = Helper::${functionPrefix}_slug_cleanup();

		add_action( 'admin_notices', array( $this, 'setup_notifications' ) );

		add_action( '${functionPrefix}_display_notice', array( $this, 'display_notice' ), 10, 2 );
		add_action( '${functionPrefix}_display_popup', array( $this, 'display_popup' ), 10, 2 );

		add_action( 'wp_ajax_${functionPrefix}_notification_action', array( $this, 'notification_action' ) );
	}

	/**
	 * Notification Action
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function notification_action() {
		check_ajax_referer( '${functionPrefix}_notification_nonce' );

		$action_type       = ! empty( $_REQUEST['action_type'] ) ? sanitize_key( $_REQUEST['action_type'] ) : '';
		$notification_type = ! empty( $_REQUEST['notification_type'] ) ? sanitize_key( $_REQUEST['notification_type'] ) : '';
		$trigger_time      = ! empty( $_REQUEST['trigger_time'] ) ? sanitize_text_field( wp_unslash( $_REQUEST['trigger_time'] ) ) : '';

		$exec_notifications = $this->manager->get_exec_notifications( $trigger_time, $notification_type );

		// No Executable Notifications found .
		if ( empty( $exec_notifications ) ) {
			die( 0 );
		}

		$count = 0;

		foreach ( $exec_notifications as $index => $notification ) {
			if ( 0 === $index ) {
				if ( 'disable' === $action_type ) {
					$notification->is_active = false;
				}
				$notification->fire( $trigger_time, $notification_type )->save();
			} else {
				$count++;
				$notification->maybe_delay( $this->date_increment( $trigger_time, $this->conflict_days * $count ) )->save();
			}
		}

		die( 0 );
	}


	/**
	 * Notification Setup
	 *
	 * @param [type] $type .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function setup_notifications_by_type( $type ) {
		// $trigger_time should be today .
		$trigger_time = $this->current_time();

		// Block if necessary .
		$notification_last_fired = get_option( "${functionPrefix}_{$type}_last_interact" );

		if ( $notification_last_fired ) {
			$notification_enable_date = $this->date_increment( $notification_last_fired, $this->conflict_days );

			if ( $this->date_is_prev( $trigger_time, $notification_enable_date ) ) {
				return;
			}
		}

		// Get Executable Notifications .
		$exec_notifications = $this->manager->get_exec_notifications( $trigger_time, $type );

		// No Executable Notifications found .
		if ( empty( $exec_notifications ) ) {
			return;
		}

		$notification = $exec_notifications[0];

		do_action( "${functionPrefix}_display_{$type}", $notification, $trigger_time );
	}

	/**
	 * Notification setup
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function setup_notifications() {
		$this->setup_notifications_by_type( 'notice' );
		$this->setup_notifications_by_type( 'popup' );
	}



	/**
	 * Display notice
	 *
	 * @param [type] $notice .
	 * @param [type] $trigger_time .
	 *
	 * @return void
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function display_notice( $notice, $trigger_time ) {
		$notice->notice_header();
		$notice->notice_content();
		$notice->notice_footer();

		$notice->core_script( $trigger_time );
	}

	/**
	 * Display Popup
	 *
	 * @param [type] $popup .
	 * @param [type] $trigger_time .
	 *
	 * @return void
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function display_popup( $popup, $trigger_time ) {
		$image_url = $popup->get_content( 'image_url' );

		?>

		<div class="${pluginClassPrefix}-popup" id="${pluginClassPrefix}-popup" data-plugin="<?php echo esc_attr( $this->slug ); ?>" tabindex="1">

			<div class="${pluginClassPrefix}-popup-overlay"></div>

			<div class="${pluginClassPrefix}-popup-modal" style="background-image: url('<?php echo esc_url( $image_url ); ?>'); --${pluginClassPrefix}-popup-color: <?php echo esc_attr( $popup->get_content( 'btn_color' ) ); ?>;">

				<!-- close  -->
				<div class="${pluginClassPrefix}-popup-modal-close popup-dismiss">×</div>

				<!-- content section  -->
				<div class="${pluginClassPrefix}-popup-modal-footer">

					<!-- countdown  -->
					<div class="${pluginClassPrefix}-popup-countdown" style="display: none;">
						<span class="${pluginClassPrefix}-popup-countdown-text"><?php echo esc_html__( 'Deal Ends In', '${pluginTextDomain}' ); ?></span>
						<div class="${pluginClassPrefix}-popup-countdown-time">
							<div>
								<span data-counter="days">00</span>
								<span><?php echo esc_html__( 'Days', '${pluginTextDomain}' ); ?></span>
							</div>
							<span>:</span>
							<div>
								<span data-counter="hours">00</span>
								<span><?php echo esc_html__( 'Hours', '${pluginTextDomain}' ); ?></span>
							</div>
							<span>:</span>
							<div>
								<span data-counter="minutes">00</span>
								<span><?php echo esc_html__( 'Minutes', '${pluginTextDomain}' ); ?></span>
							</div>
							<span>:</span>
							<div>
								<span data-counter="seconds">00</span>
								<span><?php echo esc_html__( 'Seconds', '${pluginTextDomain}' ); ?></span>
							</div>
						</div>
					</div>

					<!-- button  -->
					<a class="${pluginClassPrefix}-popup-button" target="_blank" href="<?php echo esc_url( $popup->get_content( 'button_url' ) ); ?>"><?php echo esc_html( $popup->get_content( 'button_text' ) ); ?></a>
				</div>
			</div>
		</div>

		<script>
			function ${functionPrefix}_popup_action(evt, $this, $action_type) {

				evt.preventDefault();

				$this.closest('.${pluginClassPrefix}-popup').fadeOut(200);

				jQuery.post('<?php echo esc_url( admin_url( 'admin-ajax.php' ) ); ?>', {
					action: '${functionPrefix}_notification_action',
					_wpnonce: '<?php echo esc_js( wp_create_nonce( '${functionPrefix}_notification_nonce' ) ); ?>',
					action_type: $action_type,
					notification_type: 'popup',
					trigger_time: '<?php echo esc_attr( $trigger_time ); ?>'
				});
			}

			// Notice Dismiss
			jQuery('body').on('click', '.${pluginClassPrefix}-popup .popup-dismiss', function(evt) {
				${functionPrefix}_popup_action(evt, jQuery(this), 'dismiss');
			});

			// Notice Disable
			jQuery('body').on('click', '.${pluginClassPrefix}-popup .popup-disable', function(evt) {
				${functionPrefix}_popup_action(evt, jQuery(this), 'disable');
			});
		</script>

		<?php
	}
}`;
        setStore({ notificationsPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginAuthor,
        pluginAuthorEmail,
        pluginTextDomain,
        functionPrefix,
        pluginClassPrefix,
        baseNamespace,
        pluginType,
    ]);
    return null;
};

export default NotificationsPhp;
