import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const UpgradePluginPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.upgradePluginPhp);
    const { advancedItem, pluginInfo, pluginType } = useGlobalPluginInfo();
    const { constantPrefix, baseNamespace } = advancedItem;
    const { pluginAuthor, pluginAuthorEmail } = pluginInfo;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes;

use WPAdminify\\Modules\\${baseNamespace}\\Libs\\Upgrader;`
        : `namespace ${baseNamespace}\\Inc\\Classes;

use ${baseNamespace}\\Libs\\Upgrader;`
}

if ( ! class_exists( 'Upgrade_Plugin' ) ) {
	/**
	 * Upgrade Plugin Class
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	class Upgrade_Plugin extends Upgrader {

		/**
		 * Lists of upgrades
		 *
		 * @var string[]
		 */
		protected $upgrades = array(
			'1.0.2' => 'Inc/Upgrades/upgrade-1.0.2.php', // path should be from root except trailingslash of the beginning.
		);
	}
}`;
        setStore({ upgradePluginPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constantPrefix, baseNamespace, pluginAuthor, pluginAuthorEmail, pluginType]);
    return null;
};

export default UpgradePluginPhp;
