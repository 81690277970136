import { useEffect } from "react";
import { useFileStore } from "../../../../strore/useFileStore";

const MixinScss = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.mixinScss);

    useEffect(() => {
        let value = `@mixin flexDisplay {
    display: flex;
    align-items: center;
    justify-content: center;
}`;

        setStore({ mixinScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default MixinScss;
