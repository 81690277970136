import React from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const Prefix = () => {
    const { advancedItem, setAdvancedItem } = useGlobalPluginInfo();

    return (
        <>
            <div className="grid grid-cols-2 items-center gap-[30px]">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Base Namespace
                    </label>
                    <span className="text-[.875rem] text-[#24263a] font-normal leading-[1rem] opacity-[.4] top-[-4px]">
                        Plugin initial namespace. ex: WPGENERATOR
                    </span>
                    <input
                        type="text"
                        defaultValue={advancedItem.baseNamespace}
                        placeholder="WPGENERATOR"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => {
                            setAdvancedItem({
                                ...advancedItem,
                                baseNamespace: e.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Main Class Name
                    </label>
                    <span className="text-[.875rem] text-[#24263a] font-normal leading-[1rem] opacity-[.4] top-[-4px]">
                        Plugin initial class name. ex: Weather_Forecast
                    </span>
                    <input
                        type="text"
                        defaultValue={advancedItem.mainClassName}
                        placeholder="Weather_Forecast"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => {
                            setAdvancedItem({
                                ...advancedItem,
                                mainClassName: e.target.value,
                            });
                        }}
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-[30px] mt-4">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Constant Prefix
                    </label>
                    <span className="text-[.875rem] text-[#24263a] font-normal leading-[1rem] opacity-[.4] top-[-4px]">
                        Plugin constant prefix. ex: WEATHER_ASSETS
                    </span>
                    <input
                        type="text"
                        defaultValue={advancedItem.constantPrefix}
                        placeholder="WEATHER_ASSETS"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] uppercase"
                        onChange={(e) => {
                            setAdvancedItem({
                                ...advancedItem,
                                constantPrefix: e.target.value.toUpperCase(),
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Function Prefix
                    </label>
                    <span className="text-[.875rem] text-[#24263a] font-normal leading-[1rem] opacity-[.4] top-[-4px]">
                        Plugin function prefix. ex: wf_get_results
                    </span>
                    <input
                        type="text"
                        defaultValue={advancedItem.functionPrefix}
                        placeholder="wf_get_results"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                        onChange={(e) => {
                            setAdvancedItem({
                                ...advancedItem,
                                functionPrefix: e.target.value.toLowerCase(),
                            });
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default Prefix;
