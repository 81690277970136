import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const PluginNameAdminCss = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.pluginNameAdminCss);
    const { pluginInfo } = useGlobalPluginInfo();
    const { pluginClassPrefix } = pluginInfo;

    useEffect(() => {
        let value = `/* Recommended */

.mt0 {
    margin-top: 0;
}

#TB_window {
    top: 43% !important;
}

#TB_window,
#TB_window iframe {
    width: 772px !important;
    height: 690px !important;
}

#plugin-information-scrollable {
    height: calc(100% - 65px) !important;
}

.${pluginClassPrefix}-recommended-wrapper .mr-0 {
    margin: 0 !important;
}

.${pluginClassPrefix}-recommended-wrapper .plugin-card .name,
.${pluginClassPrefix}-recommended-wrapper .plugin-card .desc {
    margin-left: 148px;
    margin-right: inherit !important;
}

.${pluginClassPrefix}-recommended-wrapper .button:not(.update-now).updating-message:before,
.${pluginClassPrefix}-recommended-wrapper .button:not(.update-now).updated-message:before,
.${pluginClassPrefix}-recommended-wrapper .button:not(.update-now).installed:before,
.${pluginClassPrefix}-recommended-wrapper .button:not(.update-now).installing:before {
    margin: 6px 10px 0px -3px !important;
}

.${pluginClassPrefix}-recommended-wrapper .plugin-status-active {
    color: #00BA88;
}

.${pluginClassPrefix}-recommended-wrapper .plugin-status-inactive {
    color: #F4B740;
}

.${pluginClassPrefix}-recommended-wrapper .plugin-status-not-install {
    color: #1d2327;
}

.${pluginClassPrefix}-recommended-wrapper .plugin-status {
    line-height: 34px;
}

.${pluginClassPrefix}-recommended-wrapper .hide {
    display: none;
}


/* Review */

#wpbody-content .notice-${pluginClassPrefix} {
    padding: 15px;
    position: relative;
}

#wpbody-content .notice-plugin-review {
    padding: 15px;
    position: relative;
}

#wpbody-content .notice-plugin-review .button:not(.${pluginClassPrefix}-notice-dismiss) {
    margin-right: 5px;
}

#wpbody-content .notice-plugin-review .${pluginClassPrefix}-notice-dismiss {
    box-shadow: none;
}


/* Popup */

#wpbody-content .${pluginClassPrefix}-notice-plugin-popup {
    max-width: 850px;
    min-height: 300px;
    left: 50%;
    top: 50%;
    width: 100%;
    height: auto;
    border-radius: 5px !important;
    transform: translate(-50%, -50%);
    border: 0;
    position: fixed;
    z-index: 99999;
    -webkit-box-shadow: 0px 0px 54px 0px rgb(20 20 42 / 30%) !important;
    box-shadow: 0px 0px 54px 0px rgb(20 20 42 / 30%) !important;
}

#wpbody-content .${pluginClassPrefix}-notice-plugin-popup .${pluginClassPrefix}-notice-dismiss {
    box-shadow: none;
    float: right;
    cursor: pointer;
}

.upgrade-pro-popup {
    gap: 15px;
    background: #fff;
    padding: 40px !important;
}

.upgrade-pro-popup .upst-body {
    display: flex;
}

.upgrade-pro-popup .col:nth-child(1) {
    padding-right: 20px;
    padding-top: 120px;
}

.upgrade-pro-popup .col:nth-child(1) img {
    max-width: 100%;
    /* -webkit-box-shadow: 0px 0px 54px 0px rgb(20 20 42 / 10%) !important;
    box-shadow: 0px 0px 54px 0px rgb(20 20 42 / 10%) !important; */
}

.upgrade-pro-popup .col {
    flex-basis: 50%;
}

.upgrade-pro-popup .col:nth-child(2) {
    padding-left: 20px;
}

.upgrade-pro-popup .upst-body .content-body {
    color: rgba(78, 75, 102, 0.72);
}

.upgrade-pro-popup .upst-body .content-body h4 {
    display: flex;
    color: #FB0066;
    margin-bottom: 10px;
    letter-spacing: 1px;
    gap: 10px;
    font-size: 22px;
    line-height: 32px;
}

.upgrade-pro-popup .upst-body .content-body h3 {
    color: #14142B;
    letter-spacing: 1px;
    font-size: 28px;
}

.upgrade-pro-popup .upst-body .content-body h3 span {
    color: #0347ff;
}

.upgrade-pro-popup .upst-body .content-body p {
    font-weight: normal !important;
    line-height: 22px !important;
    font-size: 16px !important;
}

.upgrade-pro-popup .upst-body .content-body ul li {
    margin: 10px 0;
    line-height: 22px;
    padding-left: 30px;
}

.upgrade-pro-popup ul li:before {
    position: absolute;
    content: "\\f15e";
    font-family: dashicons;
    font-size: 19px;
    left: 0;
    top: 0;
    z-index: 1;
}

.upgrade-pro-popup .upst-body .content-body ul li:before {
    color: #fff;
    top: 2px;
    left: 1px;
    font-size: 17px;
}

.upgrade-pro-popup .upst-body .content-body ul li:after {
    position: absolute;
    content: "";
    background: #00BA88;
    border-radius: 50px;
    width: 18px;
    height: 18px;
    top: 3px !important;
    left: 1px;
}

.upgrade-pro-popup ul li {
    position: relative;
    list-style: none;
    padding-left: 25px;
}

.upgrade-pro-popup .upst-body .content-body ul li span {
    color: #14142B !important;
}

.upgrade-pro-popup .upst-body .content-body .upgrade-btn {
    background: #0347ff;
    color: #fff;
    font-size: 18px;
    border: 0 !important;
    border-radius: 7px;
    padding: 5px 25px;
}

.upgrade-pro-popup .upst-footer ul {
    display: flex;
    padding-top: 60px;
    width: 70%;
    margin: 0 auto;
    justify-content: space-between;
}

.upgrade-pro-popup ul li {
    position: relative;
    list-style: none;
    padding-left: 25px;
}

.upgrade-pro-popup .upst-footer ul li a {
    color: rgba(78, 75, 102, 0.72) !important;
}

.upgrade-pro-popup .upst-footer ul li a:hover {
    color: #0347FF !important;
}

.upgrade-pro-popup .upst-footer ul li:before {
    font-size: 22px;
    color: #00BA88;
}

.${pluginClassPrefix}-notice-plugin-popup .${pluginClassPrefix}-notice-dismiss {
    border: 0 !important;
    outline: none !important;
    background: transparent !important;
}

.${pluginClassPrefix}-notice-plugin-popup .${pluginClassPrefix}-notice-dismiss:before {
    content: "\\00d7" !important;
    font-size: 30px !important;
    height: 22px !important;
    width: 22px !important;
    color: rgba(78, 75, 102, 0.72) !important;
}

body.${pluginClassPrefix}-popup-overlay {
    position: relative;
    height: auto;
}

body.${pluginClassPrefix}-popup-overlay:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;
}


/* Subscribe form */

#wpbody-content .${pluginClassPrefix}-notice-subscribe {
    padding: 15px;
    display: flex;
    align-items: center;
}

.${pluginClassPrefix}-notice-subscribe img {
    width: 50px;
    max-width: 50px;
    height: 50px;
    margin-right: 15px;
}

.${pluginClassPrefix}-notice-subscribe h4 {
    margin-top: 0;
}

.${pluginClassPrefix}-notice-subscribe-input {
    margin-right: 15px;
}


/*---- badge ---*/

.${pluginClassPrefix}-badge:after {
    color: #fff;
    position: absolute;
    font-size: 11px;
    padding: 1px 7px;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 3px;
    display: none;
}

.${pluginClassPrefix}-badge-pro:after {
    content: "Pro";
    background: darkorange;
}

.${pluginClassPrefix}-badge-ultimate:after {
    content: "Ultimate";
    background: blueviolet;
    right: -60px;
}


/* Disable Field */

.image-choose-opt.disabled .disabled-text {
    position: absolute;
    width: 90px;
    height: 90px;
    left: 5px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(85, 85, 85, 0.7);
    color: #fff;
}

.form-table tr.disabled {
    position: relative;
}

.form-table tr.disabled>* {
    opacity: 0.6;
}

.form-table tr.disabled>* input,
.form-table tr.disabled>* select,
.form-table tr.disabled>* textarea,
.form-table tr.disabled>* button {
    pointer-events: none;
}

.form-table tr.disabled:hover .${pluginClassPrefix}-badge:after {
    display: inline;
}


/* Start of Overlay Upgrade Popup  */

:root {
    --${pluginClassPrefix}-popup-color: #FF631A;
}

.${pluginClassPrefix}-popup * {
    all: initial;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.${pluginClassPrefix}-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999 !important;
}

.${pluginClassPrefix}-popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.${pluginClassPrefix}-popup-modal {
    width: 600px;
    max-width: 600px !important;
    height: 600px;
    max-height: 600px !important;
    color: white;
    background: #222;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    transform: scale(0.9);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 3px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
}

.${pluginClassPrefix}-popup-modal-close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 50px;
    cursor: pointer;
    color: var(--${pluginClassPrefix}-popup-color);
    transition: .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    opacity: .5;
}

.${pluginClassPrefix}-popup-modal-close:hover {
    opacity: 1;
}

.${pluginClassPrefix}-popup-modal-footer {
    width: 100%;
    height: 225px;
    max-height: 225px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 0;
}

.${pluginClassPrefix}-popup-countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
}

.${pluginClassPrefix}-popup-countdown-text {
    font-size: 14px;
    font-weight: 600;
    color: white;
    position: relative;
    line-height: 1.2;
}

.${pluginClassPrefix}-popup-countdown-time {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 10px;
}

.${pluginClassPrefix}-popup-countdown-time>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 5px;
}

.${pluginClassPrefix}-popup-countdown-time>div>span {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.${pluginClassPrefix}-popup-countdown-time>div>span:nth-child(1) {
    border: 2px solid rgba(255, 255, 255, 0.6);
    height: 40px;
    width: 45px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.${pluginClassPrefix}-popup-countdown-time>div>span:nth-child(2) {
    font-size: 12px;
    font-weight: 500;
    color: rgb(255, 255, 255 / .8);
}

.${pluginClassPrefix}-popup-countdown-time>span {
    font-size: 50px;
    color: white;
    margin-top: -25px;
}

.${pluginClassPrefix}-popup-button {
    height: 50px;
    background: var(--${pluginClassPrefix}-popup-color);
    color: #222;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;
    color: white;
    padding: 0 30px;
    margin: 20px 0;
    transition: .2s;
    position: relative;
    text-decoration: none;
}

.${pluginClassPrefix}-popup-button:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transition: .3s;
}

.${pluginClassPrefix}-popup-button:hover {
    color: white;
}

.${pluginClassPrefix}-popup-button:hover:after {
    width: 100%;
}

.${pluginClassPrefix}-wwc>p {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 20px;
}

.${pluginClassPrefix}-wwc-content {
    margin-bottom: 25px;
    color: #646970;
}


/* responsive  */

@media (max-width: 576px) {
    .${pluginClassPrefix}-popup-countdown {
        transform: scale(.99);
    }
}


/* End of Overlay Upgrade Popup  */`;

        setStore({ pluginNameAdminCss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginClassPrefix]);

    return null;
};

export default PluginNameAdminCss;
