import React from "react";

const RestApi = () => {
  return (
    <div>
      <h1>This is a Rest Api page</h1>
    </div>
  );
};

export default RestApi;
