import JSZip from "jszip";
import { saveAs } from "save-as";

// download
export function getDownload(fileStructures, folderName = "rootFolder") {
    const rootFolderName = folderName;
    const zip = new JSZip();

    const rootFolder = zip.folder(`${rootFolderName}`);

    traverseAndAddToZip(fileStructures, zip, rootFolder, rootFolderName);

    zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${rootFolderName}.zip`);
    });
}

// Function to recursively iterate over the array of objects
function traverseAndAddToZip(data, zip, dyFolder, rootFolder) {
    data.forEach((item) => {
        /**
         * Folders Download
         */

        if (item.type === "folder" && !item?.isDownload) {
            const dynamicFolder = zip.folder(`${rootFolder}/${item.path || "no_folder"}`);

            if (item.folders.length > 0) {
                traverseAndAddToZip(item.folders, zip, dynamicFolder, rootFolder);
            }

            if (item.files.length > 0) {
                traverseAndAddToZip(item.files, zip, dynamicFolder, rootFolder);
            }
        }

        if (item.type === "folder" && item?.isDownload === "yes") {
            const dynamicFolder = zip.folder(`${rootFolder}/${item.path || "no_folder"}`);

            if (item.folders.length > 0) {
                traverseAndAddToZip(item.folders, zip, dynamicFolder, rootFolder);
            }

            if (item.files.length > 0) {
                traverseAndAddToZip(item.files, zip, dynamicFolder, rootFolder);
            }
        }

        /**
         * File Download
         */
        if (item.type === "file" && !item?.img && !item?.isDownload) {
            dyFolder.file(`${item.name}`, item.data);
        }

        if (item.type === "file" && !item?.img && item?.isDownload === "yes") {
            dyFolder.file(`${item.name}`, item.data);
        }

        /**
         * Image Download
         */
        if (item.type === "file" && !!item?.img && !item?.isDownload) {
            dyFolder.file(`${item?.name}`, imaData(item?.data), {
                Base64: true,
            });
        }

        if (item.type === "file" && !!item?.img && !item?.isDownload === "yes") {
            dyFolder.file(`${item?.name}`, imaData(item?.data), {
                Base64: true,
            });
        }
    });
}

// image download
const imaData = async (data) => {
    const imageBlob = await fetch(data).then((response) => response.blob());
    return new File([imageBlob], "flName.jpg");
};

/// Backup Manual Process

// function traverseAndAddToZip(data, zip, dyFolder, rootFolder, feature) {
//     data.forEach((item, index) => {
//         // if (item.type === "folder") {
//         //     const dynamicFolder = zip.folder(`${rootFolder}/${item.path || "no_folder"}`);

//         //     if (item.folders.length > 0) {
//         //         traverseAndAddToZip(item.folders, zip, dynamicFolder, rootFolder, feature);
//         //     }

//         //     if (item.files.length > 0) {
//         //         traverseAndAddToZip(item.files, zip, dynamicFolder, rootFolder, feature);
//         //     }
//         // }

//         /**
//          * Folders Condition
//          */
//         if (
//             item.type === "folder" &&
//             feature === "wordpress_only" &&
//             item?.feature !== "free_pro_bundle" &&
//             !item?.isDownload
//         ) {
//             const dynamicFolder = zip.folder(`${rootFolder}/${item.path || "no_folder"}`);

//             if (item.folders.length > 0) {
//                 traverseAndAddToZip(item.folders, zip, dynamicFolder, rootFolder, feature);
//             }

//             if (item.files.length > 0) {
//                 traverseAndAddToZip(item.files, zip, dynamicFolder, rootFolder, feature);
//             }
//         }

//         if (item.type === "folder" && feature === "free_pro_bundle" && !item?.isDownload) {
//             const dynamicFolder = zip.folder(`${rootFolder}/${item.path || "no_folder"}`);

//             if (item.folders.length > 0) {
//                 traverseAndAddToZip(item.folders, zip, dynamicFolder, rootFolder, feature);
//             }

//             if (item.files.length > 0) {
//                 traverseAndAddToZip(item.files, zip, dynamicFolder, rootFolder, feature);
//             }
//         }

//         if (item.type === "folder" && item?.isDownload === "yes") {
//             const dynamicFolder = zip.folder(`${rootFolder}/${item.path || "no_folder"}`);

//             if (item.folders.length > 0) {
//                 traverseAndAddToZip(item.folders, zip, dynamicFolder, rootFolder, feature);
//             }

//             if (item.files.length > 0) {
//                 traverseAndAddToZip(item.files, zip, dynamicFolder, rootFolder, feature);
//             }
//         }

//         /**
//          * Files Conditions
//          */
//         if (item.type === "file" && !item?.img) {
//             if (feature === "wordpress_only" && item?.feature !== "free_pro_bundle") {
//                 dyFolder.file(`${item.name}`, item.data);
//             }

//             if (feature === "free_pro_bundle") {
//                 dyFolder.file(`${item.name}`, item.data);
//             }
//         }

//         // if (item.type === "file" && !item?.img) {
//         //     dyFolder.file(`${item.name}`, item.data);
//         // }

//         if (item.type === "file" && !!item?.img) {
//             dyFolder.file(`${item?.name}`, imaData(item?.data), {
//                 Base64: true,
//             });
//         }
//     });
// }
