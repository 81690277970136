import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../context/pluginInfoContext";
import { useFileStore } from "../../strore/useFileStore";

const RowLinksPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.rowLinkPhp);
    const { advancedItem, pluginInfo, pluginType } = useGlobalPluginInfo();
    const { constantPrefix, baseNamespace } = advancedItem;
    const { pluginAuthor, pluginAuthorEmail } = pluginInfo;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Libs;`
        : `namespace ${baseNamespace}\\Libs;`
}

// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

if ( ! class_exists( 'RowLinks' ) ) {
	/**
	 * Row Links Class
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	class RowLinks {


		/**
		 * Constructor
		 */
		public function __construct() {
			add_filter( 'plugin_action_links_' . ${constantPrefix}_BASE, array( $this, 'plugin_action_links' ) );
		}

		/**
		 * Plugin action links
		 *
		 * @param [type] $links .
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function plugin_action_links( $links ) {
			return $links;
		}
	}
}`;
        setStore({ rowLinkPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constantPrefix, baseNamespace, pluginAuthor, pluginAuthorEmail, pluginType]);

    return null;
};

export default RowLinksPhp;
