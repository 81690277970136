import React, { useEffect, useReducer, useState } from "react";
import { useGlobalPluginInfo } from "../../context/pluginInfoContext";
import { getPluginFolderName } from "../../utils/getPluginFolderName";

const reducer = (state, action) => {
    switch (action.type) {
        case "TEXT":
            let newItems = {...state, [action.handle]: action.payload }
            return newItems;
        default:
            break;
    }
}

const General = () => {
    const { pluginInfo, setPluginInfo, pluginType } = useGlobalPluginInfo();
    const [showGeneral, setShowGeneral] = useState(false);
    const [pluginInfoValues, dispatch] = useReducer(reducer, pluginInfo);

    const rootFolderName = getPluginFolderName(pluginInfo?.pluginName);

    useEffect(() => {
        setPluginInfo(pluginInfoValues)
    }, [pluginInfoValues, setPluginInfo])

    useEffect(() => {
        dispatch({type: "TEXT", payload: rootFolderName, handle: "pluginTextDomain"})
        dispatch({type: "TEXT", payload: rootFolderName, handle: "pluginClassPrefix"})
    }, [rootFolderName])

    return (
        <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
            <div
                className={`py-3 px-5 bg-[#F7F7F7]  ${
                    showGeneral ? "" : "border-b-[1px] border-[#D8D8D8]"
                }`}
                onClick={() => setShowGeneral(!showGeneral)}
            >
                <span className="text-[#24263a] text-[1rem] font-normal">
                    General
                </span>
            </div>
            
            <div className={`${showGeneral ? "hidden" : "block"} p-5`}>
                <div className="grid grid-cols-2 gap-8">
                    <div>
                        <label
                            htmlFor="PluginName"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Plugin Name
                        </label>
                        <input
                            type="text"
                            id="PluginName"
                            value={pluginInfoValues.pluginName}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginName"})}
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="PluginURI"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Plugin URI
                        </label>
                        <input
                            type="url"
                            id="PluginURI"
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            value={pluginInfoValues.pluginUri}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginUri"})}
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <label
                        htmlFor="Description"
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Description
                    </label>
                    <input
                        type="text"
                        id="Description"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        value={pluginInfoValues.pluginDescription}
                        onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginDescription"})}
                    />
                </div>
                <div className="grid grid-cols-2 gap-8 mt-3">
                    <div>
                        <label
                            htmlFor="Version"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Version
                        </label>
                        <input
                            type="text"
                            id="Version"
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            value={pluginInfoValues.pluginVersion}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginVersion"})}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="Author"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Author
                        </label>
                        <input
                            type="text"
                            id="Author"
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            value={pluginInfoValues.pluginAuthor}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginAuthor"})}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-8 mt-3">
                    <div>
                        <label
                            htmlFor="AuthorURI"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Author URI
                        </label>
                        <input
                            type="url"
                            id="AuthorURI"
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            value={pluginInfoValues.pluginAuthorUrl}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginAuthorUrl"})}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="AuthorEmail"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Author Email
                        </label>
                        <input
                            type="email"
                            id="AuthorEmail"
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            value={pluginInfoValues.pluginAuthorEmail}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginAuthorEmail"})}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-8 mt-3">
                    <div>
                        <label
                            htmlFor="License"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            License
                        </label>
                        <select onChange={e => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginLicense"})} name="" id="" className="w-full p-2 mt-2 border border-[#ececee] bg-[#f6f6f9]">
                            <option value="GPLv3 or later">GPLv3 or later</option>
                            <option value="GPL-2.0+">GPL-2.0+</option>
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor="LicenseURI"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            License URI
                        </label>
                          <select onChange={e => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginLicenseUrl"})} name="" id="" className="w-full p-2 mt-2 border border-[#ececee] bg-[#f6f6f9]">
                            <option value="https://www.gnu.org/licenses/gpl-3.0.html">https://www.gnu.org/licenses/gpl-3.0.html</option>
                            <option value="http://www.gnu.org/licenses/gpl-2.0.txt">http://www.gnu.org/licenses/gpl-2.0.txt</option>
                        </select>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-8 mt-3">
                    <div>
                        <label
                            htmlFor="TextDomain"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Text Domain
                        </label>
                        <input
                            type="text"
                            id="TextDomain"
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            value={pluginInfoValues.pluginTextDomain}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginTextDomain"})}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="DomainPath"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Domain Path
                        </label>
                        <input
                            type="text"
                            id="DomainPath"
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            value={pluginInfoValues.pluginDomainPath}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginDomainPath"})}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-8 mt-3">
                    <div>
                        <label
                            htmlFor="ClassJsPrefix"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                           CSS Class Prefix
                        </label>
                        <input
                            type="text"
                            id="ClassJsPrefix"
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            value={pluginInfoValues.pluginClassPrefix}
                            onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "pluginClassPrefix"})}
                        />
                    </div>
                    {
                        pluginType === "free_pro_bundle" ? (
                            <div>
                                <label
                                    htmlFor="ClassJsPrefix"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                Ask For Rating URL
                                </label>
                                <input
                                    type="text"
                                    id="ClassJsPrefix"
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    value={pluginInfoValues.askForRating}
                                    onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "askForRating"})}
                                />
                            </div>
                        ) : null
                    }
                    
                </div>
            </div>
        </div>
    );
};

export default General;
