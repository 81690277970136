import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { getPluginFolderName } from "../utils/getPluginFolderName";
import snakeCase from "lodash/snakeCase"
import { useFileStore } from "../strore/useFileStore";

const WebpackMixJs = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.webpackMixJs);
    const { pluginInfo, addCssItem, addJsItem } = useGlobalPluginInfo();
    const { pluginName, pluginTextDomain } = pluginInfo;

	const rootFolderName = getPluginFolderName(pluginName);

    useEffect(() => {
        let value = `const mix = require("laravel-mix");
const fs = require('fs');
const wpPot = require("wp-pot");

mix.options({
    autoprefixer: {
        remove: false,
    },
    processCssUrls: false,
    terser: {
        terserOptions: {
            keep_fnames: true
        }
    }
});

mix.webpackConfig({
    target: "web",
    externals: {
        jquery: "window.jQuery",
        $: "window.jQuery",
        wp: "window.wp",
        _${snakeCase(rootFolderName)}: "window._${snakeCase(rootFolderName)}",
    },
});

mix.sourceMaps(false, 'source-map');

// Disable notification on dev mode
if (process.env.NODE_ENV.trim() !== "production") mix.disableNotifications();

if (process.env.NODE_ENV.trim() === 'production') {

    // Language pot file generator
    wpPot({
        destFile: "languages/${pluginTextDomain}.pot",
        domain: "${pluginTextDomain}",
        package: "${pluginName}",
        src: "**/*.php",
    });
}

// SCSS to CSS
mix.sass("dev/scss/sdk.scss", "assets/css/${rootFolderName}-sdk.min.css");
mix.sass("dev/scss/survey.scss", "assets/css/${rootFolderName}-survey.css");
${
    addCssItem?.map((item) => `mix.sass("dev/scss/${item?.handleName}.scss", "assets/css/${item?.fileName}");`).join("\n")
}

// mix.sass("dev/scss/admin-settings.scss", "assets/css/${rootFolderName}-admin-settings.min.css");
// mix.sass("dev/scss/premium/${rootFolderName}-pro-styles.scss", "Pro/assets/css/${rootFolderName}-pro.min.css");

// Scripts to js - regular
// mix.scripts( 'dev/js/${rootFolderName}.js', 'assets/js/${rootFolderName}.js' );
${
    addJsItem?.map((item) => `mix.scripts("dev/js/${item?.handleName}.js", "assets/js/${item?.fileName}");`).join("\n")
}

// Third Party Plugins Support
// fs.readdirSync('dev/scss/plugins').forEach(
//     file => {
//         mix.sass('dev/scss/plugins/' + file, 'assets/css/plugins/' + file.substring(1).replace('.scss', '.min.css'));
//     }
// );

// fs.readdirSync('dev/scss/premium/plugins/').forEach(
//     file => {
//         mix.sass('dev/scss/premium/plugins/' + file, 'Pro/assets/css/plugins/' + file.substring(1).replace('.scss', '.min.css'));
//     }
// );
`;

    setStore({webpackMixJs: value});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginName, pluginTextDomain, rootFolderName, addCssItem, addJsItem]);

    return null;
};

export default WebpackMixJs;
