import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../../strore/useFileStore";

const DatePhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.datePhp);
    const { advancedItem, pluginInfo, pluginType } = useGlobalPluginInfo();

    const { baseNamespace } = advancedItem;
    const { pluginAuthor, pluginAuthorEmail } = pluginInfo;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Base;`
        : `namespace ${baseNamespace}\\Inc\\Classes\\Notifications\\Base;`
}


// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

trait Date {

	/**
	 * Current time
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function current_time() {
		return current_time( 'Y-m-d' );
	}

	/**
	 * Compare dates
	 *
	 * @param [type] $date_1 .
	 * @param [type] $date_2 .
	 * @param [type] $compare .
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function date_compare( $date_1, $date_2 = null, $compare = null ) {
		if ( ! $compare ) {
			$compare = '==';
		}

		if ( ! $date_2 ) {
			$date_2 = $this->current_time();
		}

		if ( '<' === $compare ) {
			return strtotime( $date_1 ) < strtotime( $date_2 );
		} elseif ( '>' === $compare ) {
			return strtotime( $date_1 ) > strtotime( $date_2 );
		} elseif ( '<=' === $compare ) {
			return strtotime( $date_1 ) <= strtotime( $date_2 );
		} elseif ( '>=' === $compare ) {
			return strtotime( $date_1 ) >= strtotime( $date_2 );
		} else {
			return strtotime( $date_1 ) === strtotime( $date_2 );
		}
	}

	/**
	 * Date Diff
	 *
	 * @param [type] $date_1 .
	 * @param [type] $date_2 .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function date_diff( $date_1, $date_2 = null ) {
		if ( ! $date_2 ) {
			$date_2 = $this->current_time();
		}
		$diff = date_diff( date_create( $date_2 ), date_create( $date_1 ) );

		return $diff->format( '%R%a' );
	}


	/**
	 * Check Current date
	 *
	 * @param [type] $date_1 .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function date_is_current( $date_1 ) {
		return $this->date_compare( $date_1 );
	}

	/**
	 * Check Prev date
	 *
	 * @param [type] $date_1 .
	 * @param [type] $date_2 .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function date_is_prev( $date_1, $date_2 = null ) {
		return $this->date_compare( $date_1, $date_2, '<' );
	}


	/**
	 * Check current or previous date
	 *
	 * @param [type] $date_1 .
	 * @param [type] $date_2 .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function date_is_current_or_prev( $date_1, $date_2 = null ) {
		return $this->date_compare( $date_1, $date_2, '<=' );
	}


	/**
	 * Date is next day
	 *
	 * @param [type] $date_1 .
	 * @param [type] $date_2 .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function date_is_next( $date_1, $date_2 = null ) {
		return $this->date_compare( $date_1, $date_2, '>' );
	}

	/**
	 * Current date or next date
	 *
	 * @param [type] $date_1 .
	 * @param [type] $date_2 .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function date_is_current_or_next( $date_1, $date_2 = null ) {
		return $this->date_compare( $date_1, $date_2, '>=' );
	}


	/**
	 * Date increament
	 *
	 * @param [type] $date .
	 * @param [type] $days .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function date_increment( $date, $days ) {
		return gmdate( 'Y-m-d', strtotime( $date . " + $days days" ) );
	}
}`;

        setStore({ datePhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseNamespace, pluginAuthor, pluginAuthorEmail, pluginType]);

    return null;
};

export default DatePhp;
