import { useState } from "react";
import Tabs from "../../Tabs/Tabs"
import CRMLicense from "./CRMLicense";
import CRMSetting from "./CRMSetting";
import Sheet from "./Sheet";
import DashWidget from "./DashWidget";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const CRMAndLicenseSetting = () => {
    const [CRMSettingsTab, setCRMSettingsTab] = useState(1);
    const { pluginType } = useGlobalPluginInfo();

    const CRMSettings = [
        {
            id: 1,
            name: "CRM",
        },
        {
            id: 2,
            name: "License",
        },
        {
            id: 3,
            name: "Sheet",
        },
        {
            id: 4,
            name: "Dash Widget",
        },
    ];


    return (
        <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
            <Tabs
                tabs={CRMSettingsTab}
                setTabs={setCRMSettingsTab}
                tabName={CRMSettings}
                tabsItems={
                    <div className="px-5 pb-5 mt-10">
                        {CRMSettingsTab === 1 && (
                            <CRMSetting />
                        )}
                        {CRMSettingsTab === 2 && (
                            pluginType ==="free_pro_bundle" ? <CRMLicense /> : <div className="bg-red-200 p-3 rounded-md text-center">This features only for pro version.</div>
                        )}
                        {CRMSettingsTab === 3 && (
                            <Sheet />
                        )}
                        {CRMSettingsTab === 4 && (
                             <DashWidget />
                        )}
                    </div>
                }
            />
        </div>
    );
}

export default CRMAndLicenseSetting