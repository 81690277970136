import { useState } from "react";
import Tabs from "../../Tabs/Tabs";
import AddCssFiles from "./AddCssFiles";
import AddJsFiles from "./AddJsFiles";
import AddTable from "./AddTable";

const AssetsFiles = () => {
    const [secondFilesTabs, setSecondFilesTabs] = useState(1);

    const FileName = [
        {
            id: 1,
            name: "Css",
        },
        {
            id: 2,
            name: "Js",
        },
        {
            id: 3,
            name: "Table",
        }
    ];

    return (
        <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
            <Tabs
                tabs={secondFilesTabs}
                setTabs={setSecondFilesTabs}
                tabName={FileName}
                tabsItems={
                    <div className="px-5 pb-5 mt-10">
                        {secondFilesTabs === 1 && (
                            <AddCssFiles />
                        )}

                        {secondFilesTabs === 2 && (
                            <AddJsFiles />
                        )}

                        {secondFilesTabs === 3 && (
                            <AddTable />
                        )}

                    </div>
                }
            />
        </div>
    )
}

export default AssetsFiles;