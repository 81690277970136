import FileView from "../FileView/FileView";

const FileStructureModel = ({ item }) => {
    return (
        <ul>
            <li>
                <FileView
                    fileName={item?.name}
                    icon={item?.icon}
                    data={item?.data}
                />
            </li>
        </ul>
        
    );
};

export default FileStructureModel;
