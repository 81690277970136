import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../context/pluginInfoContext";
import { useFileStore } from "../../strore/useFileStore";

const FunctionsPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.functionPhp);
    const { pluginInfo, advancedItem, pluginType } = useGlobalPluginInfo();

    const { pluginTextDomain, pluginName, pluginClassPrefix } = pluginInfo;

    const { functionPrefix, mainClassName } = advancedItem;

    useEffect(() => {
        let value = `<?php

if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

${
    pluginType === "adminify_module"
        ? `/**
* Function: Ajax Call for Install and Activate WP Adminify Plugin
*/
function ${functionPrefix}_install_and_activate_plugin() {

   // Include necessary WordPress files
   require_once ABSPATH . 'wp-admin/includes/plugin-install.php';
   require_once ABSPATH . 'wp-admin/includes/class-wp-upgrader.php';
   require_once ABSPATH . 'wp-admin/includes/class-wp-ajax-upgrader-skin.php';
   require_once ABSPATH . 'wp-admin/includes/class-plugin-upgrader.php';

   if ( isset( $_POST['plugin'] ) ) {

	   $nonce = isset( $_POST['nonce'] ) ? sanitize_text_field( wp_unslash( $_POST['nonce'] ) ) : '';

	   if ( ! wp_verify_nonce( $nonce, '${functionPrefix}_recommended_nonce' ) ) {
		   wp_send_json_error( array( 'mess' => esc_html__( 'Nonce is invalid', '${pluginTextDomain}' ) ) );
	   }

	   if ( ( is_multisite() && is_network_admin() ) || ! current_user_can( 'install_plugins' ) ) {
		   wp_send_json_error( array( 'mess' => esc_html__( 'Invalid Access', '${pluginTextDomain}' ) ) );
	   }

	   $plugin = sanitize_text_field( wp_unslash( $_POST['plugin'] ) );

	   if ( empty( $plugin ) ) {
		   wp_send_json_error( array( 'mess' => esc_html__( 'Invalid plugin', '${pluginTextDomain}' ) ) );
	   }

	   $type     = isset( $_POST['type'] ) ? sanitize_text_field( wp_unslash( $_POST['type'] ) ) : 'install';
	   $skin     = new \\WP_Ajax_Upgrader_Skin();
	   $upgrader = new \\Plugin_Upgrader( $skin );

	   if ( 'install' === $type ) {
		   $result = $upgrader->install( $plugin );
		   if ( is_wp_error( $result ) ) {
			   wp_send_json_error(
				   array(
					   'mess' => $result->get_error_message(),
				   )
			   );
		   }
		   $args        = array(
			   'slug'   => $upgrader->result['destination_name'],
			   'fields' => array(
				   'short_description' => true,
				   'icons'             => true,
				   'banners'           => false,
				   'added'             => false,
				   'reviews'           => false,
				   'sections'          => false,
				   'requires'          => false,
				   'rating'            => false,
				   'ratings'           => false,
				   'downloaded'        => false,
				   'last_updated'      => false,
				   'added'             => false,
				   'tags'              => false,
				   'compatibility'     => false,
				   'homepage'          => false,
				   'donate_link'       => false,
			   ),
		   );
		   $plugin_data = plugins_api( 'plugin_information', $args );

		   if ( $plugin_data && ! is_wp_error( $plugin_data ) ) {
			   $install_status = \\install_plugin_install_status( $plugin_data );
			   activate_plugin( $install_status['file'] );
		   }
		   wp_die();  // die();
	   }
   }
}

/**
* Function: Parent Plugin Installed.
*/
function ${functionPrefix}_is_parent_plugin_installed() {
   $get_plugins = get_plugins();
   $get_plugins = array_keys( $get_plugins );

   foreach ( $get_plugins as $basename ) {
	   if ( 0 === strpos( $basename, 'adminify/' ) || 0 === strpos( $basename, 'adminify-pro/' ) ) {
		   return true;
	   }
   }
   return false;
}

/**
* Function: Check WP Adminify Loaded
*/
function ${functionPrefix}_fs_not_loaded_notice() {
   add_action( 'admin_notices', '${functionPrefix}_notice_missing_parent_plugin' );
   add_action( 'network_admin_notices', '${functionPrefix}_notice_missing_parent_plugin' );
}

/**
* Function: Admin Notice for WP Adminify plugin
*/
function ${functionPrefix}_notice_missing_parent_plugin() {

   if ( ( is_multisite() || ! is_network_admin() ) && !current_user_can('install_plugins') ) {
	   // Bail early if requirements not met.
	   return;
   }

   $get_plugins = get_plugins();
   $get_plugins = array_keys( $get_plugins );

   foreach ( $get_plugins as $basename ) {
	   if ( 0 === strpos( $basename, 'adminify/' ) || 0 === strpos( $basename, 'adminify-pro/' ) ) {
		   $jltwp_adminify = $basename;
	   }
   }

   if ( ${functionPrefix}_is_parent_plugin_installed() ) {
	   // Activate Plugin .
	   $classes               = 'activate-adminify-now';
	   $install_active_url    = wp_nonce_url( 'plugins.php?action=activate&amp;plugin=' . $jltwp_adminify . '&amp;plugin_status=all&amp;paged=1&amp;s', 'activate-plugin_' . $jltwp_adminify );
	   $message               = sprintf( __( '<b>%1$s</b> requires <strong>WP Adminify</strong> plugin to be active. Please activate WP Adminify to continue.', '${pluginTextDomain}' ), '${pluginName}' );
	   $button_text           = esc_html__( 'Activate WP Adminify', '${pluginTextDomain}' );
	   $button                = '<p><a href="' . esc_url( $install_active_url ) . '" class="button-primary">' . esc_html( $button_text ) . '</a></p>';

   } else {

	   // Install Plugin .
	   // /* translators: 1: strong start tag, 2: strong end tag. */
	   $message     = sprintf( __( '<b>%1$s</b> requires <strong>"WP Adminify"</strong> plugin to be installed and activated. Please install WP Adminify to continue.', '${pluginTextDomain}' ), '${pluginName}' );
	   $button_text = esc_html__( 'Install WP Adminify', '${pluginTextDomain}' );
	   $classes     = 'install-adminify-${pluginClassPrefix}-now';
	   $button      = '<p><a class="install-now button-primary" data-plugin="' . esc_url( 'https://downloads.wordpress.org/plugin/adminify.zip' ) . '">' . esc_html( $button_text ) . '</a></p>';
   }
   printf( '<div class="notice notice-warning %3$s"><p>%1$s</p>%2$s</div>', $message, $button, $classes );
}


function ${functionPrefix}_fs_is_parent_active_and_loaded() {
   // Check if the parent's init SDK method exists.
   return function_exists( 'jltwp_adminify' );
}

function ${functionPrefix}_fs_is_parent_active() {
   $active_plugins = get_option( 'active_plugins', array() );

   if ( is_multisite() ) {
	   $network_active_plugins = get_site_option( 'active_sitewide_plugins', array() );
	   $active_plugins         = array_merge( $active_plugins, array_keys( $network_active_plugins ) );
   }

   foreach ( $active_plugins as $basename ) {
	   if (
		   0 === strpos( $basename, 'adminify/' ) ||
		   0 === strpos( $basename, 'adminify-pro/' )
	   ) {
		   return true;
	   }
   }

   return false;
}

/**
* Freemius Iniit Hooks
*/
function ${functionPrefix}_fs_init() {
   if ( ${functionPrefix}_fs_is_parent_active_and_loaded() ) {
	   // Init Freemius.
	   ${functionPrefix}_fs();

	   // Signal that the add-on's SDK was initiated.
	   do_action( '${functionPrefix}_fs_loaded' );

   } else {
	   // Parent is inactive, add your error handling here.
	   do_action( '${functionPrefix}_fs_not_loaded' );
   }
}

if ( ! function_exists( '${functionPrefix}_is_plugin_active' ) ) {
   function ${functionPrefix}_is_plugin_active( $plugin_basename ) {
	   include_once ABSPATH . 'wp-admin/includes/plugin.php';
	   return is_plugin_active( $plugin_basename );
   }
}`
        : ""
}

/*
 * @version       1.0.0
 * @package       ${mainClassName}
 * @license       Copyright ${mainClassName}
 */

if ( ! function_exists( '${functionPrefix}_option' ) ) {
	/**
	 * Get setting database option
	 *
	 * @param string $section default section name ${functionPrefix}_general .
	 * @param string $key .
	 * @param string $default .
	 *
	 * @return string
	 */
	function ${functionPrefix}_option( $section = '${functionPrefix}_general', $key = '', $default = '' ) {
		$settings = get_option( $section );

		return isset( $settings[ $key ] ) ? $settings[ $key ] : $default;
	}
}

if ( ! function_exists( '${functionPrefix}_exclude_pages' ) ) {
	/**
	 * Get exclude pages setting option data
	 *
	 * @return string|array
	 *
	 * @version 1.0.0
	 */
	function ${functionPrefix}_exclude_pages() {
		return ${functionPrefix}_option( '${functionPrefix}_triggers', 'exclude_pages', array() );
	}
}

if ( ! function_exists( '${functionPrefix}_exclude_pages_except' ) ) {
	/**
	 * Get exclude pages except setting option data
	 *
	 * @return string|array
	 *
	 * @version 1.0.0
	 */
	function ${functionPrefix}_exclude_pages_except() {
		return ${functionPrefix}_option( '${functionPrefix}_triggers', 'exclude_pages_except', array() );
	}
}

${
    pluginType === "adminify_module"
        ? `/**
* Hook: Ajax Call for Install and Activate WP Adminify Plugin
*/
add_action( 'wp_ajax_${functionPrefix}_install_plugin', '${functionPrefix}_install_and_activate_plugin' );`
        : ""
}


`;
        setStore({ functionPhp: value });
    }, [
        functionPrefix,
        mainClassName,
        pluginType,
        pluginTextDomain,
        pluginName,
        pluginClassPrefix,
    ]);

    return null;
};

export default FunctionsPhp;
