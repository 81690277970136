import { useEffect } from "react";
import { useFileStore } from "../strore/useFileStore";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { getPluginFolderName } from "../utils/getPluginFolderName";

const RootLoaderPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.rootLoaderPhp);

    const { pluginInfo, advancedItem } = useGlobalPluginInfo();

    const { constantPrefix, functionPrefix, mainClassName, baseNamespace } = advancedItem;

    const rootFolderName = getPluginFolderName(pluginInfo.pluginName);

    useEffect(() => {
        let value = `<?php
namespace WPAdminify\\Modules\\${baseNamespace};

use WPAdminify\\Modules\\${baseNamespace}\\Libs\\Update_Checker;
use WPAdminify\\Modules\\${baseNamespace}\\Libs\\Assets;

class Loader {

    public function __construct() {

        /**
         * Autoload Necessary Files
         */
        include_once ${constantPrefix}_DIR . '/vendor/autoload.php';

        /**
         * Check for Plugin Update
         */
        $updater = new Update_Checker( ${constantPrefix}_FILE );
        $updater->initialize();

        new Assets();

        /**
         * Hook: Check WP Adminify Loaded
         */
        add_action( '${functionPrefix}_fs_not_loaded', '${functionPrefix}_fs_not_loaded_notice' );

        /**
         * Hook: Load This Plugin
         */
        add_action( 'jltwp_adminify_plugin_loaded', [ $this, '${functionPrefix}_plugin_loaded' ] );



        /**
         * Freemius Hooks
         */
        if ( ${functionPrefix}_fs_is_parent_active_and_loaded() ) {
            // If parent already included, init add-on.
            ${functionPrefix}_fs_init();
        } elseif ( ${functionPrefix}_fs_is_parent_active() ) {
            // Init add-on only after the parent is loaded.
            add_action( 'jltwp_adminify_loaded', '${functionPrefix}_fs_init' );
        } else {
            // Even though the parent is not activated, execute add-on for activation / uninstall hooks.
            ${functionPrefix}_fs_init();
        }


    }

    public function ${functionPrefix}_plugin_loaded() {

        if ( ! class_exists( '\\\\WPAdminify\\Modules\\${baseNamespace}\\\\${mainClassName}' ) ) {
            include_once ${constantPrefix}_DIR . '/class-${rootFolderName}.php';
        }

    }

}

new Loader();
`;

        setStore({ rootLoaderPhp: value });
    }, [baseNamespace, constantPrefix, functionPrefix, mainClassName, rootFolderName]);

    return null;
};

export default RootLoaderPhp;
