import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const PhpUnitYml = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.phpunit);

    useEffect(() => {
        let value = `name: PHPUnit

on:
  push:
    branches:
      - develop
      - trunk
  pull_request:
    branches:
      - develop

jobs:
  phpunit:
    runs-on: ubuntu-latest
    strategy:
      fail-fast: false
      matrix:
        php: ['7.4', '8.0', '8.1']

    steps:
    - name: Checkout
      uses: actions/checkout@v2

    - uses: getong/mariadb-action@v1.1

    - name: Set PHP version
      uses: shivammathur/setup-php@v2
      with:
        php-version: \${{ matrix.php }}
        coverage: none
        tools: composer:v2

    - name: Install dependencies
      run: composer update -W

    - name: Setup WP Tests
      run: bash bin/install-wp-tests.sh wordpress_test root '' 127.0.0.1

    - name: Run PHPUnit
      run: './vendor/bin/phpunit'
`;
        setStore({ phpunit: value });
    }, []);

    return null;
};

export default PhpUnitYml;
