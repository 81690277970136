import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const SDKScss = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.SDKScss);
    const { pluginInfo } = useGlobalPluginInfo();
    const { pluginClassPrefix } = pluginInfo;

    useEffect(() => {
        let value = `@import "common/variables", "admin/dashboard";`;

        setStore({ SDKScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginClassPrefix]);

    return null;
};

export default SDKScss;
