import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../strore/useFileStore";

const ManagerPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.managerPhp);
    const { pluginInfo, advancedItem, mainMenuItem, pluginType } = useGlobalPluginInfo();

    const { pluginTextDomain, pluginClassPrefix, pluginAuthor, pluginAuthorEmail } = pluginInfo;
    const { constantPrefix, baseNamespace } = advancedItem;
    const { menuTitle } = mainMenuItem;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications;

use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Base\\Data;`
        : `namespace ${baseNamespace}\\Inc\\Classes\\Notifications;
        
use ${baseNamespace}\\Inc\\Classes\\Notifications\\Base\\Data;`
}



// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

/**
 * Notice Manager
 *
 * ${pluginAuthor} <${pluginAuthorEmail}>
 */
class Manager extends Data {

	/**
	 * Constructor method
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function __construct() {

		// Register Ask_For_Rating Notice.
		$this->register( new Ask_For_Rating() );

		// Register Subscribe Notice .
		$this->register( new Subscribe() );

		// Register What we Collect Notice .
		$this->register( new What_We_Collect() );

		// Register Upgrade_Notice Notice for managing all notices .
		$this->register( new Upgrade_Notice() );
	}
}`;
        setStore({ managerPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginTextDomain,
        pluginAuthor,
        pluginAuthorEmail,
        menuTitle,
        pluginClassPrefix,
        constantPrefix,
        baseNamespace,
        pluginType,
    ]);
    return null;
};

export default ManagerPhp;
