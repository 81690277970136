import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="flex justify-between items-center p-4">
      <a href=" ">
        <span className="text-[#24263a] font-bold text-[20px] uppercase">
          React generator
        </span>
      </a>
      <nav>
        <ul className="flex gap-3 items-center">
          <li>
            <Link
              to="/home"
              className="text-[#24263a] text-[18px] font-normal hover:text-[#0e5cee] hover:underline hover:underline-offset-1"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="text-[#24263a] text-[18px] font-normal hover:text-[#0e5cee] hover:underline hover:underline-offset-1"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="rest-api"
              className="text-[#24263a] text-[18px] font-normal hover:text-[#0e5cee] hover:underline hover:underline-offset-1"
            >
              Rest Api
            </Link>
          </li>
          <li>
            <Link
              to="https://github.com/litonarefin/reactadmin-generator"
              target={"_blank"}
              className="text-[#24263a] text-[18px] font-normal hover:text-[#0e5cee] hover:underline hover:underline-offset-1"
            >
              GitHub
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
