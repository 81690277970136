import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../strore/useFileStore";

const LicenseCss = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.licenseCss);
    const { pluginInfo } = useGlobalPluginInfo();
    const { pluginClassPrefix } = pluginInfo;

    useEffect(() => {
        let value = `.${pluginClassPrefix}-license-popup,
.${pluginClassPrefix}-license-popup * {
    box-sizing: border-box;
}

.${pluginClassPrefix}-license-popup .${pluginClassPrefix}-license-popup--wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background: rgba(27, 28, 29, 0.6156862745);
    display: none;
}

.${pluginClassPrefix}-license-popup .${pluginClassPrefix}-license-popup--container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.${pluginClassPrefix}-license-popup .${pluginClassPrefix}-license-popup--container-inner {
    background: #fff;
    width: 500px;
    max-width: 100%;
    padding: 30px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0px 0px 76px rgba(41, 42, 48, 0.25);
}

.${pluginClassPrefix}-license-popup .wc-licelse--close {
    width: 44px;
    height: 44px;
    position: absolute;
    right: -26px;
    top: -26px;
    border: none !important;
    background: #fff !important;
    box-shadow: -4px 4px 40px rgba(35, 36, 40, 0.4) !important;
    padding: 0 !important;
    outline: none !important;
    cursor: pointer;
    border-radius: 50%;
}

.${pluginClassPrefix}-license-popup .wc-licelse--close svg {
    width: 24px;
    height: 24px;
}

.${pluginClassPrefix}-license-actions button:not(.wc-btn-active) {
    display: none;
}

.${pluginClassPrefix}-license-popup.${pluginClassPrefix}-license-status--active .${pluginClassPrefix}-license-status td {
    color: #16b95c;
}

.${pluginClassPrefix}-license-popup--notice {
    padding: 14px 14px;
    border-left: 3px solid;
    margin-top: 0px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.5;
    display: none;
}

.${pluginClassPrefix}-license-popup--notice.${pluginClassPrefix}-license--notice-success {
    background: #e8f1ec;
    border-color: #16b95c;
    color: #16b95c;
}

.${pluginClassPrefix}-license-popup--notice.${pluginClassPrefix}-license--notice-error {
    background: #f1e8e8;
    border-color: #e35555;
    color: #d72f2f;
}

.${pluginClassPrefix}-license--loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    border-radius: inherit;
    backdrop-filter: blur(4px);
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease;
}

.${pluginClassPrefix}-license--loader-wrapper.active {
    visibility: visible;
    opacity: 1;
}

.${pluginClassPrefix}-license--loader {
    width: 40px;
    height: 40px;
}

.${pluginClassPrefix}-license--loader:after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid #1e75e1;
    border-color: #1e75e1 transparent #1e75e1 transparent;
    animation: ${pluginClassPrefix}-license--loader 1.2s linear infinite;
}

@keyframes ${pluginClassPrefix}-license--loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.${pluginClassPrefix}-license-settings-page .${pluginClassPrefix}-license-popup--wrapper {
    position: relative;
    display: block;
    background: none;
    z-index: unset;
}

.${pluginClassPrefix}-license-settings-page .${pluginClassPrefix}-license-popup--container {
    display: block;
}

.${pluginClassPrefix}-license-settings-page .${pluginClassPrefix}-license-popup--container-inner {
    background: none;
    box-shadow: none;
    border-radius: none;
}`;

        setStore({ licenseCss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginClassPrefix]);

    return null;
};

export default LicenseCss;
