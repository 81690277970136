import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const WordpressLatestYml = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.wordpressLatest);

    useEffect(() => {
        let value = `name: "WordPress version checker"
on:
  push:
    branches:
      - develop
      - trunk
  pull_request:
    branches:
      - develop
  schedule:
    - cron: '0 0 * * 1'

jobs:
  wordpress-version-checker:
    runs-on: ubuntu-latest
    steps:
      - name: WordPress version checker
        uses: skaut/wordpress-version-checker@v1.2.0
        with:
          repo-token: \${{ secrets.GITHUB_TOKEN }}
`;
        setStore({ wordpressLatest: value });
    }, []);

    return null;
};

export default WordpressLatestYml;
