import { useEffect } from "react";
import { useFileStore } from "../strore/useFileStore";

const GitAttributes = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.gitAttributes);

    useEffect(() => {
        let value = `# Directories
/.wordpress-org
/.git
/.github
/node_modules


# Files
.gitattributes
.distignore
.gitignore
`;

    setStore({gitAttributes: value});
    }, []);

    return null;
};

export default GitAttributes;
