import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const AdminSettingsPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.adminSettingsPhp);
    const { pluginInfo, advancedItem, mainMenuItem } = useGlobalPluginInfo();

    const { pluginClassPrefix, pluginTextDomain, pluginVersion, pluginAuthor, pluginAuthorEmail } =
        pluginInfo;
    const { constantPrefix, baseNamespace, functionPrefix } = advancedItem;
    const { menuTitle, pageTitle, menuPageSlug, menuCapability, menuIcon, menuPositon } =
        mainMenuItem;

    useEffect(() => {
        let value = `<?php
namespace ${baseNamespace}\\Inc\\Admin;

// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

/*
 * @version ${pluginVersion}
 * @package ${pluginTextDomain}
 */
if ( ! class_exists( 'AdminSettings' ) ) {
	/**
	 * Admin Settings Class
	 */
	class AdminSettings {


		private static $settings_api = null;

		/**
		 * Adminsettings Construct method
		 */
		public function __construct() {
			add_action( 'admin_init', array( $this, 'settings_fields' ) );
			add_action( 'admin_menu', array( $this, 'settings_menu' ) );
		}

		/**
		 * Register Main Menu.
		 *
		 * @return void
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function settings_menu() {
			add_menu_page(
				__( '${pageTitle}', '${pluginTextDomain}' ),
				__( '${menuTitle}', '${pluginTextDomain}' ),
				'${menuCapability}',
				${menuPageSlug ? `'${menuPageSlug}'` : `${constantPrefix}_SLUG`} . '-settings',
				array( $this, 'settings_page' ),
				${menuIcon ? `'${menuIcon}'` : `esc_url( ${constantPrefix}_IMAGES . '/menu-icon.png' )`},
				${menuPositon ? menuPositon : 40}
			);

			add_submenu_page(
				${menuPageSlug ? `'${menuPageSlug}'` : `${constantPrefix}_SLUG`} . '-settings',
				__( '${menuTitle} Settings', '${pluginTextDomain}' ),
				__( 'Settings', '${pluginTextDomain}' ),
				'${menuCapability}',
				${menuPageSlug ? `'${menuPageSlug}'` : `${constantPrefix}_SLUG`} . '-settings',
				array( $this, 'settings_page' ),
				10
			);
		}

		/**
		 * Returns all the settings fields
		 *
		 * @return void
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function settings_fields() {
			$sections = array(
				array(
					'id'    => '${functionPrefix}_general',
					'title' => sprintf(
						__( '%s <span> General Settings</span>', '${pluginTextDomain}' ),
						'<i class="dashicons dashicons-admin-tools" ></i>'
					),
				),

				array(
					'id'    => '${functionPrefix}_advanced',
					'title' => sprintf(
						__( '%s <span>Advanced Settings</span>', '${pluginTextDomain}' ),
						'<i class="dashicons dashicons-admin-generic" ></i>'
					),
				),
				array(
					'id'    => '${functionPrefix}_custom_css',
					'title' => sprintf(
						__( '%s <span>Custom CSS</span>', '${pluginTextDomain}' ),
						'<i class="dashicons dashicons-editor-code" ></i>'
					),
				),
			);

			$settings_fields = array(
				'${functionPrefix}_general'    => array(
					array(
						'name'              => 'text_val',
						'label'             => esc_html__( 'Text Input', '${pluginTextDomain}' ),
						'desc'              => esc_html__( 'Text input description', '${pluginTextDomain}' ),
						'placeholder'       => esc_html__( 'Text Input placeholder', '${pluginTextDomain}' ),
						'type'              => 'text',
						'default'           => 'Title',
						'sanitize_callback' => 'sanitize_text_field',
					),
					array(
						'name'              => 'number_input',
						'label'             => esc_html__( 'Number Input', '${pluginTextDomain}' ),
						'desc'              => esc_html__( 'Number field with validation callback \`floatval\`', '${pluginTextDomain}' ),
						'placeholder'       => esc_html__( '1.99', '${pluginTextDomain}' ),
						'min'               => 0,
						'max'               => 100,
						'step'              => '0.01',
						'type'              => 'number',
						'default'           => 'Title',
						'sanitize_callback' => 'floatval',
					),
					array(
						'name'        => 'textarea',
						'label'       => esc_html__( 'Textarea Input', '${pluginTextDomain}' ),
						'desc'        => esc_html__( 'Textarea description', '${pluginTextDomain}' ),
						'placeholder' => esc_html__( 'Textarea placeholder', '${pluginTextDomain}' ),
						'type'        => 'textarea',
					),
					array(
						'name' => 'html',
						'desc' => esc_html__( 'HTML area description. You can use any <strong>bold</strong> or other HTML elements.', '${pluginTextDomain}' ),
						'type' => 'html',
					),
					array(
						'name'  => 'checkbox',
						'label' => esc_html__( 'Checkbox', '${pluginTextDomain}' ),
						'desc'  => esc_html__( 'Checkbox Label', '${pluginTextDomain}' ),
						'type'  => 'checkbox',
					),
					array(
						'name'    => 'radio',
						'label'   => esc_html__( 'Radio Button', '${pluginTextDomain}' ),
						'desc'    => esc_html__( 'A radio button', '${pluginTextDomain}' ),
						'type'    => 'radio',
						'options' => array(
							'yes' => 'Yes',
							'no'  => 'No',
						),
					),
					array(
						'name'    => 'selectbox',
						'label'   => esc_html__( 'A Dropdown', '${pluginTextDomain}' ),
						'desc'    => esc_html__( 'Dropdown description', '${pluginTextDomain}' ),
						'type'    => 'select',
						'default' => 'no',
						'options' => array(
							'yes' => 'Yes',
							'no'  => 'No',
						),
					),
					array(
						'name'    => 'password',
						'label'   => esc_html__( 'Password', '${pluginTextDomain}' ),
						'desc'    => esc_html__( 'Password description', '${pluginTextDomain}' ),
						'type'    => 'password',
						'default' => '',
					),
					array(
						'name'    => 'file',
						'label'   => esc_html__( 'File', '${pluginTextDomain}' ),
						'desc'    => esc_html__( 'File description', '${pluginTextDomain}' ),
						'type'    => 'file',
						'default' => '',
						'options' => array(
							'button_label' => 'Choose Image',
						),
					),
				),
				'${functionPrefix}_advanced'   => array(
					array(
						'name'    => 'color',
						'label'   => esc_html__( 'Color', '${pluginTextDomain}' ),
						'desc'    => esc_html__( 'Color description', '${pluginTextDomain}' ),
						'type'    => 'color',
						'default' => '',
					),
					array(
						'name'    => 'password',
						'label'   => esc_html__( 'Password', '${pluginTextDomain}' ),
						'desc'    => esc_html__( 'Password description', '${pluginTextDomain}' ),
						'type'    => 'password',
						'default' => '',
					),
					array(
						'name'    => 'wysiwyg',
						'label'   => esc_html__( 'Advanced Editor', '${pluginTextDomain}' ),
						'desc'    => esc_html__( 'WP_Editor description', '${pluginTextDomain}' ),
						'type'    => 'wysiwyg',
						'default' => '',
					),
					array(
						'name'    => 'multicheck',
						'label'   => esc_html__( 'Multile checkbox', '${pluginTextDomain}' ),
						'desc'    => esc_html__( 'Multi checkbox description', '${pluginTextDomain}' ),
						'type'    => 'multicheck',
						'default' => array(
							'one'  => 'one',
							'four' => 'four',
						),
						'options' => array(
							'one'   => 'One',
							'two'   => 'Two',
							'three' => 'Three',
							'four'  => 'Four',
						),
					),
				),
				'${functionPrefix}_custom_css' => apply_filters(
					'${functionPrefix}_admin_custom_css',
					array(
						array(
							'name'  => 'custom_css',
							'label' => esc_html__( 'Dark Mode Custom CSS', '${pluginTextDomain}' ),
							'type'  => 'textarea',
							'desc'  => 'Add custom css for dark mode only. This CSS will only apply when the dark mode is on. use <b>!important</b> flag on each property.',
						),
					)
				),
			);

			self::$settings_api = new Settings_API();

			/*
			 * set the settings.
			 */
			self::$settings_api->set_sections( $sections );
			self::$settings_api->set_fields( $settings_fields );

			/*
			 * initialize settings
			 */
			self::$settings_api->admin_init();
		}

		/**
		 * Settings page
		 *
		 * @return void
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function settings_page() {                            ?>

			<div class="wrap ${pluginClassPrefix}-settings-page">
				<h2 style="display: flex;"><?php esc_html_e( '${menuTitle} Settings', '${pluginTextDomain}' ); ?>
					<span id="changelog_badge"></span>
				</h2>
				<?php self::$settings_api->show_settings(); ?>
			</div>
			<?php
		}

		/**
		 * Get all the pages
		 *
		 * @return array page names with key value pairs
		 */
		public function get_pages() {
			$pages         = get_pages();
			$pages_options = array();

			if ( $pages ) {
				foreach ( $pages as $page ) {
					$pages_options[ $page->ID ] = $page->post_title;
				}
			}

			return $pages_options;
		}
	}
}`;
        setStore({ adminSettingsPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginAuthor,
        pluginAuthorEmail,
        menuPageSlug,
        menuIcon,
        menuPositon,
        pluginVersion,
        pluginTextDomain,
        pluginClassPrefix,
        constantPrefix,
        baseNamespace,
        functionPrefix,
        menuTitle,
        pageTitle,
        menuCapability,
    ]);

    return null;
};

export default AdminSettingsPhp;
