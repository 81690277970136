import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { getPluginFolderName } from "../utils/getPluginFolderName";
import { useFileStore } from "../strore/useFileStore";

const PixarlabsSDKPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.pixarlabsSDKphp);
    const { pluginInfo, advancedItem, crm, pluginType } = useGlobalPluginInfo();
    const rootFolderName = getPluginFolderName(pluginInfo?.pluginName);

    const { constantPrefix, functionPrefix, mainClassName, baseNamespace } = advancedItem;
    const {
        pluginName,
        pluginUri,
        pluginDescription,
        pluginVersion,
        pluginAuthor,
        pluginAuthorUrl,
        pluginTextDomain,
        pluginDomainPath,
        pluginLicense,
        pluginLicenseUrl,
    } = pluginInfo;

    useEffect(() => {
        let value = `<?php
/**
 * Plugin Name: ${pluginName || "PixarLabs SDK"}
 * Plugin URI:  ${pluginUri || "https://pixarlabs.com"}
 * Description: ${pluginDescription || "PixarLabs Software Development Kit Plugin"}
 * Version:     ${pluginVersion || "1.0.0"}
 * Author:      ${pluginAuthor || "Pixar Labs"}
 * Author URI:  ${pluginAuthorUrl || "https://pixarlabs.com"}
 * Text Domain: ${pluginTextDomain || "pixarlabs"}
 * Domain Path: ${pluginDomainPath || "languages/"}
 * License:     ${pluginLicense || "GPLv3 or later"}
 * License URI: ${pluginLicenseUrl || "https://www.gnu.org/licenses/gpl-3.0.html"}
 *
 * @package ${pluginTextDomain}
 */

if ( ! defined( 'ABSPATH' ) ) exit; // Exit if accessed directly

$${functionPrefix}_plugin_data = get_file_data(
	__FILE__,
	array(
		'Version'     => 'Version',
		'Plugin Name' => 'Plugin Name',
		'Author'      => 'Author',
		'Description' => 'Description',
		'Plugin URI'  => 'Plugin URI',
	),
	false
);

// Define Constants.
if ( ! defined( '${constantPrefix}' ) ) {
	define( '${constantPrefix}', $${functionPrefix}_plugin_data['Plugin Name'] );
}

if ( ! defined( '${constantPrefix}_VER' ) ) {
	define( '${constantPrefix}_VER', $${functionPrefix}_plugin_data['Version'] );
}

if ( ! defined( '${constantPrefix}_AUTHOR' ) ) {
	define( '${constantPrefix}_AUTHOR', $${functionPrefix}_plugin_data['Author'] );
}

if ( ! defined( '${constantPrefix}_DESC' ) ) {
	define( '${constantPrefix}_DESC', $${functionPrefix}_plugin_data['Author'] );
}

if ( ! defined( '${constantPrefix}_URI' ) ) {
	define( '${constantPrefix}_URI', $${functionPrefix}_plugin_data['Plugin URI'] );
}

if ( ! defined( '${constantPrefix}_DIR' ) ) {
	define( '${constantPrefix}_DIR', __DIR__ );
}

if ( ! defined( '${constantPrefix}_FILE' ) ) {
	define( '${constantPrefix}_FILE', __FILE__ );
}

if ( ! defined( '${constantPrefix}_SLUG' ) ) {
	define( '${constantPrefix}_SLUG', dirname( plugin_basename( __FILE__ ) ) );
}

if ( ! defined( '${constantPrefix}_BASE' ) ) {
	define( '${constantPrefix}_BASE', plugin_basename( __FILE__ ) );
}

if ( ! defined( '${constantPrefix}_PATH' ) ) {
	define( '${constantPrefix}_PATH', trailingslashit( plugin_dir_path( __FILE__ ) ) );
}

if ( ! defined( '${constantPrefix}_URL' ) ) {
	define( '${constantPrefix}_URL', trailingslashit( plugins_url( '/', __FILE__ ) ) );
}

if ( ! defined( '${constantPrefix}_INC' ) ) {
	define( '${constantPrefix}_INC', ${constantPrefix}_PATH . '/Inc/' );
}

if ( ! defined( '${constantPrefix}_LIBS' ) ) {
	define( '${constantPrefix}_LIBS', ${constantPrefix}_PATH . 'Libs' );
}

if ( ! defined( '${constantPrefix}_ASSETS' ) ) {
	define( '${constantPrefix}_ASSETS', ${constantPrefix}_URL . 'assets/' );
}

if ( ! defined( '${constantPrefix}_IMAGES' ) ) {
	define( '${constantPrefix}_IMAGES', ${constantPrefix}_ASSETS . 'images/' );
}

${
    pluginType === "adminify_module"
        ? `// Maybe Load the Plugin
include_once ${constantPrefix}_DIR . '/loader.php';`
        : `if ( ! class_exists( '\\\\${baseNamespace}\\\\${mainClassName}' ) ) {
	// Autoload Files.
	include_once ${constantPrefix}_DIR . '/vendor/autoload.php';
	// Instantiate ${mainClassName} Class.
	include_once ${constantPrefix}_DIR . '/class-${rootFolderName}.php';
}

${
    pluginType === "free_pro_bundle"
        ? `// Activation and Deactivation hooks.
if ( class_exists( '\\\\${baseNamespace}\\\\${mainClassName}' ) ) {
	register_activation_hook( ${constantPrefix}_FILE, array( '\\\\${baseNamespace}\\\\${mainClassName}', '${functionPrefix}_activation_hook' ) );
	// register_deactivation_hook( ${constantPrefix}_FILE, array( '\\\\${baseNamespace}\\\\${mainClassName}', '${functionPrefix}_deactivation_hook' ) );
}`
        : ""
}`
}
`;
        setStore({ pixarlabsSDKphp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginName,
        pluginUri,
        pluginDescription,
        pluginVersion,
        pluginAuthor,
        pluginAuthorUrl,
        pluginTextDomain,
        pluginDomainPath,
        pluginLicense,
        pluginLicenseUrl,
        constantPrefix,
        functionPrefix,
        mainClassName,
        crm,
        baseNamespace,
        pluginType,
        rootFolderName,
    ]);

    return null;
};

export default PixarlabsSDKPhp;
