import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import RestApi from "./pages/RestApi";

import { PluginInfoProvider } from "./context/pluginInfoContext";
import { ModalContextProvider } from "./context/modalContext";
import { DynamicPluginDataProvider } from "./context/dynamicPluginDataContext";
import { FileStoreContext } from "./strore/useFileStore";
import useFileDataStore from "./strore/useFileDataStore";

function App() {
    return (
        <PluginInfoProvider>
            <FileStoreContext.Provider value={useFileDataStore()}>
                <DynamicPluginDataProvider>
                    <ModalContextProvider>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route path="/" element={<Home />} />
                                <Route path="/home" element={<Home />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/rest-api" element={<RestApi />} />
                            </Route>
                        </Routes>
                    </ModalContextProvider>
                </DynamicPluginDataProvider>
            </FileStoreContext.Provider>
        </PluginInfoProvider>
    );
}

export default App;
