import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { getPluginFolderName } from "../utils/getPluginFolderName";
import { useFileStore } from "../strore/useFileStore";

const PackageJson = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.packageJson);
    const { pluginType, pluginInfo } = useGlobalPluginInfo()
    const { pluginName, pluginDescription, pluginVersion, pluginAuthor, pluginLicense } = pluginInfo;

    const rootFolderName = getPluginFolderName(pluginName);

    useEffect(() => {
        let value = `{
    "name": "${rootFolderName}",
    "version": "${pluginVersion}",
    "description": "${pluginDescription}",
    "author": "${pluginAuthor}",
    "license": "${pluginLicense}",
    "main": "index.js",
    "keywords": [
        "wordpress",
        "plugins"
    ],
    "scripts": {
        "start": "mix watch",
        "development": "mix",
        "watch": "mix watch",
        "watch-poll": "mix watch -- --watch-options-poll=1000",
        "hot": "mix watch --hot",
        "prod": "mix --production",
        "dev": "cross-env NODE_ENV=development mix watch --mode development --progress",
        "clean": "rm -rf assets/build/*",
        "svg": "svgr -d assets/js/icons assets/icons",
        "lint:php": "vendor/bin/phpcs",
        "lint:php:fix": "vendor/bin/phpcbf",
        "lint": "eslint assets/js/*",
        "lint:fix": "eslint --fix assets/src/js/*",
        "lint-css": "stylelint 'assets/sass/**/*.scss'",
        "lint-css:fix": "npm run lint-css -- --fix",
        "precommit": "npm run lint:php && npm run lint-css:fix && npm run lint:fix && npm run prod",
        "cypress:open": "node_modules/.bin/cypress open"
    },
    "dependencies": {},
    "devDependencies": {
        "wp-pot": "^1.10.2",
        "sass": "^1.38.0",
        "sass-loader": "^13.3.2",
        "unminified-webpack-plugin": "^3.0.0",
        "laravel-mix": "^6.0.49"
    }
}`;

        setStore({packageJson: value});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginType, pluginName, pluginDescription, pluginVersion, pluginAuthor, pluginLicense, rootFolderName]);

    return null;
};

export default PackageJson;
