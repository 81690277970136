import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const Sheet = () => {
    const { sheet, setSheet } = useGlobalPluginInfo();

    return (
        <div className="grid grid-cols-2 gap-4">
            <div>
                <label
                    htmlFor=""
                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                >
                    Sheet Id
                </label>
                <input
                    type="text"
                    defaultValue={sheet.sheetId}
                    placeholder="CRM Url"
                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                    onChange={(e) => {
                        setSheet({
                            ...sheet,
                            sheetId: e.target.value,
                        });
                    }}
                />
            </div>
            <div>
                <label
                    htmlFor=""
                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                >
                    Tab Id
                </label>
                <input
                    type="number"
                    defaultValue={sheet.tabId}
                    placeholder="CRM Url"
                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                    onChange={(e) => {
                        setSheet({
                            ...sheet,
                            tabId: e.target.value,
                        });
                    }}
                />
            </div>
        </div>
    );
}

export default Sheet;