import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { useFileStore } from "../strore/useFileStore";

const PhpcsXmlDist = () => {
	// eslint-disable-next-line no-unused-vars
	const [_, setStore] = useFileStore((store) => store.phpcsXmlDist);
    const { pluginInfo, advancedItem } = useGlobalPluginInfo();
    const { constantPrefix, functionPrefix, mainClassName } = advancedItem;

    useEffect(() => {
        let value = `<?xml version="1.0"?>
<ruleset xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" name="${pluginInfo?.pluginName}" xsi:noNamespaceSchemaLocation="https://raw.githubusercontent.com/squizlabs/PHP_CodeSniffer/master/phpcs.xsd">

<description>A custom set of rules to check for a ${pluginInfo?.pluginName}</description>

<!-- What to scan -->
<file>.</file>

<!-- Exclude the Composer Vendor directory. -->
<exclude-pattern>/vendor/*</exclude-pattern>

<!-- Exclude the Node Modules directory. -->
<exclude-pattern>/node_modules/*</exclude-pattern>

<!-- Exclude dev directory, these based on vue & react. -->
<exclude-pattern>/dev/*</exclude-pattern>

<!-- Exclude built directory, as it contains final processed files -->
<exclude-pattern>/built/*</exclude-pattern>

<!-- Exclude languages directory. as it contians .po .mo files -->
<exclude-pattern>/languages/*</exclude-pattern>

<!-- Exclude assets directory. -->
<exclude-pattern>/assets/*</exclude-pattern>

<!-- Exclude  Javascript files. -->
<exclude-pattern>*.min.js</exclude-pattern>

<!-- Default tab width for indentation fixes and such. -->
<arg name="tab-width" value="4"/>

<!-- Check code for cross-version PHP compatibility. -->
<config name="testVersion" value="5.6-"/>
<rule ref="PHPCompatibility">
	<!-- Allow php short array syntax by PHPCS. -->
	<exclude name="PHPCompatibility.Syntax.NewShortArray"/>
	<!-- Exclude PHP constants back-filled by PHPCS. -->
	<exclude name="PHPCompatibility.Constants.NewConstants.t_finallyFound"/>
	<exclude name="PHPCompatibility.Constants.NewConstants.t_yieldFound"/>
	<exclude name="PHPCompatibility.Constants.NewConstants.t_ellipsisFound"/>
	<exclude name="PHPCompatibility.Constants.NewConstants.t_powFound"/>
	<exclude name="PHPCompatibility.Constants.NewConstants.t_pow_equalFound"/>
	<exclude name="PHPCompatibility.Constants.NewConstants.t_spaceshipFound"/>
	<exclude name="PHPCompatibility.Constants.NewConstants.t_coalesceFound"/>
	<exclude name="PHPCompatibility.Constants.NewConstants.t_coalesce_equalFound"/>
	<exclude name="PHPCompatibility.Constants.NewConstants.t_yield_fromFound"/>
</rule>

<!-- Include the WordPress-Extra standard. -->
<rule ref="WordPress-Extra">
	<!--
	We may want a middle ground though. The best way to do this is add the
	entire ruleset, then rule by rule, remove ones that don't suit a project.
	We can do this by running 'phpcs' with the '-s' flag, which allows us to
	see the names of the sniffs reporting errors.
	Once we know the sniff names, we can opt to exclude sniffs which don't
	suit our project like so.

	The below two examples just show how you can exclude rules.
	They are not intended as advice about which sniffs to exclude.
	-->

	<!--
	<exclude name="WordPress.WhiteSpace.ControlStructureSpacing"/>
	<exclude name="WordPress.Security.EscapeOutput"/>
	-->
</rule>

<!-- Let's also check that everything is properly documented. -->
<rule ref="WordPress-Docs"/>

<!-- Add in some extra rules from other standards. -->
<rule ref="Generic.CodeAnalysis.UnusedFunctionParameter"/>
<rule ref="Generic.Commenting.Todo"/>
<!-- Long array convert ot Short array -->
<rule ref="Generic.Arrays.DisallowLongArraySyntax"/>

<!-- Check for PHP cross-version compatibility. -->
<!--
To enable this, the PHPCompatibilityWP standard needs
to be installed.
See the readme for installation instructions:
https://github.com/PHPCompatibility/PHPCompatibilityWP
For more information, also see:
https://github.com/PHPCompatibility/PHPCompatibility
-->
<!--
<config name="testVersion" value="5.2-"/>
<rule ref="PHPCompatibilityWP"/>
-->

<!--
To get the optimal benefits of using WPCS, we should add a couple of
custom properties.
Adjust the values of these properties to fit our needs.

For information on additional custom properties available, check out
the wiki:
https://github.com/WordPress/WordPress-Coding-Standards/wiki/Customizable-sniff-properties
-->
<config name="minimum_supported_wp_version" value="4.5"/>

<rule ref="WordPress.WP.I18n">
	<properties>
		<property name="text_domain" type="array">
			<element value="${pluginInfo?.pluginTextDomain}"/>
			<!-- <element value="library-textdomain"/> -->
		</property>
	</properties>
</rule>

<rule ref="WordPress.NamingConventions.PrefixAllGlobals">
	<properties>
		<property name="prefixes" type="array">
			<element value="${constantPrefix}_"/>
			<element value="${mainClassName}"/>
			<element value="${functionPrefix}_"/>
		</property>
	</properties>
</rule>

<rule ref="WordPress.WhiteSpace.ControlStructureSpacing">
	<properties>
		<property name="blank_line_check" value="true"/>
	</properties>
</rule>

<rule ref="WordPress">
	<exclude name="WordPress.Files.FileName"/>
	<exclude name="Generic.Arrays.DisallowShortArraySyntax"/>
	<exclude name="WordPress.NamingConventions.ValidVariableName"/>
	<exclude name="WordPress.CodeAnalysis.AssignmentInCondition.FoundInWhileCondition"/>
	<exclude name="Squiz.Commenting.FileComment.Missing"/>
	<exclude name="Squiz.Commenting.VariableComment.Missing"/>
	<exclude name="Generic.Commenting.DocComment.ShortNotCapital"/>
</rule>

<rule ref="WordPress.Arrays.MultipleStatementAlignment">
	<properties>
		<property name="alignMultilineItems" value="!=100"/>
		<property name="exact" value="false" phpcs-only="true"/>
	</properties>
</rule>

</ruleset>`;

    setStore({phpcsXmlDist: value});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginInfo, constantPrefix, mainClassName, functionPrefix]);

    return null;
};

export default PhpcsXmlDist;
