import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../strore/useFileStore";

const DashboardScss = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.dashboardScss);
    const { pluginInfo } = useGlobalPluginInfo();
    const { pluginClassPrefix } = pluginInfo;

    useEffect(() => {
        let value = `@import "../common/variables";

/* Recommended */
/* Review */
/* Popup */
/* Subscribe form */
/*---- badge ---*/
/* Disable Field */
/* Start of Overlay Upgrade Popup  */
/* responsive  */
/* End of Overlay Upgrade Popup  */
.${pluginClassPrefix} {
    .mt0 {
        margin-top: 0;
    }

    #TB_window {
        top: 43% !important;
        width: 772px !important;
        height: 690px !important;

        iframe {
            width: 772px !important;
            height: 690px !important;
        }
    }

    #plugin-information-scrollable {
        height: calc(100% - 65px) !important;
    }

    .${pluginClassPrefix}-recommended-wrapper {
        .mr-0 {
            margin: 0 !important;
        }

        .plugin-card {
            .name {
                margin-left: 148px;
                margin-right: inherit !important;
            }

            .desc {
                margin-left: 148px;
                margin-right: inherit !important;
            }
        }

        .button {
            &:not(.update-now).updating-message {
                &:before {
                    margin: 6px 10px 0px -3px !important;
                }
            }

            &:not(.update-now).updated-message {
                &:before {
                    margin: 6px 10px 0px -3px !important;
                }
            }

            &:not(.update-now).installed {
                &:before {
                    margin: 6px 10px 0px -3px !important;
                }
            }

            &:not(.update-now).installing {
                &:before {
                    margin: 6px 10px 0px -3px !important;
                }
            }
        }

        .plugin-status-active {
            color: $admin_color;
        }

        .plugin-status-inactive {
            color: $admin_plg_status_color;
        }

        .plugin-status-not-install {
            color: $admin_plg_status_not_install_color;
        }

        .plugin-status {
            line-height: 34px;
        }

        .hide {
            display: none;
        }
    }

    #wpbody-content {
        .notice-${pluginClassPrefix} {
            padding: 15px;
            position: relative;
        }

        .notice-plugin-review {
            padding: 15px;
            position: relative;

            .button {
                &:not(.${pluginClassPrefix}-notice-dismiss) {
                    margin-right: 5px;
                }
            }

            .${pluginClassPrefix}-notice-dismiss {
                box-shadow: none;
            }
        }

        .${pluginClassPrefix}-notice-plugin-popup {
            max-width: 850px;
            min-height: 300px;
            left: 50%;
            top: 50%;
            width: 100%;
            height: auto;
            border-radius: 5px !important;
            transform: translate(-50%, -50%);
            border: 0;
            position: fixed;
            z-index: 99999;
            -webkit-box-shadow: 0px 0px 54px 0px rgb(20 20 42 / 30%) !important;
            box-shadow: 0px 0px 54px 0px rgb(20 20 42 / 30%) !important;

            .${pluginClassPrefix}-notice-dismiss {
                box-shadow: none;
                float: right;
                cursor: pointer;
            }
        }

        .${pluginClassPrefix}-notice-subscribe {
            padding: 15px;
            display: flex;
            align-items: center;
        }
    }

    .upgrade-pro-popup {
        gap: 15px;
        background: #fff;
        padding: 40px !important;

        .upst-body {
            display: flex;

            .content-body {
                color: $admin_content_body;

                h4 {
                    display: flex;
                    color: $admin_heading_color;
                    margin-bottom: 10px;
                    letter-spacing: 1px;
                    gap: 10px;
                    font-size: 22px;
                    line-height: 32px;
                }

                h3 {
                    color: $admin_heading_h3_color;
                    letter-spacing: 1px;
                    font-size: 28px;

                    span {
                        color: $admin_heading_h3_span;
                    }
                }

                p {
                    font-weight: normal !important;
                    line-height: 22px !important;
                    font-size: 16px !important;
                }

                ul {
                    li {
                        margin: 10px 0;
                        line-height: 22px;
                        padding-left: 30px;

                        &:before {
                            color: $admin_badge_color;
                            top: 2px;
                            left: 1px;
                            font-size: 17px;
                        }

                        &:after {
                            position: absolute;
                            content: "";
                            background: #00ba88;
                            border-radius: 50px;
                            width: 18px;
                            height: 18px;
                            top: 3px !important;
                            left: 1px;
                        }

                        span {
                            color: $admin_heading_h3_color !important;
                        }
                    }
                }

                .upgrade-btn {
                    background: #0347ff;
                    color: $admin_badge_color;
                    font-size: 18px;
                    border: 0 !important;
                    border-radius: 7px;
                    padding: 5px 25px;
                }
            }
        }

        .col {
            &:nth-child(1) {
                padding-right: 20px;
                padding-top: 120px;

                img {
                    max-width: 100%;
                }
            }

            flex-basis: 50%;

            &:nth-child(2) {
                padding-left: 20px;
            }
        }

        ul {
            li {
                &:before {
                    position: absolute;
                    content: "\\f15e";
                    font-family: $admin_fntfamly_one;
                    font-size: 19px;
                    left: 0;
                    top: 0;
                    z-index: 1;
                }

                position: relative;
                list-style: none;
                padding-left: 25px;
                position: relative;
                list-style: none;
                padding-left: 25px;
            }
        }

        .upst-footer {
            ul {
                display: flex;
                padding-top: 60px;
                width: 70%;
                margin: 0 auto;
                justify-content: space-between;

                li {
                    a {
                        color: $admin_content_body !important;

                        &:hover {
                            color: $admin_footer_hover !important;
                        }
                    }

                    &:before {
                        font-size: 22px;
                        color: $admin_color;
                    }
                }
            }
        }
    }

    .${pluginClassPrefix}-notice-plugin-popup {
        .${pluginClassPrefix}-notice-dismiss {
            border: 0 !important;
            outline: none !important;
            background: transparent !important;

            &:before {
                content: "\\00d7" !important;
                font-size: 30px !important;
                height: 22px !important;
                width: 22px !important;
                color: $admin_content_body !important;
            }
        }
    }

    body.${pluginClassPrefix}-popup-overlay {
        position: relative;
        height: auto;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            z-index: 9999;
        }
    }

    .${pluginClassPrefix}-notice-subscribe {
        img {
            width: 50px;
            max-width: 50px;
            height: 50px;
            margin-right: 15px;
        }

        h4 {
            margin-top: 0;
        }
    }

    .${pluginClassPrefix}-notice-subscribe-input {
        margin-right: 15px;
    }

    .${pluginClassPrefix}-badge {
        &:after {
            color: $admin_badge_color;
            position: absolute;
            font-size: 11px;
            padding: 1px 7px;
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 3px;
            display: none;
        }
    }

    .${pluginClassPrefix}-badge-pro {
        &:after {
            content: "Pro";
            background: darkorange;
        }
    }

    .${pluginClassPrefix}-badge-ultimate {
        &:after {
            content: "Ultimate";
            background: blueviolet;
            right: -60px;
        }
    }

    .image-choose-opt.disabled {
        .disabled-text {
            position: absolute;
            width: 90px;
            height: 90px;
            left: 5px;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(85, 85, 85, 0.7);
            color: $admin_badge_color;
        }
    }

    .form-table {
        tr.disabled {
            position: relative;

            > * {
                opacity: 0.6;

                input {
                    pointer-events: none;
                }

                select {
                    pointer-events: none;
                }

                textarea {
                    pointer-events: none;
                }

                button {
                    pointer-events: none;
                }
            }

            &:hover {
                .${pluginClassPrefix}-badge {
                    &:after {
                        display: inline;
                    }
                }
            }
        }
    }

    &:root {
        --${pluginClassPrefix}-popup-color: #ff631a;
    }

    .${pluginClassPrefix}-popup {
        * {
            all: initial;
            font-family: $admin_fntfamly_two;
        }

        position: fixed;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99999999 !important;
    }

    .${pluginClassPrefix}-popup-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
    }

    .${pluginClassPrefix}-popup-modal {
        width: 600px;
        max-width: 600px !important;
        height: 600px;
        max-height: 600px !important;
        color: $admin_popup_color;
        background: #222;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        transform: scale(0.9);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border-radius: 3px;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
    }

    .${pluginClassPrefix}-popup-modal-close {
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 50px;
        cursor: pointer;
        color: $admin_popup_close;
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .${pluginClassPrefix}-popup-modal-footer {
        width: 100%;
        height: 225px;
        max-height: 225px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 0;
    }

    .${pluginClassPrefix}-popup-countdown {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 10px;
    }

    .${pluginClassPrefix}-popup-countdown-text {
        font-size: 14px;
        font-weight: 600;
        color: $admin_popup_color;
        position: relative;
        line-height: 1.2;
    }

    .${pluginClassPrefix}-popup-countdown-time {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 10px;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            gap: 5px;

            > span {
                font-size: 20px;
                font-weight: 600;
                color: $admin_popup_color;

                &:nth-child(1) {
                    border: 2px solid rgba(255, 255, 255, 0.6);
                    height: 40px;
                    width: 45px;
                    font-size: 22px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                }

                &:nth-child(2) {
                    font-size: 12px;
                    font-weight: 500;
                    color: $admin_countdown_color;
                }
            }
        }

        > span {
            font-size: 50px;
            color: $admin_popup_color;
            margin-top: -25px;
        }
    }

    .${pluginClassPrefix}-popup-button {
        height: 50px;
        background: var(--${pluginClassPrefix}-popup-color);
        color: $admin_popup_button;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
        color: $admin_popup_color;
        padding: 0 30px;
        margin: 20px 0;
        transition: 0.2s;
        position: relative;
        text-decoration: none;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background: rgba(255, 255, 255, 0.2);
            transition: 0.3s;
        }

        &:hover {
            color: $admin_popup_color;

            &:after {
                width: 100%;
            }
        }
    }

    .${pluginClassPrefix}-wwc {
        > p {
            margin-top: 0;
            padding-top: 0;
            margin-bottom: 20px;
        }
    }

    .${pluginClassPrefix}-wwc-content {
        margin-bottom: 25px;
        color: $admin_wwc_color;
    }

    @media (max-width: 576px) {
        .${pluginClassPrefix}-popup-countdown {
            transform: scale(0.99);
        }
    }
}
`;

        setStore({ dashboardScss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginClassPrefix]);

    return null;
};

export default DashboardScss;
