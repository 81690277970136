import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const PluginNameFrontendJs = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.pluginNameFrontendJs);

    useEffect(() => {
        let value = `/* This is frontend js */`;

        setStore({ pluginNameFrontendJs: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default PluginNameFrontendJs;
