import { createContext, useContext, useState } from "react";

export const ModalContext = createContext();

export const ModalContextProvider = ({ children }) => {
    const [fileInfo, setFileInfo] = useState(null);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [fileName, setFileName] = useState("");

    return (
        <ModalContext.Provider
            value={{
                fileInfo,
                setFileInfo,
                isOpenModal,
                setIsOpenModal,
                fileName,
                setFileName,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useGlobalModal = () => {
    return useContext(ModalContext);
};
