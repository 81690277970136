import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../context/pluginInfoContext";
import { isValidUrl } from "../../../../utils/isValidURL";
import { useFileStore } from "../../../../strore/useFileStore";

const WhatWeCollectPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.whatWeCollectPhp);
    const { pluginInfo, advancedItem, crm, pluginType } = useGlobalPluginInfo();
    const {
        pluginName,
        pluginUri,
        pluginClassPrefix,
        pluginTextDomain,
        pluginAuthor,
        pluginAuthorEmail,
    } = pluginInfo;
    const { constantPrefix, baseNamespace, functionPrefix } = advancedItem;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications;

use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Base\\User_Data;
use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Model\\Notice;`
        : `namespace ${baseNamespace}\\Inc\\Classes\\Notifications;

use ${baseNamespace}\\Inc\\Classes\\Notifications\\Base\\User_Data;
use ${baseNamespace}\\Inc\\Classes\\Notifications\\Model\\Notice;`
}

if ( ! class_exists( 'What_We_Collect' ) ) {
	/**
	 * Class for what we collect
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	class What_We_Collect extends Notice {


		use User_Data;

		public $color = 'warning';

		/**
		 * Constructor method
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function __construct() {
			parent::__construct();
			add_action( 'wp_ajax_${functionPrefix}_allow_collect', array( $this, '${functionPrefix}_allow_collect' ) );
		}

		/**
		 * Allow collect data
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function ${functionPrefix}_allow_collect() {
			check_ajax_referer( '${functionPrefix}_allow_collect_nonce' );

			$email = get_bloginfo( 'admin_email' );

			$author_obj = get_user_by( 'email', $email );
			$user_id    = $author_obj->ID;
			$full_name  = $author_obj->display_name;

			$response = $this->get_collect_data( $user_id, array(
				'first_name'              => $full_name,
				'email'                   => $email,
			) );

			if ( ! is_wp_error( $response ) && 200 === $response['response']['code'] && 'OK' === $response['response']['message'] ) {
				wp_send_json_success( 'Thanks for Allow!' );
			} else {
				wp_send_json_error( "Couldn't Collect" );
			}
		}

		/**
		 * Title Content
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function set_title() {
			printf(
				'<h4>Wanna get some discount for %1$s? No Worries!! We got you!! give us your email we will send you the discount code?</h4>',
				esc_html__( '${pluginName}', '${pluginTextDomain}' )
			);
		}

		/**
		 * Link for collect data
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function what_we_collect_link() {
			echo '<a href="!#" class="${pluginClassPrefix}-wwc-link">' . esc_html__( 'what we collect', '${pluginTextDomain}' ) . '</a>';
		}

		/**
		 * Notice Content
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function notice_content() { ?>
			<div class="${pluginClassPrefix}-notice-review-box ${pluginClassPrefix}-wwc">
				<p>
				<?php
				echo sprintf(
					__( 'Want to help make <strong>%1$s</strong> even more awesome? Allow %1$s to collect non-sensitive diagnostic data and usage information.', '${pluginTextDomain}' ),
					__( '${pluginName}', '${pluginTextDomain}' )
				);
				?>
				 (<?php $this->what_we_collect_link(); ?>)</p>
				<div class="${pluginClassPrefix}-wwc-content" style="display:none">
				<?php echo sprintf(
					__( 'Server environment details (php, mysql, server, WordPress versions), Number of users in your site, Site language, Number of active and inactive plugins, Local or Production Site, IP Address, Site name and url, Your name and email address etc. No sensitive data is tracked. Learn more about our <a href="%1$s" target="_blank">Privacy Policy</a>, how we handle and collects your data.', '${pluginTextDomain}' ),
					esc_url( '${
                        isValidUrl(pluginUri) ? new URL(pluginUri).origin : "https://jeweltheme.com"
                    }/privacy-policy' )
				); ?>
				</div>
			</div>

			<?php
		}

		/**
		 * Plugin rate url
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function plugin_rate_url() {
			return '#';
		}

		/**
		 * Footer Content
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function footer_content() {
			?>
			<a class="button button-primary allow-button" href="<?php echo esc_url( $this->plugin_rate_url() ); ?>" rel="nofollow" target="_blank">
				<?php echo esc_html__( 'Allow', '${pluginTextDomain}' ); ?>
			</a>
			<a class="button button-secondary button-reject ${pluginClassPrefix}-notice-dismiss" href="#" rel="nofollow">
				<?php echo esc_html__( 'No Thanks', '${pluginTextDomain}' ); ?>
			</a>
			<?php
		}

		/**
		 * Intervals
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function intervals() {
			return array( 7, 10, 15, 20, 15, 25, 30 );
		}

		/**
		 * Core Script
		 *
		 * @param [type] $trigger_time .
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function core_script( $trigger_time ) {
			parent::core_script( $trigger_time );
			?>

			<script>
				jQuery('body').on('click', '.${pluginClassPrefix}-notice-what_we_collect .${pluginClassPrefix}-wwc-link', function(e) {
					e.preventDefault();
					if (jQuery(this).hasClass('show')) {
						jQuery(this).removeClass("show");
						jQuery('.${pluginClassPrefix}-wwc-content').stop().slideUp(200);
					} else {
						jQuery(this).addClass("show");
						jQuery('.${pluginClassPrefix}-wwc-content').stop().slideDown(200);
					}
				});

				jQuery('body').on('click', '.${pluginClassPrefix}-notice-what_we_collect .allow-button', function(e) {

					e.preventDefault();

					let noticeWrapper = jQuery(this).closest('.notice-${pluginClassPrefix}');

					noticeWrapper.css('opacity', '0.4').find('button').prop('disabled', true);

					jQuery.ajax({
							url: '<?php echo esc_url( admin_url( 'admin-ajax.php' ) ); ?>',
							method: 'POST',
							crossDomain: true,
							data: {
								action: '${functionPrefix}_allow_collect',
								_wpnonce: '<?php echo esc_js( wp_create_nonce( '${functionPrefix}_allow_collect_nonce' ) ); ?>',
							}
						})
						.done(function(response) {
							noticeWrapper.children(':not(.notice-dismiss)').hide().end().append('<p class="${pluginClassPrefix}--notice-message"><strong>' + response.data + '</strong></p>');
							let subsTimeout = setTimeout(function() {
								${functionPrefix}_notice_action(null, noticeWrapper.children(), 'disable');
								clearTimeout(subsTimeout);
							}, 1500);
						})
						.always(function() {
							noticeWrapper.css('opacity', '1').find('button').prop('disabled', false);
						})

				});
			</script>

			<?php
		}
	}
}`;
        setStore({ whatWeCollectPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginTextDomain,
        pluginUri,
        baseNamespace,
        pluginAuthor,
        pluginAuthorEmail,
        functionPrefix,
        crm,
        pluginName,
        pluginClassPrefix,
        constantPrefix,
        pluginType,
    ]);
    return null;
};

export default WhatWeCollectPhp;
