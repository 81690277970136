import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { useFileStore } from "../strore/useFileStore";

const PhpCsFixerDistPhp = () => {
	// eslint-disable-next-line no-unused-vars
	const [_, setStore] = useFileStore((store) => store.phpCsFixerDist);
    const { advancedItem } = useGlobalPluginInfo();
    const { mainClassName } = advancedItem;

    useEffect(() => {
        let value = `<?php
require_once __DIR__ . '/vendor/litonarefin/wp-php-cs-fixer/loader.php';

$finder = PhpCsFixer\\Finder::create()
	->exclude( 'node_modules' )
	->exclude( 'vendors' )
	->exclude( 'assets' )
	->in( __DIR__ );

$config = new PhpCsFixer\\Config();
$config
	->registerCustomFixers(
		array(
			new ${mainClassName}\\Fixer\\SpaceInsideParenthesisFixer(),
			new ${mainClassName}\\Fixer\\BlankLineAfterClassOpeningFixer(),
		)
	)
	->setRiskyAllowed( true )
	->setUsingCache( false )
	->setRules( ${mainClassName}\\Fixer\\Fixer::rules() )
	->setFinder( $finder );

return $config;
`;

    setStore({phpCsFixerDist: value});
    }, [mainClassName]);

    return null;
};

export default PhpCsFixerDistPhp;
