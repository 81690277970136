export const reducer = (state, action) => {
    switch (action.type) {
        case "FIELD_ADD":
            return [...state, action?.fields ? action?.fields : {}];
        case "TEXT":
            let newItems = [...state];
           newItems[action.index][action.handle] = action.payload;
           return newItems;
        case "FIELD_REMOVE":
            let removeItems = [...state];
            removeItems.splice(action.index, 1);
            return removeItems;
        default:
            break;
    }
    return state;
}