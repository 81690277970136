import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const PluginNameAdminJs = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.pluginNameAdminJs);
    const { pluginInfo, advancedItem, pluginType } = useGlobalPluginInfo();
    const { pluginClassPrefix } = pluginInfo;
    const { constantPrefix, functionPrefix } = advancedItem;

    useEffect(() => {
        let value = `(function($) {

    // Notice Hide
    $('body').on('click', '.${pluginClassPrefix}-upgrade-popup .popup-dismiss', function(evt) {
        evt.preventDefault();
        $(this).closest('.${pluginClassPrefix}-upgrade-popup').fadeOut(200);
    });

    // Notice Show
    $('body').on('click', '.disabled', function(evt) {
        evt.preventDefault();
        $('.${pluginClassPrefix}-upgrade-popup').fadeIn(200);
    });

    ${
        pluginType === "adminify_module"
            ? `// Install WP Adminify Plugin
    $("body").on("click", ".install-adminify-${pluginClassPrefix}-now .install-now", function (e) {
        e.preventDefault();
        if (!$(this).hasClass("updating-message")) {
            let plugin = $(this).attr("data-plugin");
            installAdminifyPlugin($(this), plugin);
        }
    });
    
    function installAdminifyPlugin(element, plugin) {
        element.addClass("updating-message");
        element.text("Installing...");
        jQuery.ajax({
            url: ${constantPrefix}CORE.admin_ajax,
            type: "POST",
            data: {
                action: "${functionPrefix}_install_plugin",
                type: "install",
                plugin: plugin,
                nonce: ${constantPrefix}CORE.recommended_nonce,
            },
            success: function (response) {
                console.log("response", response);

                setTimeout(() => {
                    element.removeClass("updating-message");
                    element.text("Activated");
                }, 1000);
            },
        });
    }
    `
            : `// Recommended Plugins

    // Install
    $('body').on('click', '.plugin-action-buttons .install-now', function(e) {
        e.preventDefault();
        if (!$(this).hasClass("updating-message")) {
            let plugin = $(this).attr("data-install-url");
            installPlugin($(this), plugin);
        }
    });

    // Active
    $('body').on('click', '.plugin-action-buttons .activate-now', function() {
        let file = $(this).attr("data-plugin-file");
        activatePlugin($(this), file);
    });

    // Update
    $('body').on('click', '.plugin-action-buttons .update-now', function() {
        if (!$(this).hasClass("updating-message")) {
            const plugin = $(this).attr("data-plugin");
            updatePlugin($(this), plugin);
        }
    });`
    }

    // Tab
    $('.filter-links').on('click', 'a', function(e) {
        e.preventDefault();
        let cls = $(this).data('type');
        $(this).addClass('current').parent().siblings().find('a').removeClass('current');
        $('#the-list .plugin-card').each(function(i, el) {
            if (cls == 'all') {
                $(this).removeClass('hide');
            } else {
                if ($(this).hasClass(cls)) {
                    $(this).removeClass('hide');
                } else {
                    $(this).addClass('hide');
                }
            }
        });
    });

    // Search
    $('.${pluginClassPrefix}-search-plugins #search-plugins').on('keyup', function() {
        var value = $(this).val();
        var srch = new RegExp(value, "i");
        $('#the-list .plugin-card').each(function() {
            var $this = $(this);
            if (!($this.find('.name h3 a, .desc p').text().search(srch) >= 0)) {
                $this.addClass('hide');
            }
            if (($this.find('.name h3 a, .desc p').text().search(srch) >= 0)) {
                $this.removeClass('hide');
            }
        });
    });
})(jQuery);

${
    pluginType === "adminify_module"
        ? ""
        : `function activatePlugin(element, file) {
    element.addClass("button-disabled");
    element.attr("disabled", "disabled");
    element.text("Processing...");
    jQuery.ajax({
        url: ${constantPrefix}CORE.admin_ajax,
        type: "POST",
        data: {
            action: "${functionPrefix}_recommended_activate_plugin",
            file: file,
            nonce: ${constantPrefix}CORE.recommended_nonce
        },
        success: function(response) {
            if (response.success === true) {
                const pluginStatus = jQuery(".plugin-status .plugin-status-inactive[data-plugin-file='" + file + "']")
                pluginStatus.text("Active");
                pluginStatus.addClass("plugin-status-active");
                pluginStatus.removeClass("plugin-status-inactive");
                element.removeClass("active-now");
                element.text("Activated");
            } else {
                element.removeClass("button-disabled");
                element.prop("disabled", false);
                element.text("Activated");
            }
        },
    })
}

function installPlugin(element, plugin) {
    element.removeClass("button-primary");
    element.addClass("updating-message");
    element.text("Installing...");
    jQuery.ajax({
        url: ${constantPrefix}CORE.admin_ajax,
        type: "POST",
        data: {
            action: "${functionPrefix}_recommended_upgrade_plugin",
            type: 'install',
            plugin: plugin,
            nonce: ${constantPrefix}CORE.recommended_nonce
        },
        success: function(response) {
            if (response.success === true) {
                element.removeClass("updating-message");
                element.addClass("updated-message installed button-disabled");
                element.attr("disabled", "disabled");
                element.removeAttr("data-install-url");
                element.text("Installed!");
                setTimeout(() => {
                    const pluginStatus = jQuery(".plugin-status .plugin-status-not-install[data-plugin-url='" + plugin + "']")
                    pluginStatus.text("Active");
                    pluginStatus.addClass("plugin-status-active");
                    pluginStatus.removeClass("plugin-status-not-install");
                    pluginStatus.removeAttr("data-install-url");
                    element.removeClass("install-now updated-message installed");
                    element.text("Activated");
                    element.removeAttr("aria-label");
                }, 500);

            } else {
                element.removeClass("updating-message");
                element.addClass("button-primary");
                element.text("Install Now");
            }
        },
    })
}

function updatePlugin(element, plugin) {
    element.addClass("updating-message");
    element.text("Updating...");
    jQuery.ajax({
        url: ${constantPrefix}CORE.admin_ajax,
        type: "POST",
        data: {
            action: "${functionPrefix}_recommended_upgrade_plugin",
            type: "update",
            plugin: plugin,
            nonce: ${constantPrefix}CORE.recommended_nonce
        },
        success: function(response) {
            if (response.success === true) {
                element.removeClass("updating-message");
                element.addClass("updated-message button-disabled");
                element.attr("disabled", "disabled");
                element.text("Updated!");
                if (response.data.active === false) {
                    const pluginStatus = jQuery(".plugin-status .plugin-status-inactive[data-plugin-file='" + plugin + "']");
                    pluginStatus.text("Active");
                    pluginStatus.addClass("plugin-status-active");
                    pluginStatus.removeClass("plugin-status-inactive");
                    pluginStatus.removeAttr("data-plugin-file");
                }
            } else {
                element.removeClass("updating-message");
                element.text("Update Now");
            }
        },
    })
}`
}
`;

        setStore({ pluginNameAdminJs: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginClassPrefix, functionPrefix, constantPrefix, pluginType]);
    return null;
};

export default PluginNameAdminJs;
