import FileStructureModel from "../components/FileStructureModel/FileStructureModel";
import FolderStructureModel from "../components/FolderStructureModel/FolderStructureModel";
import { Fragment } from "react";

export const FileRenderer = ({ files }) => {
    return files?.map((file) => {
        switch (file?.type) {
            case "folder": {
                return (
                    <FolderStructureModel key={Math.random()} item={file}>
                        {file?.folders.length > 0 ? (
                            <FileRenderer files={file?.folders} />
                        ) : null}

                        {file?.files.length > 0 ? (
                            <FileRenderer files={file?.files} />
                        ) : null}
                    </FolderStructureModel>
                );
            }
            case "file": {
                return (
                    <Fragment key={Math.random()}>
                        <FileStructureModel item={file} />
                    </Fragment>
                );
            }
            default:
                return null;
        }
    });
};
