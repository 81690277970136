import React, { Fragment, useEffect, useReducer } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { reducer } from "../reducer";

const initialState = [];

const Faqs = () => {
    const { readmeData, setReadmeData } = useGlobalPluginInfo();
    const [faqValues, dispatch] = useReducer(reducer, readmeData?.faq || initialState);

    useEffect(() => {
        setReadmeData({...readmeData, faq: [...faqValues]})
    }, [faqValues])

    return (
        <Fragment>
            {faqValues?.map((item, index) => (
                <div key={index} className="flex items-center justify-center">
                        <div className="grid grid-cols-2 gap-4 mb-5 w-full">
                        <div>
                            <label
                                htmlFor="Handle"
                                className="block text-[#24263a] text-xs leading-[15px] font-bold"
                            >
                                Question
                            </label>
                            <input
                                type="text"
                                value={item.question}
                                className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "question", index })}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="Handle"
                                className="block text-[#24263a] text-xs leading-[15px] font-bold"
                            >
                                Answer
                            </label>
                            <textarea
                                type="text"
                                value={item.answer}
                                className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "answer", index })}
                            />
                        </div>
                    </div>
                    <div className="w-20 h-20 flex items-center justify-center">
                        <button onClick={() => dispatch({type: "FIELD_REMOVE", index})} className="w-12 h-12 bg-red-200 text-red-600 rounded-full">
                            <i className="fa-solid fa-trash" />
                        </button>
                    </div>
                </div>
                
            ))}
            <div className="mt-[15px] text-right">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() => dispatch({type: "FIELD_ADD", fields: {question: "", answer: ""} })}
                >
                    ADD
                </button>
            </div>
        </Fragment>
    );
};

export default Faqs;
