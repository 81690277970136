import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const PhpCompatibilityYml = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.phpCompatibility);

    useEffect(() => {
        let value = `name: PHP Compatibility

on:
  push:
    branches:
      - develop
      - trunk
  pull_request:
    branches:
      - develop

jobs:
  php_compatibility:
    name: PHP minimum 7.4
    runs-on: ubuntu-latest

    steps:
      - name: Checkout
        uses: actions/checkout@v2

      - name: Set PHP version
        uses: shivammathur/setup-php@v2
        with:
          php-version: 7.4
          tools: composer:v2
          coverage: none

      - name: Install dependencies
        run: composer install

      - name: Run PHP Compatibility
        run: vendor/bin/phpcs safe-redirect-manager.php inc/ -p --standard=PHPCompatibilityWP --extensions=php --runtime-set testVersion 7.4-
`;
        setStore({ phpCompatibility: value });
    }, []);

    return null;
};

export default PhpCompatibilityYml;
