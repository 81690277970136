import React from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const MainMenu = () => {
    const { mainMenuItem, setmainMenuItem } = useGlobalPluginInfo();

    return (
        <div>
            <div className="flex items-center gap-2 mb-4">
                <input
                    type="checkbox"
                    checked={mainMenuItem?.isAdminSettings}
                    onChange={(e) => setmainMenuItem({
                        ...mainMenuItem,
                        isAdminSettings: !mainMenuItem?.isAdminSettings
                    })}
                />
                <label
                    htmlFor=""
                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                >
                    Include Admin Settings?
                </label>
            </div>
            <div className="grid grid-cols-2 items-center gap-[30px]">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Menu Title
                    </label>
                    <input
                        type="text"
                        defaultValue={mainMenuItem.menuTitle}
                        placeholder="Address Book"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setmainMenuItem({
                                ...mainMenuItem,
                                menuTitle: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Page Title
                    </label>
                    <input
                        type="text"
                        defaultValue={mainMenuItem.pageTitle}
                        placeholder="Contact List"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) =>
                            setmainMenuItem({
                                ...mainMenuItem,
                                pageTitle: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 items-center gap-[30px] mt-4">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Capability
                    </label>
                    <input
                        type="text"
                        defaultValue={mainMenuItem.menuCapability}
                        placeholder="manage_options"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                        onChange={(e) =>
                            setmainMenuItem({
                                ...mainMenuItem,
                                menuCapability: e.target.value.toLowerCase(),
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Menu Slug
                    </label>
                    <input
                        type="text"
                        defaultValue={mainMenuItem.menuPageSlug}
                        placeholder="address-book"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                        onChange={(e) =>
                            setmainMenuItem({
                                ...mainMenuItem,
                                menuPageSlug: e.target.value.toLowerCase(),
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Menu Icon
                    </label>
                    <input
                        type="text"
                        defaultValue={mainMenuItem.menuIcon}
                        placeholder="address-book"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                        onChange={(e) =>
                            setmainMenuItem({
                                ...mainMenuItem,
                                menuIcon: e.target.value.toLowerCase(),
                            })
                        }
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                      Menu Positon
                    </label>
                    <input
                        type="number"
                        defaultValue={mainMenuItem.menuPositon}
                        placeholder="address-book"
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                        onChange={(e) =>
                            setmainMenuItem({
                                ...mainMenuItem,
                                menuPositon: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default MainMenu;
