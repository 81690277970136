import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../strore/useFileStore";

const IncSubscribePhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.incSubscribePhp);
    const { advancedItem, pluginInfo, crm, pluginType } = useGlobalPluginInfo();
    const { constantPrefix, baseNamespace, functionPrefix } = advancedItem;
    const { pluginTextDomain, pluginName, pluginClassPrefix, pluginAuthor, pluginAuthorEmail } =
        pluginInfo;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications;

use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Base\\User_Data;
use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Model\\Notice;`
        : `namespace ${baseNamespace}\\Inc\\Classes\\Notifications;

use ${baseNamespace}\\Inc\\Classes\\Notifications\\Base\\User_Data;
use ${baseNamespace}\\Inc\\Classes\\Notifications\\Model\\Notice;`
}


if ( ! class_exists( 'Subscribe' ) ) {
	/**
	 * Subscribe Class
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	class Subscribe extends Notice {

		use User_Data;

		public $color = 'warning';

		/**
		 * Construct method
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function __construct() {
			parent::__construct();
			add_action( 'wp_ajax_${functionPrefix}_subscribe', array( $this, '${functionPrefix}_subscribe' ) );
		}

		/**
		 * Subscribe method
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function ${functionPrefix}_subscribe() {
			check_ajax_referer( '${functionPrefix}_subscribe_nonce' );

			$name  = ! empty( $_POST['name'] ) ? sanitize_text_field( wp_unslash( $_POST['name'] ) ) : '';
			$email = ! empty( $_POST['email'] ) ? sanitize_email( wp_unslash( $_POST['email'] ) ) : '';

			if ( ! is_email( $email ) ) {
				$email = get_bloginfo( 'admin_email' );
			}

			$author_obj = get_user_by( 'email', get_bloginfo( 'admin_email' ) );
			$user_id    = $author_obj->ID;

			// First Name & Last name .
			if ( ! empty( $name ) ) {
				$full_name = $name;
			} else {
				$full_name = $author_obj->display_name;
			}

			$response = $this->get_collect_data( $user_id, array(
				'first_name'              => $full_name,
				'email'                   => $email,
			) );

			if ( ! is_wp_error( $response ) && 200 === $response['response']['code'] && 'OK' === $response['response']['message'] ) {
				wp_send_json_success( 'Thanks for Subscribe!' );
			} else {
				wp_send_json_error( "Couldn't Subscribe" );
			}
		}

		/**
		 * Notice Header
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function notice_header() {
			return '';
		}

		/**
		 * Notice footer
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function notice_footer() {
			return '';
		}

		/**
		 * Set Title
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function set_title() {
			printf(
				'<h4>Wanna get some discount for %1$s? No Worries!! We got you!! Enter your email, we will send you the discount code?</h4>',
				esc_html__( '${pluginName}', '${pluginTextDomain}' )
			);
		}

		/**
		 * Notice Content
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function notice_content() {
			$userdata = \\wp_get_current_user();
			?>
			<div class="notice notice-${pluginClassPrefix} is-dismissible notice-plugin-review notice-<?php echo esc_attr( $this->color ); ?> ${pluginClassPrefix}-notice-<?php echo esc_attr( $this->get_id() ); ?>">
				<button type="button" class="notice-dismiss ${pluginClassPrefix}-notice-dismiss"></button>
				<img width="70" height="70" src="<?php echo esc_url( ${constantPrefix}_IMAGES . '/logo.png' ); ?>" alt="<?php esc_attr_e( 'Logo', '${pluginTextDomain}' ); ?>">
				<div class="${pluginClassPrefix}-subscribe-content">
					<?php $this->set_title(); ?>
					<form style="display:flex">
						<div class="${pluginClassPrefix}-plugin-subscribe-input">
							<input type="text" id="name" name="name" placeholder="Name" value="<?php echo esc_attr( $userdata->display_name ); ?>">
						</div>
						<div class="${pluginClassPrefix}-plugin-subscribe-input">
							<input type="text" id="email" name="email" placeholder="Email" value="<?php echo esc_attr( $userdata->user_email ); ?>">
						</div>
						<button type="submit" class="button button-primary ${pluginClassPrefix}-subscribe-btn"><?php esc_html_e( 'Get Discount', '${pluginTextDomain}' ); ?></button>
					</form>
				</div>
			</div>
			<?php
		}

		/**
		 * Rate URL
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function plugin_rate_url() {
			return 'https://wordpress.org/plugins/' . ${constantPrefix}_SLUG;
		}

		/**
		 * Footer content
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function footer_content() {
			?>
			<a class="button button-primary rate-plugin-button" href="<?php echo esc_url( $this->plugin_rate_url() ); ?>" rel="nofollow" target="_blank">
				<?php echo esc_html__( 'Rate Now', '${pluginTextDomain}' ); ?>
			</a>
			<a class="button notice-review-btn review-later ${pluginClassPrefix}-notice-dismiss" href="#" rel="nofollow">
				<?php echo esc_html__( 'Later', '${pluginTextDomain}' ); ?>
			</a>
			<a class="button notice-review-btn review-done ${pluginClassPrefix}-notice-disable" href="#" rel="nofollow">
				<?php echo esc_html__( 'I already did', '${pluginTextDomain}' ); ?>
			</a>
			<?php
		}

		/**
		 * Intervals
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function intervals() {
			return array( 5, 4, 10, 20, 15, 25, 30 );
		}

		/**
		 * Core Script
		 *
		 * @param [type] $trigger_time .
		 *
		 * @return void
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function core_script( $trigger_time ) {
			parent::core_script( $trigger_time );
			?>

			<script>
				jQuery(document).on('submit', '.${pluginClassPrefix}-notice-subscribe .${pluginClassPrefix}-subscribe-content form', function(e) {

					e.preventDefault();

					let form = jQuery(this);
					let name = form.find('input[name=name]').val();
					let email = form.find('input[name=email]').val();
					let formWrapper = form.closest('.${pluginClassPrefix}-subscribe-content');

					formWrapper.css('opacity', '0.4').find('button').prop('disabled', true);

					jQuery.ajax({
							url: '<?php echo esc_url( admin_url( 'admin-ajax.php' ) ); ?>',
							method: 'POST',
							crossDomain: true,
							data: {
								action: '${functionPrefix}_subscribe',
								_wpnonce: '<?php echo esc_js( wp_create_nonce( '${functionPrefix}_subscribe_nonce' ) ); ?>',
								name: name,
								email: email,
							}
						})
						.done(function(response) {
							formWrapper.hide().after('<p class="${pluginClassPrefix}--notice-message"><strong>' + response.data + '</strong><p>');
							let subsTimeout = setTimeout(function() {
								${functionPrefix}_notice_action(null, form, 'disable');
								clearTimeout(subsTimeout);
							}, 1500);
						})
						.always(function() {
							formWrapper.css('opacity', '1').find('button').prop('disabled', false);
						})

				});
			</script>

			<?php
		}
	}
}`;
        setStore({ incSubscribePhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        constantPrefix,
        baseNamespace,
        crm,
        functionPrefix,
        pluginClassPrefix,
        pluginAuthor,
        pluginAuthorEmail,
        pluginName,
        pluginTextDomain,
        pluginType,
    ]);
    return null;
};

export default IncSubscribePhp;
