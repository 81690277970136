import { useEffect } from "react";
import { useFileStore } from "../strore/useFileStore";

const EsLintIgnore = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.esLintIgnore);

    useEffect(() => {
        let value = `**/node_modules/**
**/build/**
`;

        setStore({esLintIgnore: value});
    }, []);

    return null;
};

export default EsLintIgnore;