import { Fragment, useState } from "react";
import Tabs from "../../Tabs/Tabs";
import Heading from "./Heading";
import Description from "./Description";
import Others from "./Others";
import Screenshots from "./Screenshots";
import Faqs from "./Faqs";


const Readme = () => {
      const [firstFilesTabs, setFirstFilesTabs] = useState(1)

      const pluginFileName = [
            {
                id: 1,
                name: "Heading",
            },
            {
                id: 2,
                name: "Description",
            },
            {
                 id: 3,
                 name: "FAQ",
            },
            {
                id: 4,
                name: "Screenshots"
            },
            {
                id: 5,
                name: "Others"
            }
      ];

    return (
        <Fragment>
            <div className="border-[1px] border-[#D8D8D8] rounded-[2px]">
                <Tabs
                    tabs={firstFilesTabs}
                    setTabs={setFirstFilesTabs}
                    tabName={pluginFileName}
                    tabsItems={
                        <div className="px-5 pb-5 mt-10">
                            {firstFilesTabs === 1 && (
                                <Heading />
                            )}
                            {firstFilesTabs === 2 && (
                                <Description />
                            )}
                            {firstFilesTabs === 3 && (
                                <Faqs />
                            )}
                            {firstFilesTabs === 4 && (
                                <Screenshots />
                            )}
                            {firstFilesTabs === 5 && (
                                <Others />
                            )}
                        </div>
                    }
                />
            </div>
        </Fragment>
       
    )
}

export default Readme;