import  { useEffect } from 'react';
import { useGlobalPluginInfo } from '../../context/pluginInfoContext';
import { useFileStore } from '../../strore/useFileStore';

const SettingsJson = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.settingsJson);
    const { pluginInfo } = useGlobalPluginInfo();
    const { pluginAuthor, pluginAuthorEmail } = pluginInfo;

    useEffect(() => {
        let value = `{
    "editor.fontSize": 12,
    "php-docblocker.author": {
        "name": "${pluginAuthor}",
        "email": "${pluginAuthorEmail}"
    },
    "php-docblocker.classTemplate": {
        "message": {
            "gapAfter": true
        },
        "param": {
            "gapBefore": true
        },
        "author": {
            "content": "@author \${###:${pluginAuthor}} <\${###:${pluginAuthorEmail}}>"
        }
    },
    "php-docblocker.extra": [
        "@author ${pluginAuthor} <${pluginAuthorEmail}>"
    ],
    "[php]": {
        "editor.defaultFormatter": "persoderlind.vscode-phpcbf",
    },
}`
    setStore({settingsJson: value})
    },[pluginAuthor, pluginAuthorEmail])

    return  null
};

export default SettingsJson;