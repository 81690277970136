import React, { Fragment, useEffect, useReducer } from "react";
import { reducer } from "../reducer"
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const initialState = [];

const RowLinks = () => {
    const { rowLinks, setRowLinks } = useGlobalPluginInfo();
    const [rowLinkValues, dispatch] = useReducer(reducer, rowLinks || initialState);

    useEffect(() => {
        setRowLinks(rowLinkValues);
    }, [rowLinkValues, setRowLinks])

    return (
        <Fragment>
            {rowLinkValues?.map((data, index) => {
                return (
                    <div key={index} className="flex items-center justify-center">
                         <div className="grid grid-cols-3 gap-8 mb-5 w-full">
                            <div>
                                <label
                                    htmlFor="Handle"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    Label
                                </label>
                                <input
                                    type="text"
                                    value={data?.label || ""}
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "label", index })}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="fileUrl"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                Link
                                </label>
                                <input
                                    type="text"
                                    value={data.url || ""}
                                    className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "url", index })}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="selectOption"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                Select Option
                                </label>
                                <select onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "type", index})} name="" id="selectOption" className="w-full p-2 mt-2 border border-[#ececee] bg-[#f6f6f9]">
                                    <option value="">None</option>
                                    <option value="is_active">Is Active</option>
                                    <option value="is_free">Is Free</option>
                                </select>
                            </div>
                        </div>
                         <div className="w-20 h-20 flex items-center justify-center">
                            <button onClick={() => dispatch({type: "FIELD_REMOVE", index})} className="w-12 h-12 bg-red-200 text-red-600 rounded-full">
                                <i className="fa-solid fa-trash" />
                            </button>
                        </div>
                    </div>
                );
            })}
            <div className="mt-[15px]">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() => dispatch({type: "FIELD_ADD" })}
                >
                    ADD
                </button>
            </div>
        </Fragment>
    );
};

export default RowLinks;
