import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const UpgradeVersion = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.upgradeVersion);
    const { pluginInfo, advancedItem } = useGlobalPluginInfo();
    const { pluginVersion } = pluginInfo;
    const { constantPrefix } = advancedItem;

    useEffect(() => {
        let value = `<?php

// pretty_log('upgrader ${pluginVersion}', 'is running.............');
// pretty_log('${constantPrefix}_BASE', ${constantPrefix}_BASE);
// pretty_log('${constantPrefix}_DIR', ${constantPrefix}_DIR);
`;
        setStore({ upgradeVersion: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginVersion, constantPrefix]);
    return null;
};

export default UpgradeVersion;
