import React, { Fragment, useEffect, useReducer } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { reducer } from "../reducer";

const initialState = [];

const Screenshots = () => {
    const { readmeData, setReadmeData } = useGlobalPluginInfo();
    const [screenshotsValues, dispatch] = useReducer(reducer, readmeData?.screenshots || initialState);

    useEffect(() => {
        setReadmeData({...readmeData, screenshots: [...screenshotsValues]})
    }, [screenshotsValues])

    return (
        <Fragment>
                {screenshotsValues?.map((item, index) => (
                    <div key={index} className="flex items-center justify-center">
                        <div className="w-full mb-[10px]">
                            <label
                                htmlFor="Handle"
                                className="block text-[#24263a] text-xs leading-[15px] font-bold"
                            >
                                Caption
                            </label>
                            <input
                                type="text"
                                value={item.caption}
                                className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "caption", index })}
                            />
                        </div>
                        <div className="w-20 h-20 flex items-center justify-center">
                            <button onClick={() => dispatch({type: "FIELD_REMOVE", index})} className="w-12 h-12 bg-red-200 text-red-600 rounded-full">
                                <i className="fa-solid fa-trash" />
                            </button>
                        </div>
                    </div>
                   
                ))}
                <div className="mt-[15px] text-right">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() => dispatch({type: "FIELD_ADD", fields: {caption: ""} })}
                >
                    ADD
                </button>
            </div>
        </Fragment>
    );
};

export default Screenshots;
