import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const PluginSurveyCss = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.pluginSurveyCss);
    const { pluginInfo } = useGlobalPluginInfo();
    const { pluginClassPrefix } = pluginInfo;

    useEffect(() => {
        let value = `.${pluginClassPrefix}-deactivate-survey-modal {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -40px;
    width: 100%;
    max-width: 635px;
    border-radius: 5px;
    background-color: #fff;
    color: rgb(14, 1, 1);
    display: block;
    z-index: 9999999999999;
}

.${pluginClassPrefix}-deactivate-input-wrapper {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.${pluginClassPrefix}-deactivate-input-wrapper .other {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.${pluginClassPrefix}-deactivate-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 24px;
    padding-right: 40px;
}

.${pluginClassPrefix}-dialog-lightbox-skip {
    border: 0;
    cursor: pointer;
    padding: 0px 15px;
}

.${pluginClassPrefix}-dialog-lightbox-submit {
    background-color: #3D5DFF;
    border-radius: 3px;
    color: #fff;
    line-height: 1;
    padding: 12px 20px;
    font-size: 13px;
    width: 180px;
    height: 38px;
    border: 0;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.${pluginClassPrefix}-dialog-lightbox-submit:hover {
    opacity: 0.9;
    background: #263580;
}

.${pluginClassPrefix}-deactivate-input-wrapper .${pluginClassPrefix}-feedback-text {
    width: 80%;
    padding: 7px 14px;
}

form.${pluginClassPrefix}-deactivate-form-wrapper {
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.${pluginClassPrefix}-deactivate-survey-modal header {
    box-shadow: 0 0 8px rgb(0 0 0 / 10%);
    padding: 3px 6px 3px 11px;
}

.${pluginClassPrefix}-deactivate-survey-modal .${pluginClassPrefix}-deactivate-info {
    font-weight: 500;
    font-size: 14px;
    padding-top: 32px;
    text-align: left;
    padding-left: 16px;
    padding-bottom: 23px;
    line-height: 1.5;
}

.${pluginClassPrefix}-deactivate-content-wrapper {
    padding: 3px 6px 3px 24px;
}

.${pluginClassPrefix}-deactivate-survey-header {
    display: flex;
    gap: 10px;
    align-items: center;
}
.${pluginClassPrefix}-deactivate-survey-header img{
    width: 25px;
}

/** Overlay */
.${pluginClassPrefix}-deactivate-survey-overlay {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s;
    position: fixed;
    top: 0;
    backdrop-filter: blur(1px);
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .8);
}

/** JS */
.${pluginClassPrefix}-deactivate-survey-is-visible {
    opacity: 1;
    pointer-events: auto;
}

.${pluginClassPrefix}-deactivate-input-wrapper .${pluginClassPrefix}-deactivate-feedback-dialog-input {
    float: right;
    margin-block: 0;
    margin-inline: 0 15px;
    box-shadow: none;
}

.${pluginClassPrefix}-deactivate-input-wrapper .${pluginClassPrefix}-deactivate-feedback-dialog-input:not(:checked)~.${pluginClassPrefix}-deactivate-feedback-text {
    display: none;
}

.${pluginClassPrefix}-deactivate-input-wrapper .${pluginClassPrefix}-deactivate-feedback-text {
    background-color: transparent;
    color: var(--e-a-color-txt);
    margin-block: 10px 0;
    margin-inline: 30px 0;
    padding: 5px;
    box-shadow: none;
    width: 45%;
}

.${pluginClassPrefix}-deactivate-input-wrapper .${pluginClassPrefix}-deactivate-feedback-dialog-label {
    display: block;
    font-size: 13px;
}

@keyframes ${pluginClassPrefix}-rotation {
    0% {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

#${pluginClassPrefix}-dialog-lightbox-submit.${pluginClassPrefix}-loading:before {
    position: absolute;
    display: inline-block;
    content: "\\f463";
    font: 16px dashicons;
    margin: -2px -20px;
    animation: ${pluginClassPrefix}-rotation 2s linear infinite;
}`;

        setStore({ pluginSurveyCss: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginClassPrefix]);

    return null;
};

export default PluginSurveyCss;
