import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../../../context/pluginInfoContext";
import { useFileStore } from "../../../../../strore/useFileStore";

const NoticePhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.noticePhp);
    const { advancedItem, pluginInfo, pluginType } = useGlobalPluginInfo();

    const { functionPrefix, baseNamespace } = advancedItem;
    const { pluginClassPrefix, pluginAuthor, pluginAuthorEmail } = pluginInfo;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Model;

use WPAdminify\\Modules\\${baseNamespace}\\Libs\\Helper;`
        : `namespace ${baseNamespace}\\Inc\\Classes\\Notifications\\Model;
		
use ${baseNamespace}\\Libs\\Helper;`
}



// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

/**
 * Notice Model for Notification
 *
 * ${pluginAuthor} <${pluginAuthorEmail}>
 */
abstract class Notice extends Notification {


	public $type  = 'notice';
	public $color = 'error';

	/**
	 * Get Key
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	final public function get_key() {
		return '${functionPrefix}_notice_' . $this->get_id();
	}

	/**
	 * Footer content
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function footer_content() {
		return '';
	}

	/**
	 * Notice Header
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function notice_header() { ?>
		<div class="notice notice-${pluginClassPrefix} is-dismissible notice-<?php echo esc_attr( $this->color ); ?> ${pluginClassPrefix}-notice-<?php echo esc_attr( $this->get_id() ); ?>">
			<button type="button" class="notice-dismiss ${pluginClassPrefix}-notice-dismiss"></button>
			<div class="notice-content-box">
		<?php
	}

	/**
	 * Notice Content
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function notice_content() {
	}

	/**
	 * Notice Footer
	 *
	 * @return void
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function notice_footer() {
		?>
			</div>
			<?php echo  $this->footer_content(); ?>
		</div>
		<?php
	}

	/**
	 * Core Script
	 *
	 * @param [type] $trigger_time .
	 *
	 * @return void
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function core_script( $trigger_time ) {
		?>
		<script>
			function ${functionPrefix}_notice_action(evt, $this, action_type) {

				if (evt) evt.preventDefault();

				$this.closest('.notice-${pluginClassPrefix}').slideUp(200);

				jQuery.post('<?php echo esc_url( admin_url( 'admin-ajax.php' ) ); ?>', {
					action: '${functionPrefix}_notification_action',
					_wpnonce: '<?php echo esc_js( wp_create_nonce( '${functionPrefix}_notification_nonce' ) ); ?>',
					action_type: action_type,
					notification_type: 'notice',
					trigger_time: '<?php echo esc_attr( $trigger_time ); ?>'
				});

			}

			// Notice Dismiss
			jQuery('body').on('click', '.notice-${pluginClassPrefix} .${pluginClassPrefix}-notice-dismiss', function(evt) {
				${functionPrefix}_notice_action(evt, jQuery(this), 'dismiss');
			});

			// Notice Disable
			jQuery('body').on('click', '.notice-${pluginClassPrefix} .${pluginClassPrefix}-notice-disable', function(evt) {
				${functionPrefix}_notice_action(evt, jQuery(this), 'disable');
			});
		</script>
		<?php
	}
}`;
        setStore({ noticePhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        functionPrefix,
        baseNamespace,
        pluginClassPrefix,
        pluginAuthor,
        pluginAuthorEmail,
        pluginType,
    ]);

    return null;
};

export default NoticePhp;
