import { useGlobalModal } from "../../context/modalContext";

const FileView = ({
    fileName = "demo.txt",
    icon = "fa-brands fa-css3",
    data = "",
}) => {
    const { setIsOpenModal, setFileInfo, setFileName } = useGlobalModal();

    return (
        <div
            // className="ml-8"
            onClick={() => {
                setIsOpenModal(true);
                setFileInfo(data);
                setFileName(fileName);
            }}
        >
            <i className={`text-[12px] ${icon}`} />
            <span className="text-[13px] select-none ml-[6px]">{fileName}</span>
        </div>
    );
};

export default FileView;
