import React, { Fragment, useEffect, useReducer } from "react";
import { reducer } from "../reducer"
import Select from 'react-select';
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const initialState = [];

const RecommendedPlugin = () => {
    const { rcdPlugin, setRcdPlugin, mainMenuItem } = useGlobalPluginInfo();
    const [recommendedPlugins, dispatch] = useReducer(reducer, rcdPlugin.allPlugin || initialState);
    const { menuPageSlug } = mainMenuItem;

    useEffect(() => {
        setRcdPlugin({...rcdPlugin, allPlugin: [...recommendedPlugins]});
    }, [recommendedPlugins])

    useEffect(() => {
        if(!!menuPageSlug){
            setRcdPlugin({...rcdPlugin, submenuSlug: menuPageSlug})
        }
    }, [menuPageSlug])

    useEffect(() => {
        if(!rcdPlugin?.submenuSlug && rcdPlugin?.pluginPosition === "submenu"){
            setRcdPlugin({...rcdPlugin, submenuSlug: "options-general.php"});
        }
    }, [rcdPlugin?.pluginPosition])

    return (
        <Fragment>
            {recommendedPlugins?.map((data, index) => {
                return (
                    <Fragment key={index}>
                        <div className="flex items-center justify-center">
                            <div className="grid grid-cols-3 gap-8 mb-5 w-full">
                                <div>
                                    <label
                                        htmlFor="slug"
                                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                    >
                                        Plugin Slug
                                    </label>
                                    <input
                                        id="slug"
                                        type="text"
                                        value={data.slug}
                                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                        onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "slug", index })}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="name"
                                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                    >
                                    Plugin Name
                                    </label>
                                    <input
                                        id="name"
                                        type="text"
                                        value={data.name}
                                        className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                        onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "name", index })}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="iconURL"
                                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                    >
                                    Icon URL
                                    </label>
                                    <input
                                        id="iconURL"
                                        type="text"
                                        value={data.icon_url}
                                        className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                        onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "icon_url", index })}
                                    />
                                </div>
                                <div className="col-span-2">
                                    <label
                                        htmlFor="shortDescription"
                                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                    >
                                    Short Description
                                    </label>
                                    <textarea
                                        id="shortDescription"
                                        type="text"
                                        rows="3"
                                        value={data.short_description}
                                        className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                        onChange={(e) => dispatch({type: "TEXT", payload: e.target.value, handle: "short_description", index })}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="selectOption"
                                        className="mb-3 block text-[#24263a] text-xs leading-[15px] font-bold"
                                    >
                                    Type
                                    </label>
                                    <Select
                                        value={data?.type}
                                        isMulti
                                        onChange={(e) => {
                                            dispatch({type: "TEXT", payload: e, handle: "type", index})
                                        }}
                                        options={[
                                            {label: "All", value: "all"},
                                            {label: "Featured", value: "featured"},
                                            {label: "Popular", value: "popular"},
                                            {label: "Favorites", value: "favorites"},
                                        ]}
                                    />
                                </div>
                            </div>

                            <div className="w-20 h-20 flex items-center justify-center">
                                <button onClick={() => dispatch({type: "FIELD_REMOVE", index})} className="w-12 h-12 bg-red-200 text-red-600 rounded-full">
                                    <i className="fa-solid fa-trash" />
                                </button>
                            </div>
                        </div>
                        <hr className="my-4" />
                    </Fragment>
                );
            })}

            <div className="mt-[15px] flex items-center justify-end">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() => dispatch({type: "FIELD_ADD" })}
                >
                    ADD
                </button>
            </div>

            <div className="mt-3 grid grid-cols-3 items-center gap-3 bg-yellow-100 p-3 rounded-lg">
                <div>
                    <label
                        htmlFor="pluginTitle"
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Page Title
                    </label>
                    <input
                        id="pluginTitle"
                        type="text"
                        value={rcdPlugin.pageTitle}
                        className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => setRcdPlugin({...rcdPlugin, pageTitle: e.target.value})}
                    />
                </div>
                <div>
                    <label
                        htmlFor="pluginTitle"
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                       Menu Title
                    </label>
                    <input
                        id="pluginTitle"
                        type="text"
                        value={rcdPlugin.menuTitle}
                        className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => setRcdPlugin({...rcdPlugin, menuTitle: e.target.value})}
                    />
                </div>
                <div>
                    <label
                        htmlFor="pluginPosition"
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Menu Position
                    </label>
                    <select 
                        onChange={(e) => setRcdPlugin({...rcdPlugin, pluginPosition: e.target.value})} 
                        name="" id="pluginPosition" 
                        className="w-full p-2 mt-2 border border-[#ececee] bg-[#f6f6f9]"
                    >
                        <option value="submenu">Sub Menu</option>
                        <option value="parentmenu">Parent Menu</option>
                    </select>
                </div>

                <div>
                    <label
                        htmlFor="submenuSlug"
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Parent Menu Slug
                    </label>
                    <input
                        id="submenuSlug"
                        type="text"
                        value={rcdPlugin?.submenuSlug}
                        className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => setRcdPlugin({...rcdPlugin, submenuSlug: e.target.value})}
                    />
                </div> 
       
                {
                    rcdPlugin?.pluginPosition === "parentmenu" ?
                        <Fragment>
                            <div>
                                <label
                                    htmlFor="menuOrder"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    Menu Order
                                </label>
                                <input
                                    id="menuOrder"
                                    type="number"
                                    value={rcdPlugin?.menuOrder}
                                    className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onChange={(e) => setRcdPlugin({...rcdPlugin, menuOrder: e.target.value})}
                                />
                            </div> 
                            <div>
                                <label
                                    htmlFor="menuIcon"
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    Menu Icon
                                </label>
                                <input
                                    id="menuIcon"
                                    type="text"
                                    value={rcdPlugin?.menuIcon}
                                    className="w-full py-[0.5rem] px-[0.75rem] border border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onChange={(e) => setRcdPlugin({...rcdPlugin, menuIcon: e.target.value})}
                                />
                            </div> 
                        </Fragment>
                    : null
                }
            </div>
        </Fragment>
    );
};

export default RecommendedPlugin;
