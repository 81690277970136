import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import kebabCase from "lodash/kebabCase";
import { useFileStore } from "../strore/useFileStore";

const ReadmeTxt = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.readmeTxt);
    const { readmeData, pluginInfo } = useGlobalPluginInfo();
    const { pluginLicense, pluginLicenseUrl, pluginName: mainPluginName,  } = pluginInfo;

    const { 
        pluginName,
        contributors,
        donateLink,
        tags,
        stableTag,
        requiresPHP,
        requiresAtLeast,
        testedUpTo,
        // license,
        // licenseURI,
        shortDesc,
        desc,
        installInstruction,
        faq,
        screenshots,
        upgradeNotice
    } = readmeData;

    useEffect(() => {
        let value = `=== ${pluginName} ===
Contributors: ${contributors}
Donate link: ${donateLink}
Tags: ${tags}
Requires at least: ${requiresAtLeast}
Tested up to: ${testedUpTo}
Stable tag: ${stableTag}
Requires PHP: ${requiresPHP}
License: ${pluginLicense}
License URI: ${pluginLicenseUrl}

${shortDesc}

== Description ==

${desc}

== Installation ==

${installInstruction}

### Liked ${mainPluginName} Plugins?
Rate us on [WordPress Forum](https://wordpress.org/support/plugin/${kebabCase(mainPluginName)}/reviews/#new-post) 🙂


== Source Code ==
[Source Code](https://github.com/litonarefin/${kebabCase(mainPluginName)})

== Support ==
[Support Forum](https://jeweltheme.com/contact/)
[Pre-Sale Questions](https://jeweltheme.com/contact/)

== ✋Want More - ==

If you like our ${mainPluginName} plugin then why not check some other plugins that developed by us:

🚀 [WP Adminify:](https://wordpress.org/plugins/adminify/) - The best WordPress Dashboard Customization plugin available in the market. With this plugin you will get 18+ modules like Dark Mode, Menu Editor, Folders, Dashboard Widget Manager, Login Customizer, Admin Columns, Activity Logs and many more.

🚀 [Master Blocks:](https://wordpress.org/plugins/ultimate-blocks-for-gutenberg/) - "Master Blocks" is a powerful WordPress plugin designed to enhance the functionality of the Gutenberg block editor. With this plugin, users can unlock a wide range of new blocks, offering greater flexibility and creativity when designing and customising their website's content.

🚀 [Master Addons:](https://wordpress.org/plugins/master-addons/) - One of the best Elementor Addon plugin that packed with 70+ elements and extensions. You will get all essential elements to design any type Website.

🚴🏻‍♂️ [Image Comparison for Elementor:](https://wordpress.org/plugins/image-comparison-elementor-addon/) - This plugin dedicatedly made for image comparison element. You will get a multiple variation for image comparison. Showcase your before after work easily with the help of this plugin.

🍡 [Master Accordion:](https://wordpress.org/plugins/wp-awesome-faq/) - If you are a default WordPress user and need a proper accordion plugin then Master Accordion is for you. You can add as much accordion as you need in multiple way.

🏕 [WordPress prettyPhoto:](https://wordpress.org/plugins/prettyphoto/) - This plugin helps you to enable lightbox for your any media file, flash, YouTube, iframes and ajax. Just enable PreetyPhoto in your desired media file and you are done.


== Frequently Asked Questions ==

${faq.length > 0 ? faq?.map(item=> `= ${item?.question} =

${item?.answer}

`).join("") : ""}
== Screenshots ==

${screenshots.length > 0 ? screenshots?.map((item, i) => `${++i}. ${item?.caption}
`).join("") : ""}
== Changelog ==


== Upgrade Notice ==

${upgradeNotice}`;

    setStore({readmeTxt: value});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mainPluginName,
        pluginName,
        contributors,
        donateLink,
        tags,
        stableTag,
        requiresPHP,
        requiresAtLeast,
        testedUpTo,
        // license,
        // licenseURI,
        pluginLicense,
        pluginLicenseUrl, 
        shortDesc,
        desc,
        installInstruction,
        faq,
        screenshots,
        upgradeNotice
    ]);

    return null;
};

export default ReadmeTxt;
