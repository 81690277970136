import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const IncRowLinksPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.incRowLinkPhp);
    const { pluginInfo, advancedItem, rowLinks, pluginType } = useGlobalPluginInfo();
    const { pluginName, pluginTextDomain, pluginAuthor, pluginAuthorEmail } = pluginInfo;
    const { constantPrefix, baseNamespace, functionPrefix } = advancedItem;

    useEffect(() => {
        let value = `<?php
${
    pluginType === "adminify_module"
        ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes;

use WPAdminify\\Modules\\${baseNamespace}\\Libs\\RowLinks;`
        : `namespace ${baseNamespace}\\Inc\\Classes;

use ${baseNamespace}\\Libs\\RowLinks;`
}

if ( ! class_exists( 'Row_Links' ) ) {
	/**
	 * Row Links Class
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	class Row_Links extends RowLinks {

		public $is_active;
		public $is_free;

		/**
		 * Construct method
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function __construct() {
			parent::__construct();

			$this->is_active = false;
			$this->is_free   = true;
		}


		/**
		 * Plugin action links
		 *
		 * @param [type] $links .
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function plugin_action_links( $links ) {
            ${rowLinks
                ?.map((item) =>
                    !!item?.type
                        ? item.url?.includes("http")
                            ? `if ( $this->${item?.type} ) {
				$links[] = sprintf(
					'<a href="%1$s" target="_blank"${
                        item.type === "is_active"
                            ? ` style="color: orangered;font-weight: bold;"`
                            : ""
                    }>%2$s</a>',
					'${item.url}',
					__( '${item.label}', '${pluginTextDomain}' )
				);
			}\n` + " ".repeat(12)
                            : `if ( $this->${item?.type} ) {
				$links[] = sprintf(
					'<a href="%1$s" target="_blank"${
                        item.type === "is_active"
                            ? ` style="color: orangered;font-weight: bold;"`
                            : ""
                    }>%2$s</a>',
					${item.url},
					__( '${item.label}', '${pluginTextDomain}' )
				);
			}\n` + " ".repeat(12)
                        : item.url?.includes("http")
                        ? `$links[] = sprintf(
				'<a href="%1$s">%2$s</a>',
				'${item?.url}',
				__( '${item.label}', '${pluginTextDomain}' )
			);\n` + " ".repeat(12)
                        : `$links[] = sprintf(
				'<a href="%1$s">%2$s</a>',
				${item?.url},
				__( '${item.label}', '${pluginTextDomain}' )
			);\n` + " ".repeat(12)
                )
                .join("")}
			return $links;
		}
	}
}`;
        setStore({ incRowLinkPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginName,
        rowLinks,
        pluginAuthor,
        pluginAuthorEmail,
        pluginTextDomain,
        functionPrefix,
        baseNamespace,
        constantPrefix,
        pluginType,
    ]);

    return null;
};

export default IncRowLinksPhp;
