import React from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const AddTable = () => {
    const { addTableItem, setAddTableItem } = useGlobalPluginInfo();

    const updateFile = (value, index, handle, type = "string") => {
        if (type === "string" && !!value) {
            let newItems = [...addTableItem];
            newItems[index][handle] = value;

            return setAddTableItem(newItems);
        }
    };
    return (
        <>
            {addTableItem.map((data, index) => {
                return (
                    <div
                        className="grid grid-cols-6 items-center gap-8 mb-5"
                        key={index}
                    >
                        <div className="col-span-4">
                            <label
                                htmlFor="TableName"
                                className="block text-[#24263a] text-xs leading-[15px] font-bold"
                            >
                                Table Name
                            </label>
                            <input
                                type="text"
                                defaultValue={data.handleName}
                                className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e] lowercase"
                                onBlur={(e) =>
                                    updateFile(
                                        e.target.value,
                                        index,
                                        "handleName"
                                    )
                                }
                            />
                        </div>
                        <div className="flex items-center mt-[25px]">
                            <button
                                className="bg-[#dc3545] px-[12px] py-[6px] rounded-[2px]"
                                onClick={() => {
                                    let delectItem = [...addTableItem];
                                    delectItem.splice(index, 1);

                                    setAddTableItem(delectItem);
                                }}
                            >
                                <i className="fas fa-times text-white font-black"></i>
                            </button>
                        </div>
                    </div>
                );
            })}

            <div className="mt-[15px] flex items-center justify-end">
                <button
                    className="bg-[#5966d2] py-2 px-3 text-[0.75rem] text-[#fff] font-bold  rounded"
                    onClick={() =>
                        setAddTableItem([...addTableItem, { handleName: "" }])
                    }
                >
                    ADD
                </button>
            </div>
        </>
    );
};

export default AddTable;
