import { useEffect } from "react";
import { useFileStore } from "../strore/useFileStore";

const DistIgnore = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.disIgnore);

    useEffect(() => {
        let value = `# A set of files you probably don't want in your WordPress.org distribution
.wordpress-org
.distignore
.editorconfig
.babelrc
.eslintignore
.eslintrc.json
.stylelintrc.json
.git
.github
.gitignore
.gitlab-ci.yml
.travis.yml
.DS_Store
Thumbs.db
behat.yml
bin
circle.yml
composer.json
composer.lock
Gruntfile.js
package.json
package-lock.json
phpunit.xml
phpunit.xml.dist
multisite.xml
multisite.xml.dist
phpcs.xml
phpcs.xml.dist
README.md
wp-cli.local.yml
yarn.lock
tests
vendor
node_modules
*.sql
*.tar.gz
*.zip`;

    setStore({disIgnore: value});
    }, []);

    return null;
};

export default DistIgnore;
