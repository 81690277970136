import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";

const CRMLicense = () => {
    const { license, setLicense, mainMenuItem } = useGlobalPluginInfo();

    return (
       <div>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        License Endpoint
                    </label>
                    <input
                        type="text"
                        defaultValue={license.licenseEndpoint}
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => {
                            setLicense({
                                ...license,
                                licenseEndpoint: e.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Product Id
                    </label>
                    <input
                        type="text"
                        defaultValue={license.productId}
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => {
                            setLicense({
                                ...license,
                                productId: e.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Redirect URL
                    </label>
                    <input
                        type="text"
                        defaultValue={license.redirectUrl}
                        className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                        onChange={(e) => {
                            setLicense({
                                ...license,
                                redirectUrl: e.target.value,
                            });
                        }}
                    />
                </div>
                <div>
                    <label
                        htmlFor=""
                        className="block text-[#24263a] text-xs leading-[15px] font-bold"
                    >
                        Software Type
                    </label>
                    <div className="flex items-center gap-6 mt-[10px]">
                        <div>
                            <input 
                                type="radio" 
                                id="plugin" 
                                name="soft_type" 
                                value="plugin" 
                                onChange={(e) => {
                                    setLicense({
                                        ...license,
                                        softwareType: e.target.value,
                                    });
                                }} 
                            />
                            <label className="ml-2" htmlFor="plugin">Plugin</label>
                        </div>
                        <div>
                            <input 
                                type="radio" 
                                id="theme" 
                                name="soft_type" 
                                value="theme" 
                                onChange={(e) => {
                                    setLicense({
                                        ...license,
                                        softwareType: e.target.value,
                                    });
                                }}
                            />
                            <label className="ml-2" htmlFor="theme">Theme</label>
                        </div>
                    </div>
                </div>
            </div>
            {/* Menu */}
            <h3 className="block text-[#24263a] text-xs leading-[15px] font-bold mt-4">License Menu</h3>
            <div className="border border-[#D8D8D8] rounded-[2px] p-2 mt-2">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label
                            htmlFor=""
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Icon url 
                        </label>
                        <input
                            type="text"
                            defaultValue={license.menu.iconUrl}
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            onChange={(e) => {
                                setLicense({
                                    ...license,
                                    menu: {
                                        ...license.menu,
                                        iconUrl: e.target.value
                                    }
                                });
                            }}
                        />
                    </div>               
                    <div>
                        <label
                            htmlFor=""
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Position 
                        </label>
                        <input
                            type="text"
                            defaultValue={license.menu.position}
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            onChange={(e) => {
                                setLicense({
                                    ...license,
                                    menu: {
                                        ...license.menu,
                                        position: e.target.value
                                    }
                                });
                            }}
                        />
                    </div>               
                    <div>
                        <label
                            htmlFor="License"
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Menu Type
                        </label>
                        <select  
                            name="" 
                            id="" 
                            className="w-full p-2 mt-2 border border-[#ececee] bg-[#f6f6f9]"
                            onChange={(e) => {
                                setLicense({
                                    ...license,
                                    menu: {
                                        ...license.menu,
                                        type: e.target.value
                                    }
                                });
                            }}
                        >
                            <option value="add_submenu_page">Add Submenu Page</option>
                            <option value="add_options_page">Add Options Page</option>
                            <option value="add_menu_page">Add Menu Page</option>
                        </select>
                    </div>
                    {
                        license.menu.type === "add_submenu_page" ? (
                            <div>
                                <label
                                    htmlFor=""
                                    className="block text-[#24263a] text-xs leading-[15px] font-bold"
                                >
                                    Parent Slug 
                                </label>
                                <input
                                    type="text"
                                    value={license.menu.parentSlug || mainMenuItem?.menuPageSlug}
                                    className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                                    onChange={(e) => {
                                        setLicense({
                                            ...license,
                                            menu: {
                                                ...license.menu,
                                                parentSlug: e.target.value
                                            }
                                        });
                                    }}
                                />
                            </div>
                        )  : null
                    }
                    <div>
                        <label
                            htmlFor=""
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Menu Title 
                        </label>
                        <input
                            type="text"
                            defaultValue={license.menu.menuTitle}
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            onChange={(e) => {
                                setLicense({
                                    ...license,
                                    menu: {
                                        ...license.menu,
                                        menuTitle: e.target.value
                                    }
                                });
                            }}
                        />
                    </div> 
                    <div>
                        <label
                            htmlFor=""
                            className="block text-[#24263a] text-xs leading-[15px] font-bold"
                        >
                            Page Title
                        </label>
                        <input
                            type="text"
                            defaultValue={license.menu.pageTitle}
                            className="w-full py-[0.5rem] px-[0.75rem] border-[1px] border-[#ececee] bg-[#f6f6f9] rounded-[2px] text-sm text-[#24263a] font-normal leading-[1.5rem] mt-[10px] focus:outline-0 focus:border-[#9e9e9e]"
                            onChange={(e) => {
                                setLicense({
                                    ...license,
                                    menu: {
                                        ...license.menu,
                                        pageTitle: e.target.value
                                    }
                                });
                            }}
                        />
                    </div> 

                </div>
            </div>
          
       </div>
    );
}

export default CRMLicense;