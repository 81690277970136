import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { getPluginFolderName } from "../../../utils/getPluginFolderName";
import { useFileStore } from "../../../strore/useFileStore";

const RecommendedPluginsPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.recommendedPluginsPhp);
    const { pluginInfo, advancedItem, rcdPlugin, mainMenuItem } = useGlobalPluginInfo();
    const { pluginName, pluginTextDomain, pluginClassPrefix, pluginAuthor, pluginAuthorEmail } =
        pluginInfo;
    const { constantPrefix, baseNamespace } = advancedItem;
    const { menuPageSlug } = mainMenuItem;

    const rootFolderName = getPluginFolderName(pluginName);

    useEffect(() => {
        let value = `<?php
namespace ${baseNamespace}\\Inc\\Classes;

use ${baseNamespace}\\Libs\\Recommended;

if ( ! class_exists( 'Recommended_Plugins' ) ) {
	/**
	 * Recommended Plugins class
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	class Recommended_Plugins extends Recommended {

		/**
		 * Constructor method
		 */
		public function __construct() {
			$this->menu_order = ${
                rcdPlugin?.menuOrder ? rcdPlugin?.menuOrder : 15
            }; // for submenu order value should be more than 10 .
			parent::__construct( $this->menu_order );
		}

		/**
		 * Menu list
		 */
		public function menu_items() {
			return array(
				array(
					'key'   => 'all',
					'label' => 'All',
				),
				array(
					'key'   => 'featured', // key should be used as category to the plugin list.
					'label' => 'Featured Item',
				),
				array(
					'key'   => 'popular',
					'label' => 'Popular',
				),
				array(
					'key'   => 'favorites',
					'label' => 'Favorites',
				),
			);
		}

		/**
		 * Plugins List
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function plugins_list() {
			return array(
                ${rcdPlugin?.allPlugin
                    ?.map(
                        (item) => `'${item?.slug}'                    => array(
					'slug'              => '${item?.slug}',
					'name'              => '${item?.name}',
					'short_description' => '${item?.short_description}',
					'icon'              => '${item?.icon_url || ""}',
					'download_link'     => 'https://downloads.wordpress.org/plugin/${item?.slug}.zip',
					'type'              => array( ${item?.type?.map((el) => `'${el.value}'`)} ),
				),
				`
                    )
                    .join("")}
			);
		}

		/**
		 * Admin submenu
		 */
		public function admin_menu() {
            ${
                rcdPlugin?.pluginPosition === "submenu"
                    ? `// For submenu .
			$this->sub_menu = add_submenu_page(
				'${rcdPlugin?.submenuSlug}',       // Ex. ${rootFolderName}-settings /  edit.php?post_type=page .
				__( '${rcdPlugin?.pageTitle}', '${pluginTextDomain}' ),
				__( '${rcdPlugin?.menuTitle}', '${pluginTextDomain}' ),
				'manage_options',
				'${rootFolderName}-recommended-plugins',
				array( $this, 'render_recommended_plugins' )
			);`
                    : ""
            }${
            rcdPlugin?.pluginPosition === "parentmenu"
                ? `// For Parent Menu.
            $this->sub_menu = add_menu_page(
                esc_html__( '${rcdPlugin?.pageTitle}', '${pluginTextDomain}' ),
                esc_html__( '${rcdPlugin?.menuTitle}', '${pluginTextDomain}' ),
                'manage_options',
                '${
                    rcdPlugin?.submenuSlug
                        ? `${rcdPlugin?.submenuSlug}`
                        : `${rootFolderName}-recommended-plugins`
                }',
                [ $this, 'render_recommended_plugins' ],
                '${rcdPlugin?.menuIcon}', // icon class
                $this->menu_order // position
            );`
                : ""
        }
		}
	}
}`;
        setStore({ recommendedPluginsPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginName,
        rootFolderName,
        rcdPlugin,
        pluginAuthor,
        pluginAuthorEmail,
        pluginTextDomain,
        menuPageSlug,
        baseNamespace,
        pluginClassPrefix,
        constantPrefix,
    ]);
    return null;
};

export default RecommendedPluginsPhp;
