import { useEffect } from "react";
import { useFileStore } from "../../../strore/useFileStore";

const PhpcsYml = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.phpcs);

    useEffect(() => {
        let value = `name: PHPCS

on:
  push:
    branches:
      - develop
      - trunk
  pull_request:
    branches:
      - develop

jobs:
  phpcs:
    name: phpcs
    runs-on: ubuntu-latest
    steps:
    - name: Checkout
      uses: actions/checkout@v2

    - name: Set PHP version
      uses: shivammathur/setup-php@v2
      with:
        php-version: '7.4'
        coverage: none
        tools: composer:v2

    - name: composer install
      run: composer install

    - name: Run PHPCS
      run: composer run lint
`;
        setStore({ phpcs: value });
    }, []);

    return null;
};

export default PhpcsYml;
